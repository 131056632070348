import React from 'react';
import {user} from '../../api/kwapi';

import './login-form.scss';

export default function () {

  //Cancel showing template login form - redirect to credentials react app
  user.forceLogin();

  return (
      <div><p>Forwarding to Sign In Page...</p></div>
  );
}