import React from 'react';
import { useHistory } from 'react-router-dom';
import store from '../../api/store/store';
import MBMap from "./mbmap";
import GMap from "./gmap";

const Map = () => {

    const history = useHistory();
    const mapObj = store.getState().map;

    if (mapObj === null) {

        history.push('/surveys');
    }

    if (mapObj.MapType === 'mapbox') {
        return <MBMap/>;
    } else if (mapObj.MapType === 'google') {
        return <GMap/>;
    }
};

export default Map;