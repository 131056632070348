import React, {Fragment} from 'react';
import { Toast } from 'devextreme-react/toast';
import { useSelector } from 'react-redux'

const ToastMessage = () => {
    const toastConfig = useSelector((state) => state.toastconfig);
    const isVisible = useSelector((state) => state.toastvisible);
    return (
    <Fragment>
        {toastConfig !== null ?
                <Toast
                    visible={isVisible}
                    message={toastConfig.message}
                    type={toastConfig.type}
                    onHiding={toastConfig.onHiding}
                    displayTime={toastConfig.time}
                />
            :
            ''
        }
    </Fragment>
    );

}
export default ToastMessage;