import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import './zoomlayerpopup.scss';

const ZoomLayerPopup = ({title, message, layer, showPopup, confirmCallback, cancelCallback}) => {

    const isFunction = (func) => {
        const getType = {};
        return func && getType.toString.call(func) === '[object Function]';
    }

    const hideConfirm = () => {
        showPopup(false);
    }

    const cancelHandler = () => {
        isFunction(cancelCallback) && cancelCallback();
        //hideConfirm();
    }

    const confirmHandler = () => {
        isFunction(confirmCallback) && confirmCallback(true);
        //hideConfirm();
    }

    const confirmButtonOptions = {
        icon: 'check',
        text: 'Zoom To Bounds',
        onClick: confirmHandler
    };

    const cancelButtonOptions = {
        icon: 'close',
        text: 'OK',
        onClick: cancelHandler
    };

    return (
        <Popup
            visible={true}
            onHiding={hideConfirm}
            resizeEnabled={true}
            dragEnabled={true}
            focusStateEnabled={true}
            closeOnOutsideClick={false}
            showCloseButton={true}
            shading={false}
            showTitle={true}
            title={title}
            container=".dx-viewport"
            defaultWidth={328}
            defaultHeight={190}
            minWidth={260}
            maxWidth={600}
            className='confirm-popup'
        >
            {/*<Position
                at="bottom"
                my="right"
                of={'#mapboxgl-ctrl-top-right'}
                offset={offsetX + ' ' + offsetY}
            />*/}
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={confirmButtonOptions}
            />
            <p>{'Layer: '}<em>{layer.id}</em>{' '+message}</p>
        </Popup>
    );
};
export default ZoomLayerPopup;
