import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step10 = ({step}) => {

    const captions = {
        0: 'The Map views tool can be found in the top toolbar.',
        1: 'Once activated, a window will come up with the existing map views. To add a new map view adjust the map to the view you want to save.',
        2: 'Once you have finished adjusting the the map view to your liking.  Click the + button at the top of the map views window.',
        3: 'Type a name and an optional description for your new map view, then click Save.',
        4: 'Once the map view is saved, it will show up in the map view list where you can click "Activate View" to switch to each view.',
        5: 'You can also share map views with other people by clicking the "Share button" next to a map view and copying the link.',
        6: 'If you paste the copied link into a new tab, it goes directly to your view of the map.'
    }

    const timeIndexes = [0, 6.0, 37.0, 42.0, 74.0, 86.0, 98.0];

    return <MT_Video step={step} basictutorial={true} title="Using the Map Views tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/723265956/7b5d3c957e"/>
}
export default MT_Step10;