import store from "../../api/store/store";
import {
    setCommentsData,
    setError,
    setShowThreads,
    setPullComments,
    setShowComments,
    setActiveThread,
    setShowThreadPopup,
    setCommentsAreaTool,
    setCommentsMarkerTool,
    setPolygonColor,
    setShowCommentsTooltip
} from "../../api/actions/actions";
import {kwapi} from "../../api/kwapi";
import {CommentsMarkerTool} from "../../pages/map/CommentsMarkerTool";
import {CommentsAreaTool} from "../../pages/map/CommentsAreaTool";

export const CommentsTool = (() => {
    let instance;

    function createInstance() {

        return {
            create: () => {
                const commentsAPIResp = (commentsdata) => {
                    store.dispatch(setCommentsData(commentsdata));
                    store.dispatch(setPullComments(false));
                    store.dispatch(setShowThreads(true));
                };
                const commentAPIError = (error) => {
                    store.dispatch(setError(error));
                };

                kwapi.getMapComments(commentsAPIResp, commentAPIError);
                store.dispatch(setShowCommentsTooltip(false));
            },
            destroy: () => {
                CommentsMarkerTool.hasInstance() && CommentsMarkerTool.getInstance().destroy();
                store.dispatch(setCommentsMarkerTool(false));
                if (CommentsAreaTool.hasInstance()) {
                    store.dispatch(setPolygonColor('#d20c0c'));
                    CommentsAreaTool.getInstance().destroy();
                }
                store.dispatch(setCommentsAreaTool(false));
                store.dispatch(setShowThreads(false));
                store.dispatch(setShowThreadPopup(false));
                store.dispatch(setShowComments(false));
                store.dispatch(setActiveThread(null));
                store.dispatch(setCommentsData(null));
                store.dispatch(setShowCommentsTooltip(false));
                instance = undefined;
            },
        };
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();