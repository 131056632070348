import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {public_url} from "./api/public_url";
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import {kwapi, user} from "./api/kwapi";

function App() {
  const { user, loading } = useAuth();

  const public_link = public_url.validate();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user || public_link) {
    return <Content />;
  }

  return <NotAuthenticatedContent />;
}

export default function () {
  const screenSizeClass = useScreenSizeClass();

  useEffect(() => {
    onComponentMount();
    // Clean up on unmount
    return onUnmountContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onComponentMount = () => {
    const selectedClient = user.getSelectedClient();
    if (selectedClient !== null) {
      user.setSelectedClient(selectedClient, true);
    } else {
      user.isLoggedIn() && kwapi.getClientData();
    }
  };

  const onUnmountContent = () => {
    //Do stuff on unmount
    return () => {}
  };

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
