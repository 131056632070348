export const environment = () => {
    let environment;
    switch (window.location.hostname) {
        case '127.0.0.1':
        case 'localhost':
            environment = 'development';
            break;
        case 'sitetours.kierwright.test':
            environment = 'staging';
            break;
        case 'virtualtours.kierwright.com':
            environment = 'production';
            break;
        default:
            environment = null;
    }
    return environment;
}