import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import { useDispatch, useSelector } from 'react-redux';
import {setClientSelected, setMaps} from "../../api/actions/actions";
import './user-panel.scss';

export default function ({ menuMode }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);

  const menuItems = useMemo(() => {
    if (account !== null && (account.scope === 'staff' || account.scope === 'admin')) {
      return [
        {
          text: 'Account Settings',
          icon: 'preferences',
          onClick: () => { history.push('/surveys/account-settings'); }
        },
        {
          text: 'Switch Client Account',
          icon: 'revert',
          onClick: () => {
            sessionStorage.removeItem('sitetours-client-id');
            dispatch(setClientSelected(null));
            dispatch(setMaps(null));
            window.location.href = '/surveys/dashboard';
          }
        },
        {
          text: 'Sign Out',
          icon: 'pinleft',
          onClick: () => { window.location.href = '/credentials/signout'; }
        }
      ]
    } else {
      return [
        {
          text: 'Account Settings',
          icon: 'preferences',
          onClick: () => { history.push('/surveys/account-settings'); }
        },
        {
          text: 'Sign Out',
          icon: 'pinleft',
          onClick: () => { window.location.href = '/credentials/signout'; }
        }
      ]
    }
  }, [account]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(${user.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{user.email}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
