import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Popup , ToolbarItem } from 'devextreme-react/popup';
import List from 'devextreme-react/list';
import ArrayStore from 'devextreme/data/array_store';
import ScrollView from 'devextreme-react/scroll-view';
//import useWindowDimensions from "../../hooks/useWindowDimensions";
import ClientItem from "./ClientItem";
import './clientselectpopup.scss';

const ClientSelectPopup = ({popupvisible, selectClient}) => {

    const [selectedClient, setSelectedClient] = useState(null);
    const clientlist = useSelector((state) => state.client_list);

    const dataSource = new ArrayStore({
        key: 'id',
        data: clientlist,
    });

    const onClientSelection = (args) => {
        if (args.name === 'selectedItem') {
            setSelectedClient(args.value);
        }
    };

    const confirmSelection = () => {
        if (typeof selectedClient.id !== 'undefined') {
            selectClient(selectedClient.id);
        }
    }

    const confirmButtonOptions = {
        icon: 'revert',
        text: 'Select Account',
        onClick: confirmSelection
    };

    //const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars
    let cpopupHeight, cpopupWidth;

    /*if (width > 840) {
        cpopupHeight = 715;
        cpopupWidth = 837;
    } else if (width > 670) {
        cpopupHeight = 670;
        cpopupWidth = 530;
    } else if (width <= 670 && width >= 500) {
        cpopupHeight = 515;
        cpopupWidth = 433;*/
    /*} else if (width < 500) {*/
        cpopupHeight = 391;
        cpopupWidth = 356;
    /*}*/

    return (
        <Popup
            visible={popupvisible}
            dragEnabled={true}
            resizeEnabled={true}
            closeOnOutsideClick={false}
            showCloseButton={false}
            showTitle={true}
            title="Select Client Account"
            defaultHeight={cpopupHeight}
            defaultWidth={cpopupWidth}
            maxWidth={837}
            maxHeight={715}
            className="client-selection"
        >

            <ScrollView className="list-container" showScrollbar="always">
                    <List
                        dataSource={dataSource}
                        height={cpopupHeight - 50}
                        grouped={false}
                        selectionMode="single"
                        showSelectionControls={false}
                        pageLoadMode="scrollBottom"
                        selectedItem={selectedClient}
                        onOptionChanged={onClientSelection}
                        noDataText="You do not have access to any client accounts!"
                        itemRender={ClientItem} />
            </ScrollView>

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={confirmButtonOptions}
            />
        </Popup>
    );
};
export default ClientSelectPopup;