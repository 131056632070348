import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {setSelectedLayers, setLayersIcon} from "../../api/actions/actions";
import './stylemenu.scss';

const StyleMenu = () => {

    const [styleradio, setStyleRadio] = useState('hybrid');
    const map = useSelector((state) => state.mbmap);
    const layers = useSelector((state) => state.selectedlayers);
    const dispatch = useDispatch();

    const handleSelect = (e) => {
        const rdoid = e.target.id.replace('rdo-', '');
        const layerstatus = {
            satellite: true,
            aerial: true,
            hillshading: false
        }

        switch (rdoid) { //eslint-disable-line default-case
            case 'hybrid':
                setStyleRadio('hybrid');
                layerstatus.satellite = true;
                layerstatus.aerial = true;
                layerstatus.hillshading = false;
                dispatch(setLayersIcon('layers'));
                break;
            case 'satellite':
                setStyleRadio('satellite');
                layerstatus.satellite = true;
                layerstatus.aerial = false;
                layerstatus.hillshading = false;
                dispatch(setLayersIcon('layers'));

                break;
            case 'aerial':
                setStyleRadio('aerial');
                layerstatus.satellite = false;
                layerstatus.aerial = true;
                layerstatus.hillshading = false;
                dispatch(setLayersIcon('layers-black'));
                break;
            case 'hillshading':
                setStyleRadio('hillshading');
                layerstatus.satellite = false;
                layerstatus.aerial = false;
                layerstatus.hillshading = true;
                dispatch(setLayersIcon('layers-black'));
                break;
        }

        if (layers.length) {
            const newlayers = [];

            layers.map((layer) => { // eslint-disable-line array-callback-return
                const layerid = typeof layer.mapbox_id !== 'undefined' ? layer.mapbox_id : layer.name;
                if (typeof layerstatus[layerid.toLowerCase()] !== 'undefined') {
                    layer.selected = layerstatus[layerid];
                    typeof layer.items === 'undefined' && map.setLayoutProperty(layerid, 'visibility', layerstatus[layerid.toLowerCase()] ? 'visible' : 'none');

                    if (typeof layer.items !== 'undefined') {
                        const itemmax = layer.items.length;
                        for (let i = 0; i < itemmax; i++) {
                            const itemid = typeof layer.items[i].mapbox_id !== 'undefined' ? layer.items[i].mapbox_id : layer.items[i].name;
                            map.setLayoutProperty(itemid, 'visibility', layerstatus[layerid.toLowerCase()] ? 'visible' : 'none');
                            layer.items[i].selected = layerstatus[layerid.toLowerCase()]
                        }
                    }
                }
                newlayers.push(layer);
            });

            if (layerstatus.hillshading) {
                if (!map.getLayer('land')) {
                    const mblayers = map.getStyle().layers
                    let firstLayerId;
                    for (let i = 0; i < mblayers.length; i++) {
                        if (mblayers[i].type === 'symbol') {
                            firstLayerId = mblayers[i].id;
                            break;
                        }
                    }
                    map.addLayer({
                        "id": "land",
                        "type": "background",
                        "layout": {},
                        "paint": {"background-color": "hsl(0,0%,82%)"},
                        "metadata": {"mapbox:featureComponent": "land-and-water", "mapbox:group": "Land & water, land"}
                    }, firstLayerId);
                }
                map.getLayer('hillshading') && map.removeLayer('hillshading');
                map.addLayer({
                    'id': 'hillshading',
                    "source": 'mapbox-dem',
                    'type': 'hillshade',
                    'paint': {'hillshade-shadow-color': 'hsl(139,76%,16%)'}
                });
            } else {
                map.getLayer('land') && map.removeLayer('land');
            }
            newlayers.length && dispatch(setSelectedLayers(newlayers));
            map.setLayoutProperty('satellite', 'visibility', layerstatus.satellite ? 'visible' : 'none');
        }
    };

    return (
        <div id="stylemenu">
            <input id="rdo-hybrid" type="radio" name="rtoggle" value="hybrid" checked={styleradio === 'hybrid'} onChange={handleSelect}/>
            <label htmlFor="rdo-hybrid">Hybrid</label>
            <input id="rdo-satellite" type="radio" name="rtoggle" value="satellite" checked={styleradio === 'satellite'} onChange={handleSelect}/>
            <label htmlFor="rdo-satellite">Satellite</label>
            <input id="rdo-aerial" type="radio" name="rtoggle" value="aerial" checked={styleradio === 'aerial'} onChange={handleSelect}/>
            <label htmlFor="rdo-aerial">Aerial</label>
            <input id="rdo-hillshading" type="radio" name="rtoggle" value="hillshading" checked={styleradio === 'hillshading'} onChange={handleSelect}/>
            <label htmlFor="rdo-hillshading">Hillshading</label>
        </div>
    )
};
export default StyleMenu;