function getBrowserInfo() {

    let browser, isIE, isChrome, isOpera;

    // Opera 8.0+
    if ((!!window.opr && typeof opr !== 'undefined' || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0)) { //eslint-disable-line no-mixed-operators
        browser = 'opera';
        isOpera = true;
    }

    // Firefox 1.0+
    if (typeof InstallTrigger !== 'undefined') { browser = 'firefox'; }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))) {
        browser = 'safari';
    }

    // Internet Explorer 6-11
    if (/*@cc_on!@*/false || !!document.documentMode) {
        browser = 'ie';
        isIE = true;
    }

    // Edge 20+
    if (!isIE && !!window.StyleMedia) {
        browser = 'edge_legacy';
    }

    // Chrome 1 - 79
    if ((!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || navigator.userAgent.indexOf("Chrome") !== -1) {
        browser = 'chrome';
        isChrome = true;
    }
    //@TODO Fix Edge Chromium and Blink detection
    // Edge (based on chromium) detection
    if (isChrome && (navigator.userAgent.indexOf("Edg") !== -1)) {
        browser = 'edge_chromium';
    }

    // Blink engine detection
    if ((isChrome || isOpera) && !!window.CSS) {
        browser = 'blink';
    }

    return browser;
}

export default function useBrowserDetection() {

    return getBrowserInfo();
}