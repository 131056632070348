import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { Popover } from 'devextreme-react/popover';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import {default as ShareMapPopup} from './ShareMapPopup';
import {default as PrintMapPopup} from './PrintMapPopup';
import {user, kwapi} from '../../api/kwapi';
import {public_url} from "../../api/public_url";
import {setSharePopupText, setSharePopupURL, setSharePopupVisible, setShowPrintTooltip} from "../../api/actions/actions";
import ToolNav from "./ToolNav";
import store from "../../api/store/store";

export default ({menuToggleEnabled, menuExpanded, title, toggleMenu, userMenuItems}) => {

  const dispatch = useDispatch();
  const map = useSelector((state) => state.mbmap)
  const geojson = useSelector((state) => state.geojson);
  const popovervisible = useSelector((state) => state.popovervisible);
  const popovertext = useSelector((state) => state.popovertext);
  const sharepopupvisible = useSelector((state) => state.sharepopupvisible);
  //const [sharemapvisible, setShareMapVisible] = useState(false);
  const [printmapvisible, setPrintMapVisible] = useState(false);
  const [popovertarget, setPopoverTarget] = useState('');

  // Initialize map when component mounts
  useEffect(() => {

    // Clean up on unmount
    return onUnmountHeader;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setShowPrintTooltip(false));
  }, [printmapvisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const env = kwapi.environment();

  const onUnmountHeader = () => {
    //console.log('Unmounting header');
  };

  const dismissPrintPopup = () => {
    setPrintMapVisible(false);
  };
  const dismissSharePopup = () => {
    dispatch(setSharePopupVisible(false));
    dispatch(setSharePopupURL(kwapi.getAppURL() + 'map/' + store.getState().map.URLToken));
    dispatch(setSharePopupText('Shareable Map URL'));
  };

  if (map === null || geojson === null) {
    return (
        <header className={'header-component'}>
          {user.isLoggedIn() || env === 'development' ?
              <Toolbar className={'header-toolbar'}>

                <Item
                    visible={menuToggleEnabled}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                  <Button icon="menu" stylingMode="text" onClick={toggleMenu}/>
                </Item>

                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    text={title}
                    visible={!!title}
                />
                <Item
                    location={'before'}
                    cssClass={'logo-container'}
                    visible={!menuExpanded}
                >
                  <img src={kwapi.getCDNURL() + "assets/images/logo.svg"} style={{width: '200px', marginLeft: '-20px'}} alt="Kier + Wright logo"/>
                  {/*<div className="logo-text">KIER+WRIGHT</div>*/}
                </Item>

                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'}
                >
                  <Button
                      className={'user-button authorization'}
                      width={210}
                      height={'100%'}
                      stylingMode={'text'}
                  >
                    <UserPanel menuMode={'context'}/>
                  </Button>
                </Item>

                <Template name={'userPanelTemplate'}>
                  <UserPanel menuMode={'list'}/>
                </Template>
              </Toolbar>
              :
              <Toolbar className={'header-toolbar'}>

                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    text={title}
                    visible={!!title}
                />
                <Item
                    location={'before'}
                    cssClass={public_url.validate() ? 'logo-container public-logo' : 'logo-container'}
                    visible={!menuExpanded}
                >
                  <img src={kwapi.getCDNURL() + "assets/images/logo.svg"} style={{width: '200px', marginLeft: '-20px'}} alt="Kier + Wright logo"/>
                  {/*<div className="logo-text">KIER+WRIGHT</div>*/}
                </Item>

              </Toolbar>
          }
        </header>
    );
  } else {
    return (
        <Fragment>
          <header className={'header-component'}>
            <ToolNav title={title} menuToggleEnabled={menuToggleEnabled} menuExpanded={menuExpanded}
                     toggleMenu={toggleMenu} setPrintMapVisible={setPrintMapVisible}
                     setPopoverTarget={setPopoverTarget}/>
            {popovervisible &&
            <Popover
                target={popovertarget}
                position="top"
                width={200}
                visible={popovervisible}
            >
              <span dangerouslySetInnerHTML={{__html: popovertext}}/>
            </Popover>
            }
          </header>
          <PrintMapPopup printvisible={printmapvisible} dismissPopup={dismissPrintPopup}/>
          <ShareMapPopup sharevisible={sharepopupvisible} dismissPopup={dismissSharePopup}/>
        </Fragment>
    )
  }
};

