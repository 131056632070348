import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import {kwapi, user} from "../../api/kwapi";
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';

export default function (props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { isLarge } = useScreenSize();
  function normalizePath () {    
    return navigation.map((item) => {
      if(item.path && !(/^\//.test(item.path))){ 
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLarge} 
    })
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clientdata = user.isLoggedIn() ? kwapi.getClientData() : undefined;
  const clientitems = [];
  user.isLoggedIn() && clientitems.push({
    text: 'Dashboard',
    path: '/surveys/dashboard',
    icon: 'event'
  });
  user.isLoggedIn() && clientitems.push({
    text: 'World Map',
    path: '/surveys',
    icon: 'map'
  });
  typeof clientdata !== 'undefined' && clientdata.map((project) => { //eslint-disable-line array-callback-return
    const maps = [];
    project.maps.map((mapobj) => { //eslint-disable-line array-callback-return
      maps.push({ text: mapobj.name, path: '/surveys/map/'+mapobj.url_token})
    });
    const item = {
      text: project.name,
      icon: 'folder',
      items: maps,
      expanded: true
    }
    clientitems.push(item);
  });

  let { navigationData: { currentPath } } = useNavigation();

  /*if (currentPath === '/surveys/map') { currentPath = undefined; }*/

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={clientitems.length ? clientitems : items}
          keyExpr={'path'}
          className="app-side-nav"
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}
