import React, {useRef, useEffect, useState} from "react";

const GoogleMap = ({mapId, center, zoom}) => {
    const ref = useRef();

    useEffect(() => {
        const googlemap = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            mapId
        });

        /*const kmlLayer = new window.google.maps.KmlLayer({
            // url: "http://www.google.com/maps/d/kml?mid=zNPjLbo835mE.k3TvWfqGG-AU",
            url: "https://www.google.com/maps/d/u/2/kml?mid=1hQMaLAE4xNQ7EEhcUXArLcb9j82noUtk",
            map: googlemap
        });*/
    }, []);

    return <div ref={ref} id="map" style={{ height: '100vh', width: '100%' }}/>;
}

export default GoogleMap;