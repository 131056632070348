import store from "../../api/store/store";
import {
    setViewsData,
    setError,
    setPullViews,
    setShowViews,
    setShowViewsPopup,
    setSelectedView,
    setViewsTool
} from "../../api/actions/actions";
import {kwapi} from "../../api/kwapi";
import {CommentsTool} from "./CommentsTool";

export const MapViewsTool = (() => {
    let instance;

    function createInstance() {

        return {
            create: () => {
                CommentsTool.getInstance().destroy();
                const viewsAPIResp = (viewsdata) => {
                    store.dispatch(setViewsData(null));
                    store.dispatch(setViewsData(viewsdata));
                    store.dispatch(setPullViews(false));
                    store.dispatch(setShowViews(true));
                    store.dispatch(setViewsTool(true));
                };
                const viewsAPIError = (error) => {
                    store.dispatch(setError(error));
                };

                kwapi.getMapViews(viewsAPIResp, viewsAPIError);
            },
            destroy: () => {
                store.dispatch(setShowViews(false));
                store.dispatch(setShowViewsPopup(false));
                store.dispatch(setSelectedView(null));
                store.dispatch(setViewsTool(false));
                instance = undefined;
            },
        };
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        hasInstance: function () {
            if (!instance) {
                return false;
            } else {
                return true;
            }
        }
    };
})();