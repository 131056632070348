import React, {Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, Position } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import RangeSlider from "../../components/rangeslider/rangeslider";
import SubscriptionDropdown from "./subscriptiondropdown";
import {
    setShowThreadPopup,
    setCommentsData,
    setMarkerColor,
    setPolygonColor,
    setPolygonCoordinates,
    setToastVisible,
    setToastConfig,
    setPolygonOpacity,
    setError,
    setSubscriberList
} from "../../api/actions/actions";
import TinyMce from "../../components/tinymce/TinyMce";
import { CompactPicker } from 'react-color';
import {kwapi, user} from "../../api/kwapi";
import {CommentsAreaTool} from "./CommentsAreaTool";
import {CommentsMarkerTool} from "./CommentsMarkerTool";
import './threadpopup.scss';


const ThreadPopup = ({visible}) => {
    const dispatch = useDispatch();
    const threadType = useSelector((state) => state.marker_type);
    const commentsdata = useSelector((state) => state.commentsdata);
    const geojsondata = useSelector((state) => state.geojsondata);
    const currentview = useSelector((state) => state.currentview);
    const subscribers = useSelector((state) => state.subscribers);
    const selectedClient = useSelector((state) => state.client_selected);
    const account = kwapi.getClientAccount();

    const [threadData, setThreadData] = useState({
        comment: '',
        marker_type: threadType,
        object_id: null,
        parent_id: null,
        thread_id: null,
        geojson: null,
        latlng: '',
        polygon_color: null,
        opacity: null,
    });

    const [threadComment, setThreadComment] = useState('');
    const [latval, setLatVal] = useState('');
    const [lngval, setLngVal] = useState('');

    const toastConfig = {
        isVisible: false,
        type: 'info',
        message: ''
    };

    const defaultColor = {
        hex: '#d20c0c',
        hsl: {
            a: 1,
            h: 0,
            l: 0.43529411764705883,
            s: 0.8918918918918918
        },
        hsv: {
            a: 1,
            h: 0,
            s: 0.9428571428571428,
            v: 0.8235294117647058
        },
        oldHue: 250,
        rgb: {
            a: 1,
            b: 12,
            g: 12,
            r: 210
        },
        source: 'hex'
    };

    const [color, setColor] = useState(defaultColor);
    const [opacity, setOpacity] = useState()

    const [editorActive, setEditorActive] = useState(false);

    // Initialize when component mounts
    useEffect(() => {
        setEditorActive(true);
        if (typeof account.id !== 'undefined' && account.id !== null) {
            getSubscribers();
        }
        !CommentsMarkerTool.hasInstance() && CommentsAreaTool.hasInstance() && CommentsAreaTool.getInstance().convertNewPolygonToLayer();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const getSubscribers = () => {
        kwapi.getThreadSubscriberList(subscriberListSuccess, subscriberListError);
    };

    const subscriberListError = () => {
        dispatch(setError('Unable to retrieve subscribers from API'));
    };
    const subscriberListSuccess = (subscriberList) => {
        dispatch(setSubscriberList(subscriberList));
    }

    const saveSuccess = (threadobj) => {
        const new_commentsdata = commentsdata;
        dispatch(setCommentsData(null));
        threadobj.status = 'Open';
        new_commentsdata.push(threadobj);
        dispatch(setCommentsData(new_commentsdata));

        if (threadobj.type === 'marker' && CommentsMarkerTool.hasInstance()) {
            CommentsMarkerTool.getInstance().destroy();
            CommentsMarkerTool.getInstance().create();
        }

        const toastconfig = {
            ...toastConfig,
            isVisible: true,
            type: 'success',
            message: 'Comment Thread successfully saved!',
            onHiding: onFlashHiding,
            time: 2000
        }

        dispatch(setToastConfig(toastconfig));
        dispatch(setToastVisible(true));
    };

    const saveError = () => {
        const toastconfig ={
            ...toastConfig,
            isVisible: true,
            type: 'error',
            message: 'Unable to save comment thread!',
            onHiding: onFlashHiding,
            time: 2000
        };

        dispatch(setToastConfig(toastconfig));
        dispatch(setToastVisible(true));
    }

    const handleSave = () => {
        if (threadData.comment.length) {
            const formdata = threadData;
            let formvalid = true;
            delete formdata.latlng;
            if (formdata.geojson === null) {
                if (threadType === 'marker') {
                    const latlng = document.getElementById('cm-coordinates').value !== null ? document.getElementById('cm-coordinates').value.replace(' ', '').split(',') : null;
                    if (latlng !== null && latlng.length) {
                        formdata.geojson = JSON.stringify({lat: latlng[0], lng: latlng[1]});
                    } else {
                        formvalid = false;
                    }
                } else {
                    if (geojsondata !== null) {
                        formdata.geojson = geojsondata;
                    } else {
                        formvalid = false;
                    }
                }
                formdata.opacity = opacity;
            }
            formdata.color = color.hex;
            formdata.zoom = currentview.zoom;
            formdata.pitch = currentview.pitch;
            formdata.bearing = currentview.bearing;
            formdata.center = currentview.center;
            formdata.subscriptions = subscribers;

            if (user.isKWAccount()) {
                formdata.client_id = selectedClient !== null ? selectedClient : user.getSelectedClient();
            }

            formvalid && kwapi.saveNewThread(formdata, saveSuccess, saveError);

            if (CommentsMarkerTool.hasInstance()) {
                dispatch(setMarkerColor(defaultColor.hex));
                setColor(defaultColor);
            } else if (CommentsAreaTool.hasInstance()) {
                CommentsAreaTool.getInstance().resetDraw();
            }
        }
    };

    const onFlashHiding = () => {
        dispatch(setToastVisible(false));
        hidePopup();
    };

    const hidePopup = () => {
        setEditorActive(false);
        dispatch(setShowThreadPopup(false));
        const new_marker = document.getElementById('new-thread-marker');
        new_marker !== null && new_marker.remove();
        const mbtrashbtn = document.querySelector('.mapbox-gl-draw_trash');
        mbtrashbtn !== null && mbtrashbtn.click();
        if (!CommentsMarkerTool.hasInstance() && CommentsAreaTool.hasInstance()) {
            dispatch(setPolygonColor(defaultColor.hex));
            dispatch(setPolygonCoordinates(null));
            CommentsAreaTool.getInstance().resetDraw();
        }
        setColor(defaultColor);
        dispatch(setMarkerColor(defaultColor.hex));
    };

    const setComment = (comment) => {
       setThreadComment(comment);
       const new_threadData = threadData;
       new_threadData.comment = comment;
       setThreadData(new_threadData);
    };

    const setLatLng = (e) => {
        const target = e.target;
        const latlng = target.value;
        const new_threadData = threadData;
        const latlngarr = latlng.replace(' ', '').split(',');
        new_threadData.latlng = latlng;
        new_threadData.geojson = JSON.stringify({lat: latlngarr[0], lng: latlngarr[1]});
        setThreadData(new_threadData);
        const lat_event = {target: {value: latlngarr[0]}};
        const lng_event = {target: {value: latlngarr[1]}};
        updateLat(lat_event);
        updateLng(lng_event);
        setTimeout(() => {
            const coordinatesInput = document.getElementById('cm-coordinates');
            if (coordinatesInput !== null && (!coordinatesInput.value.length || coordinatesInput.value !== latlng)) { coordinatesInput.value = latlng; }
        }, 400);
    }

    const updateLat = (e) => {
        const input_latval = e.target.value;
        setLatVal(input_latval);
        setTimeout(() => {
            const latinput = document.getElementById('cm-coordinates-lat');
            if (input_latval !== null && (!latinput.value.length || latinput.value !== input_latval)) {
                latinput.value = input_latval;
            }
        }, 400);
    }
    const updateLng = (e) => {
        const input_lngval = e.target.value;
        setLngVal(input_lngval);
        setTimeout(() => {
            const lnginput = document.getElementById('cm-coordinates-lng');
            if (input_lngval !== null && (!lnginput.value.length || lnginput.value !== input_lngval)) {
                lnginput.value = input_lngval;
            }
        }, 400);
    }
    const handleMarkerColorChange = (color, event) => {
        dispatch(setMarkerColor(color.hex));
        setColor(color)
        const newthreadData = threadData;
        newthreadData.color = color.hex;
        setThreadData(newthreadData);
        const coordinatesInput = document.getElementById('cm-coordinates');
        if (coordinatesInput !== null && coordinatesInput.value.length) {
            CommentsMarkerTool.getInstance().reload_marker(coordinatesInput.value, event);
        }
    }

    const handleColorChange = (color, event) => {
        dispatch(setPolygonColor(color.hex));
        setColor(color);
        const newthreadData = threadData;
        newthreadData.color = color.hex;
        setThreadData(newthreadData);
        CommentsAreaTool.getInstance().convertNewPolygonToLayer();
    }

    const handleOpacityChange = (opacity) => {
        dispatch(setPolygonOpacity(opacity));
        setOpacity(opacity);
        const newthreadData = threadData;
        newthreadData.opacity = opacity;
        setThreadData(newthreadData);
        CommentsAreaTool.getInstance().convertNewPolygonToLayer();
    }

    const popupHeight = threadData.marker_type === 'marker' ? 580 : 590;

    //Update LatLng coordinates input box when it is changed from CommentsMarkerTool
    const coordinatesInput = document.getElementById('cm-coordinates');

    if (coordinatesInput !== null && coordinatesInput.value !== threadData.latlng) {
        const newval = coordinatesInput.value;
        setLatLng({target: {value: newval}});
        coordinatesInput.value = newval;
    }

    const btncontainer_top_margin = threadData.marker_type === 'marker' ? '15px' : 0;

    return (
        <Fragment>
            <Popup
                visible={visible}
                onHiding={hidePopup}
                resizeEnabled={true}
                dragEnabled={true}
                focusStateEnabled={true}
                closeOnOutsideClick={false}
                showCloseButton={true}
                shading={false}
                showTitle={true}
                title={'New Comment Thread'}
                container=".dx-viewport"
                defaultWidth={430}
                defaultHeight={popupHeight}
                minWidth={260}
                maxWidth={600}
                minHeight={270}
                maxHeight={popupHeight}
                className='new-thread-popup'
            >
                <Position
                    at="center"
                    my="right"
                    of={'#new-thread-marker'}
                    offset="-90 50"
                />
                <div id="form" className="dx-form dx-widget dx-visibility-change-handler" role="form">
                    <div className="dx-fieldset thread-toolbar">
                        <SubscriptionDropdown/>
                    </div>
                    <div className="dx-layout-manager dx-widget">
                        <div className="dx-widget dx-collection dx-responsivebox-screen-sm dx-responsivebox" style={{width: '100%', height: '100%'}}>
                            <div className="dx-box-flex dx-box dx-widget dx-visibility-change-handler dx-collection" style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                                <div id="comments-field" className="dx-item dx-box-item" style={{display: 'flex', minHeight: 'auto', flexGrow: 1, flexShrink: 1}}>
                                    <div className="dx-first-row dx-first-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align" style={{width: '100% !important'}}>
                                        <label className="dx-field-item-label dx-field-item-label-location-top"><span
                                            className="dx-field-item-label-content"><span
                                            className="dx-field-item-label-text">Comment:</span></span></label>
                                        <div className="dx-field-item-content dx-field-item-content-location-bottom">
                                            <div className="dx-show-invalid-badge dx-numberbox dx-texteditor dx-editor-outlined dx-widget">
                                                    <div className="dx-texteditor-container">
                                                        <div className="dx-texteditor-input-container">
                                                            <TinyMce active={editorActive} value={threadComment} setValue={setComment}/>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {threadData.marker_type === 'marker' ?
                                <div id="coordinates-field" className="dx-item dx-box-item"
                                     style={{display: 'flex', minHeight: 'auto', flexGrow: 1, flexShrink: 1}}>
                                    <div
                                        className="dx-item-content dx-box-item-content dx-box-flex dx-box dx-widget dx-visibility-change-handler dx-collection"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'stretch',
                                            width: 'auto',
                                            height: 'auto',
                                            flexBasis: '0px',
                                            flexGrow: 1
                                        }}>
                                        <div className="dx-item dx-box-item"
                                             style={{display: 'flex', minWidth: '0px', flex: '1 1 0px'}}>
                                            <div className="dx-item-content dx-box-item-content dx-root-simple-item"
                                                 style={{
                                                     width: 'auto',
                                                     height: 'auto',
                                                     display: 'flex',
                                                     flexBasis: '0px',
                                                     flexGrow: 1,
                                                     flexDirection: 'column'
                                                 }}>
                                                <div
                                                    className="dx-first-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align">
                                                    <label
                                                        className="dx-field-item-label dx-field-item-label-location-top"
                                                        htmlFor="cm-coordinates">
                                                        <span className="dx-field-item-label-content">
                                                            <span className="dx-field-item-label-text">Coordinates (Lat, Lng):</span>
                                                        </span>
                                                    </label>
                                                    <div
                                                        className="dx-field-item-content dx-field-item-content-location-bottom cm-coordinates-container">
                                                        <div
                                                            className="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-outlined dx-widget">
                                                            <div className="dx-texteditor-container">
                                                                <div className="dx-texteditor-input-container">
                                                                    {
                                                                        // eslint-disable-next-line jsx-a11y/no-redundant-roles
                                                                    }<input
                                                                        autoComplete="off"
                                                                        id="cm-coordinates"
                                                                        className="dx-texteditor-input" type="hidden"
                                                                        spellCheck="false" name="coordinates"
                                                                        tabIndex="0"
                                                                        role="textbox"
                                                                        aria-required="false"
                                                                        value={threadData.latlng}
                                                                    />

                                                                    <input
                                                                        autoComplete="off"
                                                                        id="cm-coordinates-lat"
                                                                        className="dx-texteditor-input" type="text"
                                                                        spellCheck="false" name="coordinates"
                                                                        tabIndex="0"
                                                                        role="textbox"
                                                                        aria-required="false"
                                                                        value={latval}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cm-coordinates-separator"><span>,</span></div>
                                                        <div
                                                            className="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-outlined dx-widget">
                                                            <div className="dx-texteditor-container">
                                                                <div className="dx-texteditor-input-container">
                                                                    <input
                                                                        autoComplete="off"
                                                                        id="cm-coordinates-lng"
                                                                        className="dx-texteditor-input" type="text"
                                                                        spellCheck="false" name="coordinates"
                                                                        tabIndex="0"
                                                                        role="textbox"
                                                                        aria-required="false"
                                                                        value={lngval}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="polygon-colorpicker">
                                                    <label className="dx-field-item-label dx-field-item-label-location-top" style={{marginBottom: '-2px'}}>
                                                        <span className="dx-field-item-label-content">
                                                            <span className="dx-field-item-label-text">Marker Color:</span>
                                                        </span>
                                                    </label>
                                                    <CompactPicker onChange={handleMarkerColorChange} color={color}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    <Fragment>
                                        <div className="polygon-colorpicker">
                                            <label className="dx-field-item-label dx-field-item-label-location-top">
                                                <span className="dx-field-item-label-content">
                                                    <span className="dx-field-item-label-text">Polygon Color:</span>
                                                </span>
                                            </label>
                                            <CompactPicker onChange={handleColorChange} color={color}/>
                                        </div>
                                        <div className="dx-field custom-height-slider">
                                            <div className="dx-field-label">Opacity</div>
                                            <RangeSlider min={0} max={1} step={0.1} onSliderChange={handleOpacityChange} initialValue={opacity}/>
                                        </div>
                                    </Fragment>
                                }
                                <div className="button-container" style={{marginTop: btncontainer_top_margin, marginLeft: '90px'}}>
                                    <Button
                                        id="save-comment"
                                        width={100}
                                        text="Save"
                                        type="success"
                                        stylingMode="contained"
                                        onClick={handleSave}
                                    />
                                    <Button
                                        id="cancel-comment"
                                        width={100}
                                        text="Cancel"
                                        type="normal"
                                        stylingMode="contained"
                                        onClick={hidePopup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>}
        </Fragment>
    );
};
export default ThreadPopup;