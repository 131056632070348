import React, {Fragment, useEffect, useState} from 'react';
import TreeView from 'devextreme-react/tree-view';
import ScrollView from 'devextreme-react/scroll-view';
import {setLayersLoaded, setSelectedLayers} from "../../api/actions/actions";
import { useDispatch, useSelector } from 'react-redux'
import ZoomLayerPopup from "../../components/zoomlayer-popup/ZoomLayerPopup";

const LayersMenu = () => {
    const selectModes = ['multiple', 'single'];
    const [selectionModes, setSelectionModes] = useState(selectModes);  // eslint-disable-line no-unused-vars
    const [selectMode, setSelectMode] = useState(selectionModes[0]); // eslint-disable-line no-unused-vars
    const [treeref, setTreeRef] = useState(React.createRef()); // eslint-disable-line no-unused-vars
    const [parentLayers, setParentLayers] = useState(null);
    const [origSubAttrVal, setOrigSubAttrVal] = useState({});
    const [treeComponent ,setTreeComponent] = useState(null);
    const [menuLayers, setMenuLayers] = useState([]);
    const [zoomPopupOpen, setZoomPopupOpen] = useState(false);
    const [cycledLayers, setCycledLayers] = useState([]);
    const map = useSelector((state) => state.mbmap);
    const layers = useSelector((state) => state.selectedlayers);
    const markers = useSelector((state) => state.custom_markers);
    const maploaded = useSelector((state) => state.maploaded);
    const markers_loaded = useSelector((state) => state.markers_loaded);
    const sublabels = useSelector((state) => state.sublabels);
    const map_center = useSelector((state) => state.map_center);
    //const contourlabels = useSelector((state) => state.contourlabels);
    const dispatch = useDispatch();
    const debug_msgs = false;

    const renderTreeViewItem = (item) => {
        return `${item.name}`;
    };

    const treeViewSelectionChanged = (e) => {
        syncSelection(e.component, e.itemData);
    };

    const treeViewContentReady = (e) => {
        //syncSelection(e.component);
        setTreeComponent(e.component);
    };

    const dismissZoomPopup = (doZoom) => {
        if (typeof doZoom === 'undefined') {
            doZoom = false;
        }

        if (doZoom) {
            const zoom_level = zoomPopupOpen.minzoom;
            if (typeof zoom_level !== 'undefined') {
                setMapZoomLevel(zoom_level);
            }
        }
        setZoomPopupOpen(false);
    }

    const setMapZoomLevel = (zoom_level) => {
        map.setZoom(zoom_level);

        map.flyTo({
            center: [map_center.lng, map_center.lat]
        });
    }

    const processSubLabels = (sublabels, clickedLayer, parent_layer, add_sublabels, attrval) => {
        for (let k = 0; k < sublabels.length; k++) {
            if (sublabels[k].layer === clickedLayer.name) {
                const lnextkey = sublabels[k].layeridxs[1] + 1;
                layers[sublabels[k].layeridxs[0]].json_obj.paint[sublabels[k].attribute[0].attribute][lnextkey] = attrval;
                const itemid = typeof parent_layer.mapbox_id !== 'undefined' ? parent_layer.mapbox_id : parent_layer.name;
                const sublabel = {
                    itemid: itemid,
                    name: layers[sublabels[k].layeridxs[0]].name,
                    attrname: sublabels[k].attribute[0].attribute,
                    attrval: layers[sublabels[k].layeridxs[0]].json_obj.paint[sublabels[k].attribute[0].attribute],
                    newjsonobj: parent_layer.json_obj
                }
                add_sublabels.push(sublabel);
                break;
            }
        }
        return add_sublabels;
    };

    useEffect(() => {
        //Do stuff on render

        return unloadLayersMenu();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //Populate menu layers
        const menulayers = [];
        for (let i=0;i<layers.length;i++) {
            if (!layers[i].is_label || (layers[i].visible && layers[i].is_label)) {
                menulayers.push(layers[i]);
            }
        }
        setMenuLayers(menulayers);
        //Sublayer selection doesn't work on first click without this

        if (maploaded && markers_loaded && treeComponent !== null) {
            syncSelection(treeComponent);
        }
    },[maploaded, markers_loaded]);

    const unloadLayersMenu = () => {
        setCycledLayers([]);
        dismissZoomPopup();
    }

    const toggleMarkers = (layer, visible) => {
        markers.map((marker) => {
            const is_custom_layer = layer.id !== null ? layer.id.toString().indexOf('c_') !== -1 : false;
            const found_layer = is_custom_layer ? marker.layer_id === layer.id : parseInt(marker.layer_id) === parseInt(layer.id);
            if (found_layer) {
                const markerDiv = document.getElementById('kw-marker-' + marker.id);
                markerDiv.style.display = visible /*&& map.getZoom() > mapobj.MarkerMinZoom*/ ? '' : 'none';
            }
        });
    };

    const syncSelection = (treeView, clickeditem) => {
        const selectedLayers = treeView.getSelectedNodes()
            .map((node) => node.itemData);

        const newlayers = [];
        layers.map((layer, i) => { //eslint-disable-line array-callback-return
            if (typeof layer.items === 'undefined') {
                layer.selected = selectedLayers.some(e => e.id === layer.id);
                toggleLayer(layer, clickeditem);
            } else if (typeof layer.items !== 'undefined' && !layer.parent_layer) {
                const itemmax = layer.items.length;
                for (let i = 0; i < itemmax; i++) {
                    layer.items[i].selected = selectedLayers.some(e => e.id === layer.items[i].id);
                    if (layer.items[i].parent_layer) {
                        if (layer.items[i].selected) {
                            toggleLayer(layer.items[i], clickeditem);
                        }

                        const sitemmax = layer.items[i].items.length;
                        let subselected = false;
                        for (let s = 0; s < sitemmax; s++) {
                            layer.items[i].items[s].selected = selectedLayers.some(e => e.id === layer.items[i].items[s].id);
                            subselected = !subselected ? layer.items[i].items[s].selected : subselected;
                            toggleSubLayer(s, layer.items[i], s, clickeditem);
                        }

                        if (!subselected) {
                            toggleLayer(layer.items[i], clickeditem);
                        }

                    } else {
                        toggleLayer(layer.items[i], clickeditem);
                    }
                }
            } else if (layer.parent_layer) {

                layer.selected = selectedLayers.some(e => e.id === layer.id);
                if (layer.selected) {
                    toggleLayer(layer, clickeditem);
                }

                const itemmax = layer.items.length;
                let subselected = false;
                for (let i = 0; i < itemmax; i++) {
                    layer.items[i].selected = selectedLayers.some(e => e.id === layer.items[i].id);
                    subselected = !subselected ? layer.items[i].selected : subselected;
                    toggleSubLayer(i, layer, false, clickeditem);
                }

                if (!subselected) {
                    toggleLayer(layer, clickeditem);
                }
            }
            newlayers.push(layer);
        });

        //Set Layer flags for other components
        dispatch(setSelectedLayers(newlayers));
        dispatch(setLayersLoaded(true));
    };

    const toggleLayer = (clickedLayer, clickeditem) => {

        if (typeof clickeditem === 'undefined') { clickeditem = false; }

        const is_custom_layer = clickedLayer.id !== null ? clickedLayer.id.toString().indexOf('c_') !== -1 : false;

        if (is_custom_layer) {
            if (!clickedLayer.selected) {
                //Toggle custom layer markers
                toggleMarkers(clickedLayer, false);
                return false;
            } else if (clickedLayer.selected) {
                //Toggle custom layer markers
                toggleMarkers(clickedLayer, true);
                return true;
            }
        } else {
            let visibility = map.getLayoutProperty(clickedLayer.mapbox_id, 'visibility');

            // toggle layer visibility by changing the layout object's visibility property - initial value for visibility is undefined
            if (!clickedLayer.selected && (visibility === 'visible' || typeof visibility === 'undefined')) {
                if (clickedLayer.is_label && !clickedLayer.visible) {
                    map.setLayoutProperty(clickedLayer.mapbox_id, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(clickedLayer.mapbox_id, 'visibility', 'none');
                    toggleMarkers(clickedLayer, false);
                }
                return false;
            } else if (clickedLayer.selected && (visibility === 'none' || typeof visibility === 'undefined')) {
                map.setLayoutProperty(clickedLayer.mapbox_id, 'visibility', 'visible');
                toggleMarkers(clickedLayer, true);
                if (clickeditem && typeof clickedLayer.json_obj.minzoom !== 'undefined') {
                    if (parseInt(map.getZoom()) < parseInt(clickedLayer.json_obj.minzoom) && clickedLayer.name === clickeditem.name) {
                        setZoomPopupOpen(clickedLayer.json_obj);
                        //alert('Minimum Zoom Level ' + clickedLayer.json_obj.minzoom + ' > ' + map.getZoom());
                    }
                }
                return true;
            }
        }
    };

    const toggleSubLayer = (layer_idx, parent_layer, nested, clickeditem) => {
        const parent_layers = {};

        if (typeof nested === 'undefined') {
            nested = false;
        }

        if (typeof clickeditem === 'undefined') {
            clickeditem = false;
        }

        if (parentLayers === null || typeof parentLayers[parent_layer.id] === 'undefined') {
            parent_layers[parent_layer.id] = parent_layer;
        } else if (typeof parentLayers[parent_layer.id] !== 'undefined') {
            parent_layers[parent_layer.id] = parentLayers[parent_layer.id];
        }
        const clickedLayer = nested && typeof parent_layer.items[layer_idx].items !== 'undefined' ? parent_layer.items[layer_idx].items[nested] : parent_layer.items[layer_idx];
        let attributes = nested && typeof parent_layer.items[layer_idx].items !== 'undefined' ? parent_layers[parent_layer.id].items[layer_idx].items[nested].sub_jsonobj : parent_layers[parent_layer.id].items[layer_idx].sub_jsonobj;
        const subattr =  nested && typeof parent_layer.items[layer_idx].items !== 'undefined' ? parent_layers[parent_layer.id].items[layer_idx].items[nested].subattr : parent_layers[parent_layer.id].items[layer_idx].subattr;
        let found_sublayer = false;

        let add_sublabels = [];
        //let add_cntrlabels = [];
        if (Array.isArray(attributes)) {
            for (let i=0; i < attributes.length; i++) {
                const elem = attributes[i];
                debug_msgs && console.log(elem);
                if (Array.isArray(elem) && !Array.isArray(elem[0])) {
                    if (elem[0].length && elem[0].indexOf('_sub_') !== -1 && clickedLayer.name === elem[0].replace('_sub_', '')) {
                        debug_msgs && console.log('Non-conditional sublayer found: ' + clickedLayer.name);
                        if (!clickedLayer.selected) {
                            debug_msgs && console.log('layer unchecked');
                            const nextKey = i + 1;
                            debug_msgs && console.log(parseInt(attributes[nextKey]));
                            if (!isNaN(parseInt(attributes[nextKey]))) {
                                if (typeof origSubAttrVal[parent_layer.id] === 'undefined') { origSubAttrVal[parent_layer.id] = {}; }
                                if (typeof origSubAttrVal[parent_layer.id][elem[0]] === 'undefined') {
                                    origSubAttrVal[parent_layer.id][elem[0]] = attributes[nextKey];
                                    setOrigSubAttrVal(origSubAttrVal);
                                }
                                debug_msgs && console.log('Changing ' + subattr + ' for ' + clickedLayer.name + ' to 0');
                                attributes[nextKey] = 0;

                                add_sublabels = processSubLabels(sublabels, clickedLayer, parent_layer, add_sublabels, 0);
                                //add_cntrlabels = processSubLabels(contourlabels, clickedLayer, parent_layer, add_cntrlabels, 0);
                            }
                        } else {
                            debug_msgs && console.log('layer checked');
                            const nextKey = i + 1;
                            debug_msgs && console.log(parseInt(attributes[nextKey]));
                            if (!isNaN(parseInt(attributes[nextKey]))) {
                                if (typeof origSubAttrVal[parent_layer.id] === 'undefined') { origSubAttrVal[parent_layer.id] = {}; }
                                if (typeof origSubAttrVal[parent_layer.id][elem[0]] === 'undefined') {
                                    origSubAttrVal[parent_layer.id][elem[0]] = attributes[nextKey];
                                    setOrigSubAttrVal(origSubAttrVal);
                                }
                                debug_msgs && console.log('Changing ' + subattr + ' for ' + clickedLayer.name + ' to ' + origSubAttrVal[parent_layer.id][elem[0]]);
                                attributes[nextKey] = typeof origSubAttrVal[parent_layer.id] !== 'undefined' && typeof origSubAttrVal[parent_layer.id][elem[0]] !== 'undefined' ? origSubAttrVal[parent_layer.id][elem[0]] : 0;

                                add_sublabels = processSubLabels(sublabels, clickedLayer, parent_layer, add_sublabels, 1);
                                //add_cntrlabels = processSubLabels(contourlabels, clickedLayer, parent_layer, add_cntrlabels, 1);

                                if (clickeditem && typeof parent_layer.json_obj.minzoom !== 'undefined') {
                                    if (parseInt(map.getZoom()) < parseInt(parent_layer.json_obj.minzoom) && clickedLayer.name === clickeditem.name) {
                                        setZoomPopupOpen(parent_layer.json_obj);
                                        //alert('Minimum Zoom Level ' + parent_layer.json_obj.minzoom + ' > ' + map.getZoom());
                                    }
                                }
                            }
                        }
                        found_sublayer = true;

                    }
                    if (found_sublayer) { break }

                } else if (Array.isArray(elem)) {
                    for (let s = 0; s < elem.length; s++) {
                        if (Array.isArray(elem[s]) && elem[s].length && elem[s][0].indexOf('_sub_') !== -1 && clickedLayer.name === elem[s][0].replace('_sub_', '')) {
                            debug_msgs && console.log('Conditional sublayer found: ' + clickedLayer.name);
                            if (!clickedLayer.selected) {
                                debug_msgs && console.log('layer unchecked');
                                const nextKey = s + 1;
                                debug_msgs && console.log(parseInt(elem[nextKey]));
                                if (!isNaN(parseInt(elem[nextKey]))) {
                                    if (typeof origSubAttrVal[parent_layer.id] === 'undefined') { origSubAttrVal[parent_layer.id] = {}; }
                                    if (typeof origSubAttrVal[parent_layer.id][elem[s][0]] === 'undefined') {
                                        origSubAttrVal[parent_layer.id][elem[s][0]] = attributes[i][nextKey];
                                        setOrigSubAttrVal(origSubAttrVal);
                                    }
                                    debug_msgs && console.log('Changing ' + subattr + ' for ' + clickedLayer.name + ' to 0');
                                    attributes[i][nextKey] = 0;

                                    add_sublabels = processSubLabels(sublabels, clickedLayer, parent_layer, add_sublabels, 0);
                                    //add_cntrlabels = processSubLabels(contourlabels, clickedLayer, parent_layer, add_cntrlabels, 0);

                                }
                            } else {
                                debug_msgs && console.log('layer checked');
                                const nextKey = s + 1;
                                debug_msgs && console.log(parseInt(elem[nextKey]));
                                if (!isNaN(parseInt(elem[nextKey]))) {
                                    if (typeof origSubAttrVal[parent_layer.id] === 'undefined') { origSubAttrVal[parent_layer.id] = {}; }
                                    if (typeof origSubAttrVal[parent_layer.id][elem[s][0]] === 'undefined') {
                                        origSubAttrVal[parent_layer.id][elem[s][0]] = attributes[i][nextKey];
                                        setOrigSubAttrVal(origSubAttrVal);
                                    }
                                    debug_msgs && console.log('Changing ' + subattr + ' for ' + clickedLayer.name + ' to ' + origSubAttrVal[parent_layer.id][elem[s][0]]);
                                    attributes[i][nextKey] = typeof origSubAttrVal[parent_layer.id] !== 'undefined' && typeof origSubAttrVal[parent_layer.id][elem[s][0]] !== 'undefined' ? origSubAttrVal[parent_layer.id][elem[s][0]] : 0;

                                    add_sublabels = processSubLabels(sublabels, clickedLayer, parent_layer, add_sublabels, 1);
                                    //add_cntrlabels = processSubLabels(contourlabels, clickedLayer, parent_layer, add_cntrlabels, 1);

                                    if (clickeditem && typeof parent_layer.json_obj.minzoom !== 'undefined') {
                                        if (parseInt(map.getZoom()) < parseInt(parent_layer.json_obj.minzoom) && clickedLayer.name === clickeditem.name) {
                                            setZoomPopupOpen(parent_layer.json_obj);
                                            //alert('Minimum Zoom Level ' + parent_layer.json_obj.minzoom + ' > ' + map.getZoom());
                                        }
                                    }
                                }
                            }
                            found_sublayer = true;

                        }
                        if (found_sublayer) { break }
                    }
                }
                if (found_sublayer) { break }
            }
        }

        if (nested && typeof parent_layers[parent_layer.id].items[layer_idx].items !== 'undefined') {
            parent_layers[parent_layer.id].items[layer_idx].items[nested].sub_jsonobj = typeof attributes !== 'undefined' ? attributes : parent_layers[parent_layer.id].items[layer_idx].items[nested].sub_jsonobj;
            parent_layer.items[nested].json_obj.paint[subattr] = attributes;
            parent_layers[parent_layer.id].items[layer_idx].json_obj.paint[subattr] = attributes;
        } else {
            parent_layers[parent_layer.id].items[layer_idx].sub_jsonobj = typeof attributes !== 'undefined' ? attributes : parent_layer.items[layer_idx].sub_jsonobj;
            parent_layer.json_obj.paint[subattr] = attributes;
            parent_layers[parent_layer.id].json_obj.paint[subattr] = attributes;
        }
        dispatch(setSelectedLayers(layers));
        setParentLayers(parent_layers);
        parent_layer.items[layer_idx] = parent_layers[parent_layer.id].items[layer_idx];

        if (!add_sublabels.length /*&& !cycledLayers.includes(parent_layer.name)*/) {
            map.removeLayer(parent_layer.name);
            map.addLayer(parent_layer.json_obj);
            cycledLayers.push(parent_layer.name);
            setCycledLayers(cycledLayers);
        }

        for (let s = 0; s < add_sublabels.length; s++) {
            const sublabel = add_sublabels[s];
            map.removeLayer(sublabel.itemid);
            map.addLayer(sublabel.newjsonobj, sublabel.name);
            map.setLayoutProperty(sublabel.name, 'visibility', 'visible');
            map.setPaintProperty(sublabel.name, sublabel.attrname, sublabel.attrval);

            if (!cycledLayers.includes(sublabel.itemid)) {
                cycledLayers.push(sublabel.itemid);
                setCycledLayers(cycledLayers);
            }
        }
    };

    return (
        <Fragment>
            <h3 className="menu-title">Layers</h3>
            <nav id="menu">
                <ScrollView width="100%" height="100%">
                    <TreeView
                        id="treeview"
                        ref={treeref}
                        width={340}
                        height="100%"
                        items={menuLayers}
                        noDataText="Loading..."
                        selectNodesRecursive={true}
                        selectByClick={false}
                        onItemSelectionChanged={treeViewSelectionChanged}
                        showCheckBoxesMode="normal"
                        selectionMode={selectMode}
                        onContentReady={treeViewContentReady}
                        itemRender={renderTreeViewItem}
                    />
                </ScrollView>
            </nav>
            {zoomPopupOpen !== false &&
                <ZoomLayerPopup title="Layer Visibility" message={'minimum zoom level not met. Please zoom to level ' + zoomPopupOpen.minzoom + ' or higher'} layer={zoomPopupOpen} showPopup={setZoomPopupOpen} cancelCallback={dismissZoomPopup} confirmCallback={dismissZoomPopup}/>
            }
        </Fragment>
    );
}
export default LayersMenu;