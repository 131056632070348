export const CoordinatesTool = (() => {
    let instance;

    function createInstance() {
        const coordinatesdisplay = document.getElementById('lnglatzoom-display');

        return {
            create: () => {
                coordinatesdisplay.style.display = 'inline-block';
            },
            destroy: () => {
                coordinatesdisplay.style.display = 'none';
            },
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();