import store from "../../api/store/store";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {CommentsMarkerTool} from "./CommentsMarkerTool";
import {
    setShowThreadPopup,
    setMarkerType,
    setGeoJsonData,
    setPolygonCoordinates,
    setPolygonColor,
    setCommentsMarkerTool
} from "../../api/actions/actions";

export const CommentsAreaTool = (() => {
    let instance;

    function createInstance() {
        const map = store.getState().mbmap;
        let draw, commentsdata;

        return {
            addPolygon: (polygonobj) => {
                if (polygonobj.type === 'polygon') {
                    const coordinates = JSON.parse(polygonobj.json);
                    try {
                        map.addSource('polygon-'+polygonobj.id, {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Polygon',

                                    'coordinates': coordinates
                                }
                            }
                        });

                        let fillcolor = '#D20C0C';
                        let opacity = 0.1;
                        if (typeof polygonobj.color !== 'undefined' && polygonobj.color !== null) {
                            fillcolor = polygonobj.color;
                        }
                        if (typeof polygonobj.opacity !== 'undefined' && polygonobj.opacity !== null) {
                            opacity = parseFloat(polygonobj.opacity);
                        }

                        // Add line
                        map.addLayer({
                            "id": 'line-'+polygonobj.id,
                            "type": "line",
                            'source': 'polygon-'+polygonobj.id, // reference the data source
                            "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                            "layout": {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            "paint": {
                                "line-color": fillcolor,
                                "line-dasharray": [0.2, 2],
                                "line-width": 2
                            }
                        });

                        // Add a new layer to visualize the polygon.
                        map.addLayer({
                            'id': 'pg-'+polygonobj.id,
                            'type': 'fill',
                            'source': 'polygon-'+polygonobj.id, // reference the data source
                            "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                            'layout': {},
                            'paint': {
                                "fill-color": fillcolor,
                                "fill-outline-color": fillcolor,
                                "fill-opacity": opacity
                            }
                        });
                        map.addLayer({
                            'id': 'points-'+polygonobj.id,
                            'type': 'circle',
                            'source': 'polygon-'+polygonobj.id, // reference the data source
                            'filter': ['all',
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'midpoint']],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': '#fbb03b'
                            }
                        });
                        // Add outline around the polygon.
                        map.addLayer({
                            'id': 'outline-'+polygonobj.id,
                            'type': 'line',
                            'source': 'polygon-'+polygonobj.id,
                            "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                            "layout": {
                                'line-cap': "round",
                                "line-join": "round"
                            },
                            'paint': {
                                "line-color": "#D20C0C",
                                "line-dasharray": [0.2, 2],
                                "line-width": 1
                            }
                        });
                        //Add point halos
                        map.addLayer({
                            "id": 'pointholes-'+polygonobj.id,
                            "type": "circle",
                            'source': 'polygon-'+polygonobj.id,
                            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                            "paint": {
                                "circle-radius": 5,
                                "circle-color": "#FFF"
                            }
                        });
                        //Add vertex points
                        map.addLayer({
                            "id": 'points2-'+polygonobj.id,
                            "type": "circle",
                            'source': 'polygon-'+polygonobj.id,
                            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                            "paint": {
                                "circle-radius": 3,
                                "circle-color": "#D20C0C",
                            }
                        });
                    } catch(err) {

                    }
                }
            },
            removePolygon: (id) => {
                try {
                    if (map !== null) {
                        map.getLayer('line-' + id) && map.removeLayer('line-' + id);
                        map.getLayer('pg-' + id) && map.removeLayer('pg-' + id);
                        map.getLayer('points-' + id) && map.removeLayer('points-' + id);
                        map.getLayer('points2-' + id) && map.removeLayer('points2-' + id);
                        map.getLayer('pointholes-' + id) && map.removeLayer('pointholes-' + id);
                        map.getLayer('outline-' + id) && map.removeLayer('outline-' + id);
                        map.getSource('polygon-' + id) && map.removeSource('polygon-' + id);
                    }

                } catch (err) {
                }
            },
            create: () => {
                CommentsMarkerTool.hasInstance() && CommentsMarkerTool.getInstance().destroy();
                store.dispatch(setCommentsMarkerTool(false));
                commentsdata = store.getState().commentsdata;

                let fillcolor = '#D20C0C';
                if (typeof store.getState().polygoncolor !== 'undefined' && store.getState().polygoncolor !== null) {
                    fillcolor = store.getState().polygoncolor;
                }

                let opacity = 0.1;
                if (typeof store.getState().opacity !== 'undefined' && store.getState().opacity !== null) {
                    opacity = parseFloat(store.getState().polygonopacity);
                }


                draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        /*polygon: true,*/
                        trash: true
                    },
                    styles: [
                        // ACTIVE (being drawn)
                        // line stroke
                        {
                            "id": "gl-draw-line",
                            "type": "line",
                            "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                            "layout": {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            "paint": {
                                "line-color": "#D20C0C",
                                "line-dasharray": [0.2, 2],
                                "line-width": 2
                            }
                        },
                        // polygon fill
                        {
                            "id": "gl-draw-polygon-fill",
                            "type": "fill",
                            "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                            "paint": {
                                "fill-color": fillcolor,
                                "fill-outline-color": fillcolor,
                                "fill-opacity": opacity
                            }
                        },
                        // polygon mid points
                        {
                            'id': 'gl-draw-polygon-midpoint',
                            'type': 'circle',
                            'filter': ['all',
                                ['==', '$type', 'Point'],
                                ['==', 'meta', 'midpoint']],
                            'paint': {
                                'circle-radius': 3,
                                'circle-color': '#fbb03b'
                            },
                        },
                        // polygon outline stroke
                        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                        {
                            "id": "gl-draw-polygon-stroke-active",
                            "type": "line",
                            "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                            "layout": {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            "paint": {
                                "line-color": "#D20C0C",
                                "line-dasharray": [0.2, 2],
                                "line-width": 2
                            }
                        },
                        // vertex point halos
                        {
                            "id": "gl-draw-polygon-and-line-vertex-halo-active",
                            "type": "circle",
                            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                            "paint": {
                                "circle-radius": 5,
                                "circle-color": "#FFF"
                            }
                        },
                        // vertex points
                        {
                            "id": "gl-draw-polygon-and-line-vertex-active",
                            "type": "circle",
                            "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                            "paint": {
                                "circle-radius": 3,
                                "circle-color": "#D20C0C",
                            }
                        },

                        // INACTIVE (static, already drawn)
                        // line stroke
                        {
                            "id": "gl-draw-line-static",
                            "type": "line",
                            "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                            "layout": {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            "paint": {
                                "line-color": "#000",
                                "line-width": 3
                            }
                        },
                        // polygon fill
                        {
                            "id": "gl-draw-polygon-fill-static",
                            "type": "fill",
                            "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                            "paint": {
                                "fill-color": "#000",
                                "fill-outline-color": "#000",
                                "fill-opacity": 0.1
                            }
                        },
                        // polygon outline
                        {
                            "id": "gl-draw-polygon-stroke-static",
                            "type": "line",
                            "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                            "layout": {
                                "line-cap": "round",
                                "line-join": "round"
                            },
                            "paint": {
                                "line-color": "#000",
                                "line-width": 3
                            }
                        }
                    ]
                });
                !map.hasControl(draw) && map.addControl(draw);
                draw.changeMode('draw_polygon');

                map.on('draw.create', CommentsAreaTool.getInstance().updateArea);
                map.on('draw.delete', CommentsAreaTool.getInstance().updateArea);
                map.on('draw.update', CommentsAreaTool.getInstance().updateArea);

                if (commentsdata !== null) {
                    for (let i = 0;i < commentsdata.length; i++) {
                        if (commentsdata[i].type === 'polygon') {
                            CommentsAreaTool.getInstance().addPolygon(commentsdata[i]);
                        }
                    }
                }

            },
            destroy: (reset) => {
                if (map !== null) {
                    commentsdata = store.getState().commentsdata;

                    try {
                        map.removeControl(draw);
                    } catch (e) {
                        //Do nothing as the control already doesn't exist
                    }

                    if (commentsdata !== null) {
                        for (let i = 0;i < commentsdata.length; i++) {
                            if (commentsdata[i].type === 'polygon') {
                                CommentsAreaTool.getInstance().removePolygon(commentsdata[i].id);
                            }
                        }
                    }
                    map.getSource('polygon-n') && CommentsAreaTool.getInstance().removePolygon('n');
                    store.dispatch(setPolygonColor('#d20c0c'));
                    store.dispatch(setPolygonCoordinates(null));
                    store.dispatch(setShowThreadPopup(false));
                    typeof reset === 'undefined' || !reset && store.dispatch(setGeoJsonData(null)); //eslint-disable-line no-mixed-operators
                }
            },
            convertNewPolygonToLayer: () => {
                if (store.getState().polygoncoords === null) {
                    const data = draw.getAll();
                    if (data.features.length > 0) {
                        store.dispatch(setPolygonCoordinates(data.features[0].geometry.coordinates));
                    }
                }
                const coordinates = store.getState().polygoncoords;
                const color = store.getState().polygoncolor;
                const opacity = store.getState().polygonopacity;
                if (coordinates !== null) {
                    if (map !== null) {
                        map.getSource('polygon-n') && CommentsAreaTool.getInstance().removePolygon('n');
                    }
                    const polygonobj = {
                        id: 'n',
                        json: JSON.stringify(coordinates),
                        color: color,
                        opacity: opacity,
                        type: 'polygon'
                    };
                    CommentsAreaTool.getInstance().addPolygon(polygonobj);
                    setTimeout(() => {
                        try {
                            map.removeControl(draw);
                            map.addControl(draw);
                        } catch (e) {
                            //Do nothing as the control already doesn't exist
                        }
                    }, 2000);
                }

            },
            updateArea: (e) => {
                var data = draw.getAll();
                if (data.features.length > 0) {
                    const coordsarr = data.features[0].geometry.coordinates;
                    store.dispatch(setGeoJsonData(JSON.stringify(coordsarr)));
                    store.dispatch(setMarkerType('polygon'));
                    store.dispatch(setShowThreadPopup(true));
                } else {
                    map.removeControl(draw);
                    map.addControl(draw);
                    draw.changeMode('draw_polygon');
                    store.dispatch(setGeoJsonData(null));
                    if (e.type !== 'draw.delete')
                        alert('Use the draw tools to draw a polygon!');
                }
            },
            resetDraw: () => {
                try {
                    map.getSource('polygon-n') && CommentsAreaTool.getInstance().removePolygon('n');
                    CommentsAreaTool.getInstance().destroy();
                    CommentsAreaTool.getInstance().create();
                } catch (e) {
                    //Do nothing as the control already doesn't exist
                }
            }
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        hasInstance: function () {
            if (!instance) {
                return false;
            } else {
                return true;
            }
        }
    };
})();