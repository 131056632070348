import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step11 = ({step}) => {

    const captions = {
        0: 'The Comments tool can be found in the top toolbar.',
        1: 'The buttons at the top of the comments window allow you to add a new comment thread, for example a marker thread.',
        2: 'You can place the marker by clicking or dragging your mouse. A window will come up where you can type your comment regarding the marker you placed.',
        3: 'You can pick the color of the marker and type your comment. Once you have typed your comment, you can click save to save your new comment thread.',
        4: 'You can view your new comment thread by scrolling to the bottom of the comment thread list, and click "View Thread"',
        5: 'You can edit the thread subscriptions and see other people\'s replies or comments.',
        6: 'Another thread type, is the Area comment thread, which can also be found at the top of the comment thread window.',
        7: 'Once you click the button for the Area comment thread, place the points at the boundaries of the polygon that you want to highlight.',
        8: 'When you are ready to place your last point for your polygon, double-click to place the last point.',
        9: 'A window will come up where you can type a comment, pick the background color, and opacity for your new polygon.',
        10: 'Once you have picked a color, opacity, and typed your comment, click Save to save your new area comment thread.',
        11: 'The Area comment thread works much the same as the marker comment thread.',
        12: 'Comment threads also have a status that you can change to let others know where a the disussion is in your thread.'
    }

    const timeIndexes = [0, 9.0, 18.0, 29.0, 67.0, 80.0, 115.0, 128.0, 157.0, 165.0, 195.0, 202.0, 227.0]

    return <MT_Video step={step} basictutorial={true} title="Using the Comments tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/723276633/8f3daf265d"/>;
}
export default MT_Step11;