import React, {useState, useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, Position } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, {Item} from "devextreme-react/toolbar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import './userviews.scss';
import {kwapi, user} from "../../api/kwapi";
import ConfirmPopup from "../../components/confirmpopup/ConfirmPopup";
import {MapViewsTool} from "../../components/header/MapViewsTool";
import {
    setError, setSharePopupText, setSharePopupURL, setSharePopupVisible,
    setShowViews,
    setShowViewsPopup,
    setViewsData
} from "../../api/actions/actions";

const UserViews  = () => {
    const dispatch = useDispatch();
    const showViews = useSelector((state) => state.showviews);
    const viewsdata = useSelector((state) => state.viewsdata);
    const [showconfirm, setShowConfirm] = useState(false);
    const [actionobj, setActionObj] = useState(null);
    const account = kwapi.getClientAccount();
    const mbmap = useSelector((state) => state.mbmap);
    const mapObj = useSelector((state) => state.map);

    const hideViews = () => {
        dispatch(setShowViews(false));
        dispatch(setShowViewsPopup(false));
        if (MapViewsTool.hasInstance()) {
            MapViewsTool.getInstance().destroy();
        }
    };

    const addViewButtonOptions = {
        icon: 'plus',
        elementAttr: {title: 'New Map View'},
        onClick: () => {
            dispatch(setShowViewsPopup(true));
        }
    };

    const activateView = (e) => {
        const id = e.target.id.replace('view-thread-', '');
        const view = viewsdata.find(x => x.id.toString() === id.toString())
        mbmap.setPitch(view.pitch);
        mbmap.setBearing(view.bearing);
        mbmap.setZoom(view.zoom);
        const centercoords = JSON.parse(view.center);
        mbmap.setCenter([centercoords.lng,centercoords.lat]);
    };

    const shareView = (e) => {
        const id = e.target.id.replace('share-thread-', '');
        dispatch(setSharePopupURL(kwapi.getAppURL()+'mapview/'+id+'/'+mapObj.URLToken));
        dispatch(setSharePopupText('Shareable Map View URL'));
        dispatch(setSharePopupVisible(true));
    };

    const onViewDelete = (viewobj) => {
        setActionObj(viewobj);
        setShowConfirm(true);
    };

    const doViewDelete = () => {
        //match user
        if (parseInt(account.id) === parseInt(actionobj.client_user_id)) {
            kwapi.deleteMapView(actionobj, viewDeleteSuccess, viewError);
        }
    };

    const viewDeleteSuccess = (view_id) => {
        setActionObj(null);
        const viewidx = viewsdata.findIndex(x => x.id.toString() === view_id.toString());
        const updateViewsData = viewsdata;
        updateViewsData.splice(viewidx, 1);
        dispatch(setViewsData(updateViewsData));
    };

    const viewError = (error) => {
        dispatch(setError(error));
    };

    //Dynamically change popup position based on screen size
    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars
    const offsetY = height > 800 ? 299 : height > 760 ? 250 : 100;
    const offsetX = height > 760 ? 86 : 70;
    const popupHeight = height > 940 ? 600 : 470;


    if (kwapi.getMapView() !== null) {
        const e = {
            target: {
                id: 'view-thread-'+kwapi.getMapView()
            }
        }
        activateView(e);
        kwapi.unsetMapView();
    }

    return (
        <Fragment>
            <Popup
                visible={showViews}
                onHiding={hideViews}
                resizeEnabled={true}
                dragEnabled={true}
                focusStateEnabled={true}
                closeOnOutsideClick={false}
                showCloseButton={true}
                shading={false}
                showTitle={true}
                title={viewsdata !== null ? viewsdata.length + ' Map Views' : ''}
                container=".dx-viewport"
                defaultWidth={390}
                defaultHeight={popupHeight}
                minWidth={260}
                maxWidth={600}
                className='user-views'
            >
                <Position
                    at="bottom"
                    my="right"
                    of={'#mapboxgl-ctrl-top-right'}
                    offset={offsetX + ' ' + offsetY}
                />
                <Toolbar className={'views-toolbar'}>
                    <Item
                        widget="dxButton"
                        toolbar="top"
                        location="leftr"
                        cssClass={'btn-marker-tool'}
                        options={addViewButtonOptions}
                    />
                </Toolbar>
                <ScrollView width="100%" height="100%">
                    <div style={{height: '100%'}}>
                        <Fragment>
                        {viewsdata !== null && viewsdata.length > 0 && viewsdata.map((viewobj, i) => { //eslint-disable-line array-callback-return
                            return (
                                <div key={i} className="map-view">
                                    <div className="view" id="view-1">
                                        <summary>
                                            <div className="view-heading">
                                                <div className="comment-icon">
                                                    <img className="view-icon" alt="View Icon" src={kwapi.getCDNURL()+"assets/images/views-icon.png"}/>
                                                </div>
                                                <div className="view-info">
                                                    <span
                                                        className="view-name">{viewobj.name}</span>
                                                    {(user.isAdminAccount() || account.id.toString() === viewobj.client_user_id.toString()) &&
                                                        <Fragment>
                                                            <button id={'delete-btn-'+viewobj.id} type="button" className="delete-btn action-btn" title="Delete Map View" onClick={() => {onViewDelete(viewobj)}}><i className="dx-icon dx-icon-trash"/></button>
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </summary>

                                        <div className="view-body">
                                            <p>
                                                {viewobj.description}
                                            </p>
                                            <button id={'view-thread-' + viewobj.id} className="view-view-btn" type="button"
                                                    data-toggle="view-thread" onClick={activateView}>Activate View
                                            </button>
                                            <button id={'share-thread-' + viewobj.id} onClick={shareView} className="share-view-btn" type="button">
                                                <i className="dx-icon dx-icon-share"/>
                                                Share
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {(viewsdata === null || !viewsdata.length) &&
                            <div className="map-view">
                                <details open className="view" id="view-1">
                                    <summary>
                                        <div className="view-heading">
                                            <div className="view-info">
                                                There are no saved views on this map
                                            </div>
                                        </div>
                                    </summary>
                                </details>
                            </div>
                        }
                        </Fragment>
                    </div>
                </ScrollView>
            </Popup>
            {showconfirm &&
                <ConfirmPopup title="Delete View" message="Are you sure you want to delete this map view? This cannot be undone and will permanently deleted this view!" showConfirm={setShowConfirm} confirmCallback={doViewDelete}/>
            }
        </Fragment>
    );
}
export default UserViews;