import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step8 = ({step}) => {
    const captions = {
        0: 'The Elevation contours tool can be found in the top toolbar.',
        1: 'Once activated, the elevation contours for the map will appear.',
        2: 'You can click on the terrain selector to switch between aerial, satellite, and other terrains.',
    }

    const timeIndexes = [0, 3.0, 7.0]

    return <MT_Video step={step} basictutorial={false} title="Using the Elevation contours tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/698024647"/>
}
export default MT_Step8;