import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step5 = ({step}) => {

    const captions = {
        0: 'Map layers can be found by clicking the layers menu in the top left of the map.',
        1: 'Layers may be grouped under a category.  Click a category to expand it and see its underlying layers.',
        2: 'Click the checkbox next to a layer to toggle the layer on/off.',
        3: 'You can click the checkbox next to a category to toggle all layers under that category on/off.',
    }

    const timeIndexes = [0, 7.0, 11.0, 16.0]

    return <MT_Video step={step} basictutorial={true} title="Toggling Map Layers" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/723254274/4c5fd9f990"/>;
}
export default MT_Step5;