import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step7 = ({step}) => {

    const captions = {
        0: 'The Polygon area tool can be found in the top toolbar.',
        1: 'Once activated, click on the map to place points to mark the border of your polygon.',
        2: 'You can add as many points as needed to match the boundaries of any shape.',
        3: 'Once you re-connect with your first point, double-click on the last point to get the area measurement.',
        4: 'The trash can icon removes/resets any measurement points and polygons that you have on the map.',
    }

    const timeIndexes = [0, 4.0, 10.2, 32.0, 40.0]

    return <MT_Video step={step} basictutorial={false} title="Using the Polygon area tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/698024618"/>
}
export default MT_Step7;