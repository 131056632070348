import {kwapi} from "../../api/kwapi";
import store from "../../api/store/store";
import {useSelector, useDispatch} from "react-redux";
import {Popup} from "devextreme-react/popup";
import { Button } from 'devextreme-react/button';
import React, {Fragment} from "react";
import {setToastVisible, setToastConfig} from "../../api/actions/actions";

export default ({sharevisible, dismissPopup}) => {
    const dispatch = useDispatch();
    const sharepopuptext = useSelector((state) => state.sharepopuptext);
    const sharepopupurl = useSelector((state) => state.sharepopupurl);
    const shareText = sharepopuptext !== null ? sharepopuptext : 'Shareable Map URL';


    const toastConfig = {
        type: 'info',
        message: ''
    };

    const copyStringToClipboard = (str) => {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    };

    const handleCopy = () => {
        copyStringToClipboard(document.getElementById('map_url').value);
        dispatch(setToastConfig({
            ...toastConfig,
            type: 'success',
            message: 'URL has been copied',
            onHiding: onFlashHiding,
            time: 2000
        }));
        dispatch(setToastVisible(true));
        dismissPopup();
    };

    const onFlashHiding = () => {
        dispatch(setToastVisible(false));
    };
    //map_url token is undefined in development environment
    const inputURL = sharepopupurl !== null ? sharepopupurl : kwapi.getAppURL() + 'map/' + store.getState().map.URLToken;
    return (
        <Fragment>
            {sharevisible &&
            <Popup
                visible={sharevisible}
                onHiding={dismissPopup}
                dragEnabled={true}
                resizeEnabled={false}
                className="share-popup"
                closeOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={shareText}
                width={460}
                height={200}
            >
                <h4 style={{marginTop: 0}}>{shareText}</h4>
                <p>
                    <input id="map_url" type="text" className="dx-texteditor-input"
                           style={{width: '298px', border: '1px solid #a8a8a8'}}
                           value={inputURL} readOnly={true}/>
                    <Button
                        id="copy-map-url"
                        width={100}
                        icon="copy"
                        text="Copy"
                        type="default"
                        stylingMode="contained"
                        onClick={handleCopy}
                    />
                </p>
            </Popup>
            }
        </Fragment>
    )
}