import React from 'react';
import Form, { ButtonItem, GroupItem, SimpleItem, Item, Label, CompareRule, RequiredRule, StringLengthRule, AsyncRule, EmailRule } from 'devextreme-react/form';
import 'devextreme-react/autocomplete';
import { LoadPanel } from 'devextreme-react/load-panel';
import service from './data.js';
import {kwapi, user} from '../../api/kwapi';
import store from '../../api/store/store';
import {setMBMap, setGeoJson} from '../../api/actions/actions'
import notify from 'devextreme/ui/notify';
import './profile.scss';

class Profile extends React.Component {
  constructor() {
    super();
    kwapi.unsetThreadRoute();
    kwapi.getClientAccount();
    this.states = service.getStates();
    this.countries = service.getCountries();
    store.dispatch(setMBMap(null));
    store.dispatch(setGeoJson(null));

    this.validationRules = {
      email: [
        { type: 'required', message: 'E-mail is required.'},
        { type: 'aync', }
      ],
      firstname: [
        { type: 'required', message: 'Firstname is required.'}
      ],
      lastname: [
        { type: 'required', message: 'Firstname is required.'}
      ],
      company: [
        { type: 'required', message: 'Company is required.'}
      ],
      address: [
        { type: 'required', message: 'Address is required.'}
      ],
      city: [
        { type: 'required', message: 'City is required.'}
      ],
      state: [
        { type: 'required', message: 'State is required.'}
      ],
      zipcode: [
        { type: 'required', message: 'Zipcode is required.'}
      ],
      country: [
        { type: 'required', message: 'Country is required.'}
      ]
    };

    this.validateForm = (e) => {
      e.component.validate();
    };

    this.updateBtnOptions = {
      text: 'Update',
      type: 'default',
      useSubmitBehavior: true
    };

    this.cancelBtnOptions = {
      text: 'Cancel',
      type: 'normal',
      useSubmitBehavior: false,
      onClick: this.cancelHandler
    };

    this.phoneEditorOptions = {
      mask: '+1 (X00) 000-0000',
      maskRules: {
        X: /[02-9]/
      },
      useMaskedValue: true,
      maskInvalidMessage: 'The phone must have a correct USA phone format'
    };

    this.state = {
      clientacct: null,
      curpassclass: 'hidden-field'
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordComparison = this.passwordComparison.bind(this);
  }

  componentWillMount() {
    this.pullClientAccount();
  }
  componentDidMount() {
    user.setJustSignedIn(false);
  }

  pullClientAccount = () => {
     const clientacct = kwapi.getClientAccount();
     if (typeof clientacct !== 'undefined') {
       this.setState({clientacct: clientacct});
     } else {
        setTimeout(() => {
            this.pullClientAccount();
        }, 300);
     }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const {clientacct, curpassclass} = this.state;
    return (
        <React.Fragment>
          { clientacct === null ?
              <LoadPanel
                  shadingColor="rgba(0,0,0,0.4)"
                  visible={true}
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
              />
              :
              <React.Fragment>
                <div className="back-arrow" title="Back to previous screen">
                  <img src={kwapi.getCDNURL()+"assets/images/back-circle.png"} alt="< Back" onClick={this.cancelHandler}/>
                </div>
                <div className="long-title"><h3>Account Settings</h3></div>
                <div className="form-container settings-form">
                  <form onSubmit={this.handleSubmit}>
                    <Form
                        onContentReady={this.validateForm}
                        colCount={2}
                        id="form"
                        formData={clientacct}>
                      <GroupItem caption="Credentials">
                        <SimpleItem dataField="email" editorType="dxTextBox">
                          <Label text="E-mail"/>
                          <RequiredRule message="Email is required" />
                          <EmailRule message="Email is invalid" />
                          <AsyncRule
                              message="Email is already registered to another account"
                              validationCallback={this.asyncEmailValidation} />
                        </SimpleItem>
                        <Item dataField="password" editorOptions={{ mode: 'password'}}>
                          <StringLengthRule ignoreEmptyValue={true} message="Password must have at least 8 characters" min={8} />
                        </Item>
                        <SimpleItem dataField="confirm_password" editorType="dxTextBox" editorOptions={{ mode: 'password'}}>
                          <Label text="Confirm Password" />
                          <CompareRule
                              message="Password and Confirm Password do not match"
                              comparisonTarget={this.passwordComparison}
                          />
                        </SimpleItem>
                      </GroupItem>
                      <GroupItem caption="Contact Information">
                        <SimpleItem dataField="firstname" validationRules={this.validationRules.firstname}>
                          <Label text="Firstname" />
                        </SimpleItem>
                        <Item dataField="lastname" validationRules={this.validationRules.lastname}>
                          <Label text="Lastname" />
                        </Item>
                        <Item dataField="phone" editorOptions={this.phoneEditorOptions}>
                          <Label text="Contact Phone"/>
                          <RequiredRule message="Contact Phone is required" />
                        </Item>
                      </GroupItem>
                      <GroupItem caption="Company Information">
                        <Item dataField="name" validationRules={this.validationRules.company} disabled={user.isKWAccount()}>
                          <Label text="Company Name"/>
                        </Item>
                        <Item dataField="address" validationRules={this.validationRules.address} disabled={user.isKWAccount()} />
                        <Item dataField="city" validationRules={this.validationRules.city} disabled={user.isKWAccount()} />
                        <Item dataField="state" editorType="dxSelectBox" editorOptions={{ items: this.states, searchEnabled: true }} validationRules={this.validationRules.state} disabled={user.isKWAccount()} />
                        <Item dataField="zipcode" validationRules={this.validationRules.zipcode} disabled={user.isKWAccount()} />
                        <Item dataField="country" editorType="dxSelectBox" editorOptions={{ items: this.countries, searchEnabled: true }} validationRules={this.validationRules.country} disabled={user.isKWAccount()} />
                        <SimpleItem cssClass={curpassclass} dataField="current_password" editorType="dxTextBox" editorOptions={{ mode: 'password'}}>
                          <StringLengthRule ignoreEmptyValue={curpassclass === 'hidden-field'} message="Current Password is required to change password" min={8} />
                          <AsyncRule
                              message="The current password you entered is not correct."
                              validationCallback={this.asyncValidateCurrentPassword}
                              ignoreEmptyValue={true}
                          />
                          <Label text="Current Password"/>
                        </SimpleItem>
                        <GroupItem cssClass="form-buttons">
                          <ButtonItem buttonOptions={this.updateBtnOptions}/>
                          <ButtonItem buttonOptions={this.cancelBtnOptions}/>
                        </GroupItem>
                      </GroupItem>
                    </Form>
                  </form>
                </div>
              </React.Fragment>
          }
        </React.Fragment>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const {clientacct} = this.state;
    kwapi.updateClientAccount(clientacct, this.accountUpdateSuccess, this.accountUpdateError);
  }

  cancelHandler = (e) => {
    const {clientacct} = this.state;

    const passinput = document.getElementsByName('password')[0];
    const confpassinput = document.getElementsByName('confirm_password')[0];
    const curpassinput = document.getElementsByName('current_password')[0];

    passinput.value = '';
    confpassinput.value = '';
    curpassinput.value = '';

    clientacct.password = '';
    clientacct.confirm_password = '';
    clientacct.current_password = '';

    this.setState({clientacct: clientacct, curpassclass: 'hidden-field'}, () => {
      this.goBack();
    });
  }
  accountUpdateSuccess = () => {
    const {clientacct} = this.state;

    const passinput = document.getElementsByName('password')[0];
    const confpassinput = document.getElementsByName('confirm_password')[0];
    const curpassinput = document.getElementsByName('current_password')[0];

    passinput.value = '';
    confpassinput.value = '';
    curpassinput.value = '';

    clientacct.password = '';
    clientacct.confirm_password = '';
    clientacct.current_password = '';

    this.setState({clientacct: clientacct, curpassclass: 'hidden-field'}, () => {
      notify({
        message: 'Your Account Settings Have Been Updated',
        position: {
          my: 'center top',
          at: 'center top'
        }
      }, 'success', 5000);
    });
  }

  accountUpdateError = () => {
    const {clientacct} = this.state;

    const curpassinput = document.getElementsByName('current_password')[0];
    curpassinput.value = '';

    clientacct.current_password = '';

    this.setState({clientacct: clientacct, curpassclass: 'hidden-field'}, () => {
      notify({
        message: 'A problem occurred updating your account',
        position: {
          my: 'center top',
          at: 'center top'
        }
      }, 'error', 5000);
    });
  }

  passwordComparison() {
    if (typeof this.state.clientacct !== 'undefined') {
      const {password} = this.state.clientacct;
      if (typeof password !== 'undefined' && password.length >= 8) {
        this.setState({curpassclass: 'current-password'});
      } else {
        this.setState({curpassclass: 'hidden-field'});
      }
      return typeof password !== 'undefined' ? password : '';
    }
    return '';
  }

  asyncEmailValidation = (params) => {
    return kwapi.validateClientEmail(params.value);
  }

  asyncValidateCurrentPassword = (params) => {
    const {current_password} = this.state.clientacct;
    if (typeof current_password !== 'undefined' && current_password.length >= 8) {
      return kwapi.validateCurrentPassword(params.value);
    } else {
      return new Promise((resolve, reject) => {
        reject();
      })
    }
  }
}
export default Profile;