import { createStore } from 'redux';

// Centralized application state
// For more information visit http://redux.js.org/
const store = createStore((state = { client_selected: null, client_list: null, disable_aerials: false, geojson: null, geojsondata: null, layers: null, layers_loaded: false, lng: null, lat: null, lnglow: null, lnghigh: null, latlow: null, lathigh: null, currentview: null, selectedview: null, maps: null, map: null, mbmap: null, sublabels: [] , contourlabels: [], markers: null, markers_loaded: false, marker_type: null, custom_markers: null, comment_markers: null, comments_area_tool: false, comments_marker_tool: false, account: null, error: null, layersicon: 'layers', maplink: false, maploaded: false, map_first_render: false, map_center: null, marker_color: null, measurements: [], polygoncolor: '#d20c0c', polygoncoords: null, popovervisible: false, popovertext: null, polygonopacity: 0.1, pullingcomments: null, pullingmarkers: null, pullinglayers: null, pullingmapdata: false ,pullingviews: false, pullingdata: false, pullingclientlist: false, selectedlayers: [], active_thread: null, views_tool: false, comments_tool: false, commentsdata: null, viewsdata: null, showviews: false, showcomments: false, showthreadpopup: false , showviewspopup: false, showthreads: false, showmarkerslist: false, showlinetoolip: false, showpolygontoolip: false, showspotelevtooltip: false, showelevcontrstooltip: false, showprinttooltip: false, showcommentstooltip: false, showviewstooltip: false, showmarkerstooltip: false, showmaptutorial: false, sharepopupvisible: false, sharepopuptext: null, sharepopupurl: null, sidemenuexpand: false, toastconfig: null, toastvisible: false, subscribers: [], subscriberlist: [], scrollview: null, zoom: null}, action) => {
    // TODO: Add action handlers (aka "reducers")
    switch (action.type) {
        case 'SET_ACCOUNT':
            return { ...state, account: action.payload };
        case 'SET_DISABLE_AERIALS':
            return { ...state, disable_aerials: action.payload };
        case 'SET_LAYERS':
            return { ...state, layers: action.payload };
        case 'SET_LAYERS_LOADED':
            return { ...state, layers_loaded: action.payload};
        case 'SET_MAP':
            return { ...state, map: action.payload };
        case 'SET_MAPLINK':
            return { ...state, maplink: action.payload };
        case 'SET_MAPS':
            return { ...state, maps: action.payload };
        case 'SET_MARKERS':
            return { ...state, markers: action.payload };
        case 'SET_MARKER_TYPE':
            return { ...state, marker_type: action.payload }
        case 'SET_MARKER_COLOR':
            return { ...state, marker_color: action.payload }
        case 'SET_MEASUREMENTS':
            return { ...state, measurements: action.payload };
        case 'SET_CUSTOM_MARKERS':
            return { ...state, custom_markers: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        case 'SET_PULLDATA':
            return { ...state, pullingdata: action.payload };
        case 'SET_PULLLAYERS':
            return { ...state, pullinglayers: action.payload };
        case 'SET_PULLMAPDATA':
            return { ...state, pullingmapdata: action.payload }
        case 'SET_PULLMARKERS':
            return { ...state, pullingmakers: action.payload };
        case 'SET_PULLCOMMENTS':
            return { ...state, pullingcomments: action.payload };
        case 'SET_PULLVIEWS':
            return { ...state, pullingviews: action.payload };
        case 'SET_PULL_CLIENTLIST':
            return { ...state, pullingclientlist: action.payload };
        case 'SET_SHOW_LINE_TOOLTIP':
            return { ...state, showlinetoolip: action.payload };
        case 'SET_SHOW_POLYGON_TOOLTIP':
            return { ...state, showpolygontoolip: action.payload };
        case 'SET_SHOW_SPOTELEV_TOOLTIP':
            return { ...state, showspotelevtooltip: action.payload };
        case 'SET_SHOW_ELEVCONTRS_TOOLTIP':
            return { ...state, showelevcontrstooltip: action.payload };
        case 'SET_SHOW_PRINT_TOOLTIP':
            return { ...state, showprinttooltip: action.payload };
        case 'SET_SHOW_COMMENTS_TOOLTIP':
            return { ...state, showcommentstooltip: action.payload };
        case 'SET_SHOW_VIEWS_TOOLTIP':
            return { ...state, showviewstooltip: action.payload };
        case 'SET_MARKERS_TOOLTIP':
            return { ...state, showmarkerstooltip: action.payload };
        case 'SET_LNG':
            return { ...state, lng: action.payload };
        case 'SET_LNGLOW':
            return { ...state, lnglow: action.payload };
        case 'SET_LNGHIGH':
            return { ...state, lnghigh: action.payload };
        case 'SET_LAT':
            return { ...state, lat: action.payload };
        case 'SET_LATLOW':
            return { ...state, latlow: action.payload };
        case 'SET_LATHIGH':
            return { ...state, lathigh: action.payload };
        case 'SET_MBMAP':
            return { ...state, mbmap: action.payload };
        case 'SET_MAP_CENTER':
            return { ...state, map_center: action.payload };
        case 'SET_GEOJSON':
            return { ...state, geojson: action.payload };
        case 'SET_GEOJSON_DATA':
            return { ...state, geojsondata: action.payload };
        case 'SET_SELECTEDLAYERS':
            return { ...state, selectedlayers: action.payload };
        case 'SET_ZOOM':
            return { ...state, zoom: action.payload };
        case 'SET_POPOVER_VISIBLE':
            return { ...state, popovervisible: action.payload };
        case 'SET_POPOVER_TEXT':
            return { ...state, popovertext: action.payload };
        case 'SET_SIDEMENU_EXPAND':
            return { ...state, sidemenuexpand: action.payload };
        case 'SET_LAYERS_ICON':
            return { ...state, layersicon: action.payload };
        case 'SET_SHOW_COMMENTS':
            return { ...state, showcomments: action.payload };
        case 'SET_SHOW_THREADS':
            return { ...state, showthreads: action.payload };
        case 'SET_SHOW_VIEWS':
            return { ...state, showviews: action.payload };
        case 'SET_ACTIVE_THREAD':
            return { ...state, active_thread: action.payload };
        case 'SET_COMMENTS_TOOL':
            return { ...state, comments_tool: action.payload };
        case 'SET_VIEWS_TOOL':
            return { ...state, views_tool: action.payload };
        case 'SET_COMMENT_MARKERS':
            return { ...state, comment_markers: action.payload };
        case 'SET_COMMENTS_DATA':
            return { ...state, commentsdata: action.payload };
        case 'SET_SHOW_THREAD_POPUP':
            return { ...state, showthreadpopup: action.payload };
        case 'SET_COMMENTS_MARKER_TOOL':
            return { ...state, comments_marker_tool: action.payload };
        case 'SET_COMMENTS_AREA_TOOL':
            return { ...state, comments_area_tool: action.payload };
        case 'SET_SUBSCRIBERS':
            return { ...state, subscribers: action.payload };
        case 'SET_SUBSCRIBER_LIST':
            return { ...state, subscriberlist: action.payload };
        case 'SET_POLYGON_COLOR':
            return { ...state, polygoncolor: action.payload };
        case 'SET_POLYGON_COORDINATES':
            return { ...state, polygoncoords: action.payload };
        case 'SET_SHAREPOPUP_TEXT':
            return { ...state, sharepopuptext: action.payload };
        case 'SET_SHAREPOPUP_URL':
            return { ...state, sharepopupurl: action.payload };
        case 'SET_SHAREPOPUP_VISIBLE':
            return { ...state, sharepopupvisible: action.payload };
        case 'SET_CURRENTVIEW':
            return { ...state, currentview: action.payload };
        case 'SET_SELECTEDVIEW':
            return { ...state, selectedview: action.payload };
        case 'SET_VIEWS_DATA':
            return { ...state, viewsdata: action.payload };
        case 'SET_SHOW_VIEWS_POPUP':
            return { ...state, showviewspopup: action.payload };
        case 'SET_SHOW_MAP_TUTORIAL':
            return { ...state, showmaptutorial: action.payload };
        case 'SET_SHOW_MARKERS_LIST':
            return { ...state, showmarkerslist: action.payload };
        case 'SET_POLYGON_OPACITY':
            return { ...state, polygonopacity: action.payload };
        case 'SET_TOAST_CONFIG':
            return { ...state, toastconfig: action.payload };
        case 'SET_TOAST_VISIBLE':
            return { ...state, toastvisible: action.payload };
        case 'SET_CLIENT_SELECTED':
            return { ...state, client_selected: action.payload };
        case 'SET_CLIENT_LIST':
            return { ...state, client_list: action.payload };
        case 'SET_SCROLLVIEW':
            return { ...state, scrollview: action.payload};
        case 'SET_MAPLOADED':
            return { ...state, maploaded: action.payload};
        case "SET_MAP_FIRST_RENDER":
            return { ...state, map_first_render: action.payload};
        case "SET_MARKERS_LOADED":
            return { ...state, markers_loaded: action.payload}
        case "SET_SUBLABELS":
            return { ...state, sublabels: action.payload}
        case "SET_CONTOUR_LABELS":
            return { ...state, contourlabels: action.payload}
        default:
            return state;
    }
});

export default store;