import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step1 = ({step}) => {

    const captions = {
        0: 'If you are familiar with Google maps, navigation and movement on the map are very similar.',
        1: 'You can move the map around by holding down your left mouse button and dragging the map to the desired position',
        2: 'You can zoom in and out by clicking on the zoom buttons in the top right of the map',
        3: 'If you have a mouse wheel, you can also zoom in and out by using your mouse wheel.',
        4: 'The scale at the bottom-center of the map auto-adjusts as you zoom to help you determine distance measurements.'
    };
                       //0,  1 ,  2  , 3   ,  4  ,  5  ,  6  ,  7  ,  8  ,  9  ,  10  ,  11  ,  12  ,  13
    const timeIndexes = [0, 4.0, 10.0, 20.0, 24.0];

    return <MT_Video step={step} basictutorial={true} title="Basic Map navigation and movement" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/715737537/2292252e42"/>;
}
export default MT_Step1;