/* Add action imports in this file to combine into global actions*/
export const setAccount = (payload) => ({
    type: 'SET_ACCOUNT',
    payload
});
export const setMap = (payload) => ({
    type: 'SET_MAP',
    payload
});
export const setMapLink = (payload) => ({
    type: 'SET_MAPLINK',
    payload
});
export const setMaps = (payload) => ({
    type: 'SET_MAPS',
    payload
});
export const setMarkers = (payload) => ({
    type: 'SET_MARKERS',
    payload
});
export const setCustomMarkers = (payload) => ({
    type: 'SET_CUSTOM_MARKERS',
    payload
});
export const setError = (payload) => ({
    type: 'SET_ERROR',
    payload
});
export const setPullData = (payload) => ({
    type: 'SET_PULLDATA',
    payload
});
export const setPullLayers = (payload) => ({
    type: 'SET_PULLLAYERS',
    payload
});
export const setPullMapData = (payload) => ({
    type: 'SET_PULLMAPDATA',
    payload
});
export const setPullMarkers = (payload) => ({
    type: 'SET_PULLMARKERS',
    payload
});
export const setPullComments = (payload) => ({
    type: 'SET_PULLCOMMENTS',
    payload
});
export const setPullViews = (payload) => ({
    type: 'SET_PULLVIEWS',
    payload
});
export const setPullClientList = (payload) => ({
    type: 'SET_PULL_CLIENTLIST',
    payload
});
export const setMBMap = (payload) => ({
    type: 'SET_MBMAP',
    payload
});
export const setGeoJson = (payload) => ({
    type: 'SET_GEOJSON',
    payload
});
export const setGeoJsonData = (payload) => ({
    type: 'SET_GEOJSON_DATA',
    payload
});
export const setLayers = (payload) => ({
    type: 'SET_LAYERS',
    payload
});
export const setLayersLoaded = (payload) => ({
    type: 'SET_LAYERS_LOADED',
    payload
});
export const setDisableAerials = (payload) => ({
    type: 'SET_DISABLE_AERIALS',
    payload
});
export const setLng = (payload) => ({
    type: 'SET_LNG',
    payload
});
export const setLngLow = (payload) => ({
    type: 'SET_LNGLOW',
    payload
});
export const setLngHigh = (payload) => ({
    type: 'SET_LNGHIGH',
    payload
});
export const setLat = (payload) => ({
    type: 'SET_LAT',
    payload
});
export const setLatLow = (payload) => ({
    type: 'SET_LATLOW',
    payload
});
export const setLatHigh = (payload) => ({
    type: 'SET_LATHIGH',
    payload
});
export const setSelectedLayers = (payload) => ({
    type: 'SET_SELECTEDLAYERS',
    payload
});
export const setZoom = (payload) => ({
    type: 'SET_ZOOM',
    payload
});
export const setPopoverVisible = (payload) => ({
    type: 'SET_POPOVER_VISIBLE',
    payload
});
export const setPopoverText = (payload) => ({
    type: 'SET_POPOVER_TEXT',
    payload
});
export const setMeasurements = (payload) => ({
    type: 'SET_MEASUREMENTS',
    payload
});
export const setSideMenuExpand = (payload) => ({
    type: 'SET_SIDEMENU_EXPAND',
    payload
});
export const setLayersIcon = (payload) => ({
    type: 'SET_LAYERS_ICON',
    payload
});
export const setShowComments = (payload) => ({
    type: 'SET_SHOW_COMMENTS',
    payload
});
export const setCommentsTool = (payload) => ({
    type: 'SET_COMMENTS_TOOL',
    payload
});
export const setViewsTool = (payload) => ({
    type: 'SET_VIEWS_TOOL',
    payload
});
export const setShowThreads = (payload) => ({
    type: 'SET_SHOW_THREADS',
    payload
});
export const setCommentMarkers = (payload) => ({
    type: 'SET_COMMENT_MARKERS',
    payload
});
export const setCommentsData = (payload) => ({
    type: 'SET_COMMENTS_DATA',
    payload
})
export const setActiveThread = (payload) => ({
    type: 'SET_ACTIVE_THREAD',
    payload
});
export const setShowThreadPopup = (payload) => ({
    type: 'SET_SHOW_THREAD_POPUP',
    payload
});
export const setMarkerType = (payload) => ({
    type: 'SET_MARKER_TYPE',
    payload
});
export const setMarkerColor = (payload) => ({
    type: 'SET_MARKER_COLOR',
    payload
});
export const setCommentsAreaTool = (payload) => ({
    type: 'SET_COMMENTS_AREA_TOOL',
    payload
});
export const setCommentsMarkerTool = (payload) => ({
    type: 'SET_COMMENTS_MARKER_TOOL',
    payload
});
export const setSubscribers = (payload) => ({
    type: 'SET_SUBSCRIBERS',
    payload
})
export const setSubscriberList = (payload) => ({
    type: 'SET_SUBSCRIBER_LIST',
    payload
});
export const setPolygonColor = (payload) => ({
    type: 'SET_POLYGON_COLOR',
    payload
});
export const setPolygonCoordinates = (payload) => ({
    type: 'SET_POLYGON_COORDINATES',
    payload
});
export const setShowLineTooltip = (payload) => ({
    type: 'SET_SHOW_LINE_TOOLTIP',
    payload
});
export const setShowPolygonTooltip = (payload) => ({
    type: 'SET_SHOW_POLYGON_TOOLTIP',
    payload
});
export const setShowSpotelevTooltip = (payload) => ({
    type: 'SET_SHOW_SPOTELEV_TOOLTIP',
    payload
});
export const setShowElevcontrsTooltip = (payload) => ({
    type: 'SET_SHOW_ELEVCONTRS_TOOLTIP',
    payload
});
export const setShowPrintTooltip = (payload) => ({
    type: 'SET_SHOW_PRINT_TOOLTIP',
    payload
});
export const setShowCommentsTooltip = (payload) => ({
    type: 'SET_SHOW_COMMENTS_TOOLTIP',
    payload
});
export const setSharePopupText = (payload) => ({
    type: 'SET_SHAREPOPUP_TEXT',
    payload
});
export const setSharePopupURL = (payload) => ({
    type: 'SET_SHAREPOPUP_URL',
    payload
})
export const setSharePopupVisible = (payload) => ({
    type: 'SET_SHAREPOPUP_VISIBLE',
    payload
});
export const setCurrentView = (payload) => ({
    type: 'SET_CURRENTVIEW',
    payload
});
export const setSelectedView = (payload) => ({
    type: 'SET_SELECTEDVIEW',
    payload
});
export const setViewsData = (payload) => ({
    type: 'SET_VIEWS_DATA',
    payload
});
export const setShowViewsPopup = (payload) => ({
    type: 'SET_SHOW_VIEWS_POPUP',
    payload
});
export const setShowViews = (payload) => ({
    type: 'SET_SHOW_VIEWS',
    payload
});
export const setShowViewsTooltip = (payload) => ({
    type: 'SET_SHOW_VIEWS_TOOLTIP',
    payload
});
export const setShowMarkersTooltip = (payload) => ({
    type: 'SET_MARKERS_TOOLTIP',
    payload
});
export const setShowMapTutorial = (payload) => ({
    type: 'SET_SHOW_MAP_TUTORIAL',
    payload
});
export const setToastConfig = (payload) => ({
    type: 'SET_TOAST_CONFIG',
    payload
});
export const setToastVisible = (payload) => ({
    type: 'SET_TOAST_VISIBLE',
    payload
});
export const setShowMarkersList = (payload) => ({
    type: 'SET_SHOW_MARKERS_LIST',
    payload
});
export const setPolygonOpacity = (payload) => ({
    type: 'SET_POLYGON_OPACITY',
    payload
});
export const setClientSelected = (payload) => ({
    type: 'SET_CLIENT_SELECTED',
    payload
});
export const setClientList = (payload) => ({
    type: 'SET_CLIENT_LIST',
    payload
});
export const setScrollView = (payload) => ({
    type: 'SET_SCROLLVIEW',
    payload
});
export const setMapLoaded = (payload) => ({
    type: 'SET_MAPLOADED',
    payload
})
export const setMapFirstRender = (payload) => ({
    type: 'SET_MAP_FIRST_RENDER',
    payload
});
export const setMarkersLoaded = (payload) => ({
    type: 'SET_MARKERS_LOADED',
    payload
});
export const setSubLabels = (payload) => ({
    type: 'SET_SUBLABELS',
    payload
});
export const setContourLabels = (payload) => ({
    type: 'SET_CONTOUR_LABELS',
    payload
});
export const setMapCenter = (payload) => ({
    type: 'SET_MAP_CENTER',
    payload
})