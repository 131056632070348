import store from "../../api/store/store";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import turfarea from "@turf/area";
import {setPopoverVisible, setPopoverText, setShowPolygonTooltip} from "../../api/actions/actions";

export const PolygonAreaTool = (() => {
    let instance;

    function createInstance() {
        const map = store.getState().mbmap;
        let draw;

        return {
           create: () => {
               if (map !== null) {
                   /*const resultdiv = document.querySelector('.calculation-box');*/

                   draw = new MapboxDraw({
                       displayControlsDefault: false,
                       controls: {
                           /*polygon: true,*/
                           trash: true
                       },
                       styles: [
                           // ACTIVE (being drawn)
                           // line stroke
                           {
                               "id": "gl-draw-line",
                               "type": "line",
                               "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                               "layout": {
                                   "line-cap": "round",
                                   "line-join": "round"
                               },
                               "paint": {
                                   "line-color": "#D20C0C",
                                   "line-dasharray": [0.2, 2],
                                   "line-width": 2
                               }
                           },
                           // polygon fill
                           {
                               "id": "gl-draw-polygon-fill",
                               "type": "fill",
                               "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                               "paint": {
                                   "fill-color": "#D20C0C",
                                   "fill-outline-color": "#D20C0C",
                                   "fill-opacity": 0.1
                               }
                           },
                           // polygon mid points
                           {
                               'id': 'gl-draw-polygon-midpoint',
                               'type': 'circle',
                               'filter': ['all',
                                   ['==', '$type', 'Point'],
                                   ['==', 'meta', 'midpoint']],
                               'paint': {
                                   'circle-radius': 3,
                                   'circle-color': '#fbb03b'
                               },
                           },
                           // polygon outline stroke
                           // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                           {
                               "id": "gl-draw-polygon-stroke-active",
                               "type": "line",
                               "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                               "layout": {
                                   "line-cap": "round",
                                   "line-join": "round"
                               },
                               "paint": {
                                   "line-color": "#D20C0C",
                                   "line-dasharray": [0.2, 2],
                                   "line-width": 2
                               }
                           },
                           // vertex point halos
                           {
                               "id": "gl-draw-polygon-and-line-vertex-halo-active",
                               "type": "circle",
                               "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                               "paint": {
                                   "circle-radius": 5,
                                   "circle-color": "#FFF"
                               }
                           },
                           // vertex points
                           {
                               "id": "gl-draw-polygon-and-line-vertex-active",
                               "type": "circle",
                               "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                               "paint": {
                                   "circle-radius": 3,
                                   "circle-color": "#D20C0C",
                               }
                           },

                           // INACTIVE (static, already drawn)
                           // line stroke
                           {
                               "id": "gl-draw-line-static",
                               "type": "line",
                               "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                               "layout": {
                                   "line-cap": "round",
                                   "line-join": "round"
                               },
                               "paint": {
                                   "line-color": "#000",
                                   "line-width": 3
                               }
                           },
                           // polygon fill
                           {
                               "id": "gl-draw-polygon-fill-static",
                               "type": "fill",
                               "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                               "paint": {
                                   "fill-color": "#000",
                                   "fill-outline-color": "#000",
                                   "fill-opacity": 0.1
                               }
                           },
                           // polygon outline
                           {
                               "id": "gl-draw-polygon-stroke-static",
                               "type": "line",
                               "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                               "layout": {
                                   "line-cap": "round",
                                   "line-join": "round"
                               },
                               "paint": {
                                   "line-color": "#000",
                                   "line-width": 3
                               }
                           }
                       ]
                   });
                   map.addControl(draw);
                   draw.changeMode('draw_polygon');


                   map.on('draw.create', PolygonAreaTool.getInstance().updateArea);
                   map.on('draw.delete', PolygonAreaTool.getInstance().updateArea);
                   map.on('draw.update', PolygonAreaTool.getInstance().updateArea);

                   /*resultdiv.style.display = 'table';

                   document.querySelector('.mapbox-gl-draw_polygon, .mapbox-gl-draw_trash').addEventListener("click", (e) => {
                       resultdiv.style.display = 'none';
                   });*/
                   store.dispatch(setShowPolygonTooltip(false));
               }
           },
           destroy: () => {
               if (map !== null) {
                   const resultdiv = document.querySelector('.calculation-box');
                   const distanceContainer = document.getElementById('distance');

                   map.removeControl(draw);
                   map.off('draw.create', PolygonAreaTool.getInstance().updateArea);
                   map.off('draw.delete', PolygonAreaTool.getInstance().updateArea);
                   map.off('draw.update', PolygonAreaTool.getInstance().updateArea);

                   var answer = document.getElementById('calculated-area');
                   var infoarrow = document.getElementById('polytools-icon');
                   var infotext = document.getElementById('polytools-info');
                   infoarrow.style.display = 'inline';
                   infotext.style.display = 'inline';
                   answer.innerHTML = '';
                   distanceContainer.innerHTML = '';
                   resultdiv.style.display = 'none';

                   store.dispatch(setPopoverVisible(false));
                   store.dispatch(setPopoverText(''));
                   store.dispatch(setShowPolygonTooltip(false));
               }
           },
           updateArea: (e) => {
                /*const trashbtnctrl = document.getElementById('mapbox-gl-distance-trash');
                if ( trashbtnctrl !== null ) { trashbtnctrl.style.display = 'none'; }*/
                const distanceContainer = document.getElementById('distance');
                var data = draw.getAll();
                var infoarrow = document.getElementById('polytools-icon');
                var infotext = document.getElementById('polytools-info');
                if (data.features.length > 0) {
                    var area = turfarea(data);
                    // restrict to area to 2 decimal points
                    var rounded_area = Math.round(area * 100) / 100;
                    var sqmi_area = rounded_area / 2589988.110336;
                    var acres_area = sqmi_area * 640;
                    var sqft_area = acres_area * 43560;
                    infoarrow.style.display = 'none';
                    infotext.style.display = 'none';

                    // Clear the Distance container to populate it with a new value
                    distanceContainer.innerHTML = '';

                    var value = document.createElement('pre');
                    value.textContent =
                        'Total Area: ' +
                        acres_area.toFixed(2) +
                        ' acres' +
                        ' | ' +
                        sqft_area.toFixed(2) +
                        ' sqft';

                    // Populate popover
                    /*store.dispatch(setPopoverText( textContent));
                    store.dispatch(setPopoverVisible(true));*/
                    distanceContainer.appendChild(value);
                    const resultdiv = document.querySelector('.calculation-box');
                    resultdiv.style.display = 'none';
                } else {
                    infoarrow.style.display = 'inline';
                    infotext.style.display = 'inline';
                    store.dispatch(setPopoverVisible(false));
                    store.dispatch(setPopoverText(''));
                    distanceContainer.innerHTML = '';
                    map.removeControl(draw);
                    map.addControl(draw);
                    draw.changeMode('draw_polygon');
                    if (e.type !== 'draw.delete')
                        alert('Use the draw tools to draw a polygon!');
                }
           },
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        hasInstance: function () {
            if (!instance) {
                return false;
            } else {
                return true;
            }
        }
    };
})();