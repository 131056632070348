import React, {Fragment} from 'react';
import TreeView from 'devextreme-react/tree-view';
import LoadPanel from 'devextreme-react/load-panel'
import ClientSelectPopup from "../../components/client-select-popup/ClientSelectPopup";
/*import TabPanel from 'devextreme-react/tab-panel';*/
/*import { continents } from './data.js';*/
import {kwapi, user} from "../../api/kwapi";
import store from "../../api/store/store";
import {
    setMap,
    setMapLink,
    setPullComments,
    setPullLayers,
    setPullMarkers,
    setPullClientList,
    setClientSelected,
    setClientList,
    setError, setPullData,
} from "../../api/actions/actions";
import mbxClient from '@mapbox/mapbox-sdk';
import mbxStatic from '@mapbox/mapbox-sdk/services/static';
import './dashboard.scss';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        //kwapi.getClientData();
        this.state = {
            firstLoad: true,
            tabPanelIndex: 0,
            /*countryData: continents[0].items[0],
            citiesData: continents[0].items[0].cities,*/
            clientData: null,
            clientSelection: false,
            mapData: null,
            markers: null,
            layers: null,
            comments: null,
            markerscnt: 0,
            layerscnt: 0,
            commentscnt: 0,
            mapPreview: '',
            loading: false
        };

        this.handleTreeViewSelectionChange = this.handleTreeViewSelectionChange.bind(this);
        this.handleTabPanelSelectionChange = this.handleTabPanelSelectionChange.bind(this);
        //console.log(clientData);
    }

    componentDidMount() {
        this.checkUserAccount();
    }

    checkUserAccount = () => {
        if (store.getState().account !== null) {
            const selectedClient = user.getSelectedClient();
            if (user.isKWAccount() && store.getState().maps === null && selectedClient === null) {
                kwapi.getClientList(this.clientListSuccess, this.clientListError);
                this.setState({loading: true});
            } else if (user.getSelectedClient() !== null) {
                this.setSelectedClient(selectedClient);
            } else {
                this.initDashboard();
            }
        } else {
            setTimeout(() => {
                this.checkUserAccount();
            }, 300);
        }
    }

    initDashboard = () => {
        const dashboardContainer = document.querySelector('.dashboard-container');
        if (dashboardContainer) {
            setTimeout(() => {
                const layoutBody = document.querySelector('.layout-body');
                const footer = document.querySelector('.footer');
                layoutBody.style.backgroundColor = '#fff';
                footer.style.textAlign = 'right';
                this.setInitialData();
            }, 10);
        }
    }

    clientListSuccess = (clients) => {
        store.dispatch(setPullClientList(false));
        store.dispatch(setClientList(clients));
        this.setState({loading: false, clientSelection: true});
    }

    clientListError = () => {
        store.dispatch(setPullClientList(false));
        store.dispatch(setError('Unable to pull client list from Kier & Wright API server'));
    }

    setSelectedClient = (client_id) => {
        store.dispatch(setClientSelected(client_id));
        user.setSelectedClient(client_id);
        this.setState({clientSelection: false, loading: true},() => {
            kwapi.pullClientData(this.setClientData, kwapi.setClientDataError);
        });
    }
    setClientData = (clientdata) => {
        store.dispatch(setPullData(false));
        kwapi.setClientData(clientdata);
        this.setState({loading: false});
        this.initDashboard();
    }

    setInitialData = () => {
        const clientData = kwapi.getClientData();
        if (typeof clientData !== 'undefined' && clientData !== null ) {
            for (let p=0;p<clientData.length;p++) {
                clientData[p].text = clientData[p].name;
                for (let i = 0; i < clientData[p].maps.length; i++) {
                    clientData[p].maps[i].text = clientData[p].maps[i].name;
                }
                clientData[p].items = clientData[p].maps;
                for (let m=0;m < clientData[p].items.length; m++) {
                    clientData[p].items[m].did = p + '_' + (m + 1);
                }
            }

            const mapData = typeof clientData[0] !== 'undefined' ? clientData[0] : null;
            this.setState({mapData: mapData, clientData: clientData});
        } else {
            setTimeout(this.setInitialData, 300);
        }
    }

    mapLinkHandler = () => {
        const { mapData } = this.state;
        const map_data = kwapi.getMapData(mapData);

        if ((kwapi.environment() === 'development' || (store.getState().map === null || store.getState().map.ID !== map_data.ID))) {
            store.dispatch(setMap(map_data));
            store.dispatch(setMapLink(true));
            kwapi.getMapMarkers(this.setMarkers);
            kwapi.getMapLayers(this.setLayers);
            kwapi.getMapComments(this.setComments);

            const stylesarr = mapData.style_url.split('/');
            const mbuser = stylesarr[3];
            const mbstyle = stylesarr[4];

            const baseClient = mbxClient({ accessToken: mapData.access_token });
            const staticService = mbxStatic(baseClient);
            const request = staticService.getStaticImage({
                ownerId: mbuser,
                styleId: mbstyle,
                width: 500,
                height: 350,
                position: {
                    coordinates: [parseFloat(mapData.lng), parseFloat(mapData.lat)],
                    zoom: 12
                },
            });
            const staticImageUrl = request.url();
            this.setState({mapPreview: staticImageUrl}, () => {
                this.initMapLinkClick(mapData.id);
            });
        }
    }

    initMapLinkClick = (lindex) => {
        const maplink = document.getElementById('map-link-' + lindex);
        setTimeout(this.initCounters, 500);
        if (maplink === null) {
            setTimeout(() => {
                this.initMapLinkClick(lindex);
            }, 300);
        } else {
            maplink.addEventListener('click', (e) => {
                const { history } = this.props;
                store.dispatch(setMapLink(false));
                if (kwapi.environment() === 'development') {
                    history.push('/surveys/viewmap');
                } else if (store.getState().map !== null) {
                    history.push('/surveys/map/' + store.getState().map.URLToken);
                }
            });
        }
    }

    initCounters = () => {
        const counters = document.querySelectorAll('.count');
        const speed = 100;

        this.setState({layerscnt: 0, markerscnt: 0, commentscnt: 0}, () => {
            counters.forEach((counter) => {
                const updateCount = () => {
                    const {markerscnt, layerscnt, commentscnt} = this.state;
                    const target = parseInt(counter.getAttribute('data-target'));
                    let counterName = counter.getAttribute('data-counter');
                    let count = {};
                    switch (counterName) {
                        case 'markers':
                            count = {name: 'markerscnt', cnt: markerscnt};
                            break;
                        case 'layers':
                            count = {name: 'layerscnt', cnt: layerscnt};
                            break;
                        case 'comments':
                            count = {name: 'commentscnt', cnt: commentscnt};
                            break;
                        default:
                            count = null;
                    }
                    const increment = 1;
                    const stateobj = this.state;
                    if (count !== null && count.cnt < target) {
                        stateobj[count.name] = count.cnt + increment;
                        this.setState(stateobj);
                        setTimeout(updateCount, speed);
                    } else if (count !== null) {
                        stateobj[count.name] = target;
                        this.setState(stateobj);
                    }
                };
                setTimeout(updateCount, 500);
            });
        });
    }

    setMarkers = (markersData) => {
        store.dispatch(setPullMarkers(false));
        this.setState({markers: markersData});
    }

    setLayers = (layersData) => {
        store.dispatch(setPullLayers(false));
        this.setState({layers: layersData});
    }

    setComments = (commentsData) => {
        store.dispatch(setPullComments(false));
        this.setState({comments: commentsData});
    }

    render() {
        const { clientData, clientSelection, loading, mapData, comments, layers, markers, commentscnt, layerscnt, markerscnt, firstLoad, mapPreview } = this.state;
        if (loading) {
            return <LoadPanel visible={true} />;
        } else if (clientSelection) {
            return <ClientSelectPopup popupvisible={clientSelection} selectClient={this.setSelectedClient}/>;
        } else {
            return (
                <div className="container dashboard-container">
                    <div className="left-content">
                        <TreeView
                            dataSource={clientData}
                            keyExpr="did"
                            selectionMode="single"
                            selectByClick={true}
                            onItemSelectionChanged={this.handleTreeViewSelectionChange}
                        />
                    </div>
                    <div className="right-content">
                        <div className="title-container">
                            {!firstLoad ?
                                <div>
                                    <div className="map-name">{mapData.text}</div>
                                    <div>{mapData.description}</div>
                                    <a id={'map-link-' + mapData.id} className="dashboard-map-link">
                                        <img src={mapPreview} alt="Unable to load map preview" className="map-preview"/>
                                    </a>
                                    <div className="map-overlay"><span className="dashboard-symbol">↑</span> Click the
                                        Map preview image to view this map <span className="dashboard-symbol">↑</span>
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <h3>Welcome to your dashboard</h3>
                                    <div className="dashboard-intro"><span className="dashboard-symbol">←</span> Expand
                                        a project in the left pane, then click on a map name. This will allow you to
                                        browse or go to your maps.
                                    </div>
                                </Fragment>
                            }
                        </div>
                        {!firstLoad &&
                            <Fragment>
                                <div className="stats">
                                    <div>
                                        <div className="sub-title">Layers</div>
                                        <div className="stat-value count"
                                             data-target={layers !== null ? layers.layers.length : 0}
                                             data-counter="layers">{layerscnt}</div>
                                    </div>
                                    <div>
                                        <div className="sub-title">Markers</div>
                                        <div className="stat-value count"
                                             data-target={markers !== null ? markers.length : 0}
                                             data-counter="markers">{markerscnt}</div>
                                    </div>
                                    <div>
                                        <div className="sub-title">Comment Threads</div>
                                        <div className="stat-value count"
                                             data-target={comments !== null ? comments.length : 0}
                                             data-counter="comments">{commentscnt}</div>
                                    </div>
                                </div>

                                {/*<div className="sub-title">Largest cities</div>

                            <TabPanel
                                itemTitleRender={renderPanelItemTitle}
                                itemRender={renderPanelItem}
                                selectedIndex={this.state.tabPanelIndex}
                                onSelectionChanged={this.handleTabPanelSelectionChange}
                                dataSource={this.state.citiesData}
                                animationEnabled={true}
                                id="tabpanel"
                            />*/}
                            </Fragment>
                        }
                    </div>
                </div>
            );
        }
    }

    handleTreeViewSelectionChange(e) {
        const mapData = e.itemData
        if (typeof mapData.type !== 'undefined' && mapData.type === 'mapbox' && mapData.selected) {
            this.setState({
                firstLoad: false,
                tabPanelIndex: 0,
                mapData: mapData,
            }, () => {
                this.mapLinkHandler();
            });
        } else if (typeof mapData.items !== 'undefined' && mapData.selected) {
            const sElement = e.itemElement;
            const parentElement = sElement.parentElement;
            const expandElement = document.querySelector('#'+parentElement.id+' .dx-treeview-toggle-item-visibility');
            expandElement.click();
        }
        e.itemElement.click();
    }

    handleTabPanelSelectionChange(e) {
        this.setState({
            tabPanelIndex: e.value,
        });
    }
}

/*function renderPanelItemTitle(item) {
    return <span className="tab-panel-title">{item.text}</span>;
}

function renderPanelItem(city) {
    return (
        <React.Fragment>
            <img className="flag" src={city.flag} />
            <div className="right-content">
                <div>
                    <b>{(city.capital) ? 'Capital. ' : ''}</b>{city.description}
                </div>
                <div className="stats">
                    <div>
                        <div>Population</div>
                        <div><b>{city.population} people</b></div>
                    </div>
                    <div>
                        <div>Area</div>
                        <div><b>{city.area} km<sup>2</sup></b></div>
                    </div>
                    <div>
                        <div>Density</div>
                        <div><b>{city.density}/km<sup>2</sup></b></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}*/

export default Dashboard;
