import React, {Fragment} from 'react';

const MT_Done = () => {
    return (
        <Fragment>
            <h1>Congratulations!!!</h1>
            <div style={{fontSize: '16px'}}>
                <p>You have completed the tutorial of the Kier & Wright Virtual Tours App.</p>
                <p>Click Done at the bottom or close this window to begin using this map.</p>
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </Fragment>
    )
}
export default MT_Done;