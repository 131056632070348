import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step9 = ({step}) => {

    const captions = {
        0: 'The Print to PDF tool can be found in the top toolbar.',
        1: 'Once activated, you can move the print window and adjust the map to match the view that you want to print.',
        2: 'Once you are you have adjusted to the map view (zoom, pitch, bearing, etc) to what you wanted printed, select the print options you want.',
        3: 'The paper size determines how big the printed map view will be in the PDF file.',
        4: 'When you finally click Print, a dialog will come up to save the PDF, which can be opened upon completion of download.',
    }

    const timeIndexes = [0, 7.0, 31.2, 33.0, 54.0]

    return <MT_Video step={step} basictutorial={true} title="Using the Print to PDF tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/723259907/871e1094cc"/>;
}
export default MT_Step9;