import React, {Fragment} from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import store from '../../api/store/store';
import {setMap, setMapLink, setPullMapData} from '../../api/actions/actions'
import {kwapi} from "../../api/kwapi";
import { LoadPanel } from 'devextreme-react/load-panel';
import Map from './map';
import ErrorPopup from "../../components/error/ErrorPopup";

class MapLink extends React.Component
{
    constructor() {
        super();
        this.state = {
            apiresp: false,
            apierror: false
        }
        kwapi.pullMapData(this.mapDataApiResp, this.mapDataApiError);
    }

    mapDataApiResp = (mapdata) => {
        const mapobj = kwapi.getMapData(mapdata);
        store.dispatch(setMap(mapobj));
        store.dispatch(setMapLink(true));
        this.setState({apiresp: true}, () => store.dispatch(setPullMapData(false)));
    }

    mapDataApiError = () => {
        this.setState({apierror: true}, () => store.dispatch(setPullMapData(false)));
    }

    render() {
        const {apiresp, apierror} = this.state;
        return (
            <Fragment>
                { apiresp ?
                  <Map/>
                        :
                        ''
                }
                {apierror &&
                    <ErrorPopup message="There was a problem contacting the API server. Please try again later." visible={true}/>
                }
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={apiresp && !apierror && this.props.appState.pullingmapdata}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    closeOnOutsideClick={false}
                />
            </Fragment>
        );
    }

}
function mapStateToProps(state) {
    return {
        appState: state
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setPullMapData, setMap, setMapLink
        }, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapLink);