import React from 'react';
import DataGrid, {Column, Grouping, GroupPanel, Paging, SearchPanel} from 'devextreme-react/data-grid';
import CheckBox from 'devextreme-react/check-box';
import DataRow from './DataRow.js';
import {kwapi, user} from '../../api/kwapi';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import store from '../../api/store/store';
import {setMBMap, setGeoJson} from '../../api/actions/actions'
import './maps.scss';

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.projects = kwapi.getClientData();
        this.maps = [];
        this.projects.map((project) => { //eslint-disable-line array-callback-return
            project.maps.map((maprec) => { //eslint-disable-line array-callback-return
                this.maps.push(kwapi.getMapData(maprec, project));
            });
        });
        this.state = {
            autoExpandAll: true
        };
        this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);
        //this.maps = service.getMaps();
        store.dispatch(setMBMap(null));
        store.dispatch(setGeoJson(null));
    }
    componentDidMount() {
        user.setJustSignedIn(false);
    }

    goBack(){
        this.props.history.goBack();
    }

    onAutoExpandAllChanged() {
        this.setState({
            autoExpandAll: !this.state.autoExpandAll
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="back-arrow" title="Back to previous screen">
                    <img src={kwapi.getCDNURL()+'assets/images/back-circle.png'} alt="< Back" onClick={this.goBack.bind(this)}/>
                </div>
                <h2 className={'content-block'}>Projects & Maps</h2>
                <div>
                    <DataGrid
                        dataSource={this.maps}
                        allowColumnReordering={true}
                        showBorders={true}
                        rowRender={DataRow}>
                    >
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Grouping autoExpandAll={this.state.autoExpandAll} />
                        <Paging defaultPageSize={10} />

                        <Column dataField="MapName" caption="Map Name" dataType="string"/>
                        <Column dataField="MapType" dataType="string"/>
                        <Column dataField="ProjectName" groupIndex={0}/>
                        <Column dataField="MapDescription" dataType="string"/>
                        <Column dataField="Created" dataType="date" />
                        <Column dataField="Updated" dataType="date" />
                        <Column dataField="ViewURL"/>
                    </DataGrid>

                    <div className="options">
                        <div className="caption">Options</div>
                        <div className="option">
                            <CheckBox text="Expand All Projects"
                                      value={this.state.autoExpandAll}
                                      onValueChanged={this.onAutoExpandAllChanged} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        appState: state
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setMBMap, setGeoJson
        }, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Maps);