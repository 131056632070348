import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step2 = ({step}) => {

    const captions = {
        0: 'To rotate the map, position your mouse near the top or bottom of the map, then hold down the right mouse button (Ctrl + Click on a Mac), then move the mouse in a circular motion.',
        1: 'You can click the compass button in the top right of the map to re-orient the map to North.'
    }
                       //0,  1 ,  2  , 3   ,  4  ,  5  ,  6  ,  7  ,  8  ,  9  ,  10  ,  11  ,  12  ,  13
    const timeIndexes = [0, 11.0]

    return <MT_Video step={step} basictutorial={true} title="Rotating the Map" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/715737591/404830ba77"/>;
}
export default MT_Step2;