import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {setSideMenuExpand} from "../../api/actions/actions";
import './sidemenu.scss';
import LayersMenu from "./layersmenu";

const SideMenu = () => {
    const [menuWidth, setMenuWidth] = useState('0px');
    const menuExpand = useSelector((state) => state.sidemenuexpand);
    const dispatch = useDispatch();

    useEffect(() => {
        setMenuWidth(menuExpand ? '337px' : '0px');
    }, [menuExpand])

    const toggleNav = () => {
       dispatch(setSideMenuExpand(!menuExpand));
    };

    return (
        <div id="map-sidenav" className="sidenav" style={{ width: menuWidth }}>
            {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
            }<a className="closebtn" onClick={toggleNav} title="Close Layers Menu"><i className="dx-icon dx-icon-back"/></a>
            <LayersMenu/>
        </div>
    );
};
export default SideMenu;