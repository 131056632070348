import React, {useEffect, useRef, useState, Fragment} from 'react';
import { useHistory } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import ErrorPopup from "../../components/error/ErrorPopup";
import './home.scss';
import mapboxgl from "mapbox-gl";
import {kwapi, user} from "../../api/kwapi";
import {mbapi} from "../../api/mbapi";
import { useDispatch, useSelector } from 'react-redux'
import store from '../../api/store/store';
import {
    setMap,
    setMarkers,
    setMBMap,
    setGeoJson,
    setLatHigh,
    setLatLow,
    setLngHigh,
    setLngLow
} from "../../api/actions/actions";
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

export default () => {
    const dispatch = useDispatch();
    const mapContainerRef = useRef(null);
    const clientdata = useSelector((state) => state.maps);
    const [lng, setLng] = useState(-107.2527);
    const [lat, setLat] = useState(48.3856);
    const [zoom, setZoom] = useState(2.90);
    const [apipulled, setAPIPulled] = useState(false);
    const [apierror, setAPIError] = useState(false);
    const [apimap, setAPIMap] = useState(null);
    const [mapsnum, setMapsNum] = useState(0);
    const [mapboxmap, setMapboxMap] = useState(null);
    const [popupvisible, setPopupVisible] = useState(false);
    const [clientdatapulls, setClientDataPulls] = useState(0);
    let [popupdismiss, setPopupDismiss] = useState(localStorage.getItem('map-welcome-dismiss') === '1');

    const history = useHistory();

    dispatch(setMBMap(null));
    dispatch(setGeoJson(null));
    // Initialize map when component mounts
    useEffect(() => {
        onComponentMount();
        // Clean up on unmount
        return unloadMap();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        const markers = store.getState().markers;
        if (mapsnum > 0 && markers === null && apimap !== null) {
            apiPull(apimap);
        } else if (mapsnum > 0 && markers !== null && markers.length >= mapsnum) {
            setAPIMap(null);
            setAPIPulled(true);
            addMarkers();
        }
    }, [apimap, mapsnum]); //eslint-disable-line react-hooks/exhaustive-deps

    const onComponentMount = () => {
        const selectedClient = user.getSelectedClient();
        if (user.isKWAccount() && selectedClient === null) {
            window.location.href ='/surveys/dashboard';
        } else {
            loadMap();
            populateMarkers();
            kwapi.unsetThreadRoute();
        }
    };

    const unloadMap = () => {
        // Clean up on unmount
        if (mapboxmap !== null) { return () => mapboxmap.remove(); }
        else { return () => {}}
    };

    const loadMap = () => {
        mapboxgl.accessToken = 'pk.eyJ1IjoibXJnc3dpZnQiLCJhIjoiY2tpNDJ5cXowMXVpMDJycGRrZzJsaTJlYSJ9.IF-IOVlEu5gf7A2F75yoGA';

        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/satellite-streets-v11',
          center: [lng, lat],
          zoom: zoom,
          attributionControl: false  //mapboxgl-ctrl-bottom-right
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        map.on('move', () => {
          setLng(map.getCenter().lng.toFixed(4));
          setLat(map.getCenter().lat.toFixed(4));
          setZoom(map.getZoom().toFixed(2));
        });

        map.on('load', () => {
         //Do stuff on load
        });
        setMapboxMap(map);
        const mblogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
        const mbattrib = document.getElementsByClassName('mapboxgl-ctrl-bottom-right')[0];
        mblogo.remove();
        mbattrib.innerHTML = '<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib"><div class="mapboxgl-ctrl-attrib-inner">Copyright © ' + new Date().getFullYear() + ' <a href="https://kierwright.com" target="_blank">Kier & Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';

        //If popup hasn't been dismissed, show on load
        if (user.justSignedIn() && !popupdismiss) {
          togglePopup();
        }
        user.setJustSignedIn(false);
    };


    const apiPull = (mapObj) => {
        let style_url = mapObj.style_url;
        style_url = style_url.replace('mapbox://', '');
        const stylesarr = style_url.split('/');
        const mbuser = stylesarr[1];
        const mapid = stylesarr[2];
        const apiuri = mbuser + '/' + mapid + '?access_token=' + mapObj.access_token;
        mbapi.doAPICall(apiuri, 'get', undefined, apiResp, apiError, '*');
    };

    const apiResp = (resp) => {
        const markers = store.getState().markers;
        const markersarr = markers !== null ? markers : [];
        const midx = markersarr.length + 1;
        const mapobj = {
            index: midx,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [resp.center[0], resp.center[1]]
            },
            map: apimap,
            project: apimap.project,
            properties: {
              title: /*apimap.project.name +  ' - ' + */resp.name,
              description: apimap.description
            }
        };
        markersarr.push(mapobj);
        store.dispatch(setMarkers(markersarr));
        if (mapsnum > 0 && markersarr.length >= mapsnum) {
            setAPIMap(null);
            setAPIPulled(true);
            addMarkers();
            setTimeout(mapBounds, 3000);
        }
    };

    const apiError = (error) => {
        setAPIMap(null);
        setAPIError(error);
    };

    const populateMarkers = () => {
        const selectedClient = user.getSelectedClient();
        if (user.isKWAccount() && selectedClient === null) {
            window.location.href = '/surveys/dashboard';
        } else {
            const projects = kwapi.getClientData();
            setClientDataPulls(clientdatapulls + 1);
            if (typeof projects !== 'undefined' && projects !== null && projects.length) {
                projects.map((project) => { //eslint-disable-line array-callback-return
                    setMapsNum(mapsnum + project.maps.length);
                    project.maps.map((maprec, i) => { //eslint-disable-line array-callback-return
                        maprec.project = project;
                        setAPIMap(maprec);
                    });
                });
            } else {
                if (!apierror && store.getState().maps === null && clientdatapulls <= 68) {
                    if (Array.isArray(projects) && !projects.length) {
                        apiError('This account has no published projects or maps!');
                    } else {
                        setTimeout(() => {
                            populateMarkers();
                        }, 300);
                    }
                } else if (clientdatapulls > 68) {
                    apiError('Unable to pull account data from Kier & Wright API!');
                }
            }
        }
    };

    const addMarkers = () => {
        const geoJSONobj = {};
        const map = mapboxmap;
        const markers = store.getState().markers;
        geoJSONobj.type = 'FeatureCollection';
        geoJSONobj.features = markers;
        geoJSONobj.features.forEach(function(marker) {
            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';
            if (store.getState().maps !== null) {
                const clientdata = kwapi.getClientData()[0];
                if (typeof clientdata.logo_url !== 'undefined' && clientdata.logo_url !== null && clientdata.logo_url.length) {
                    el.style.backgroundImage = "url('"+kwapi.getMediaURL() + clientdata.logo_url+"')";
                } else {
                    el.style.backgroundImage = "url('"+kwapi.getCDNURL() + "assets/images/k+w_single_logo.png')";
                }
            } else {
                el.style.backgroundImage = "url('"+kwapi.getCDNURL() + "assets/images/k+w_single_logo.png')";
            }

            //Find Marker limits to find bounds box later
            parseLngLimits(marker.geometry.coordinates[0]);
            parseLatLimits(marker.geometry.coordinates[1]);

            // make a marker for each feature and add to the map
            const popup = new mapboxgl.Popup({ offset: 25 });
            const mbmarker = new mapboxgl.Marker(el)
                .setLngLat(marker.geometry.coordinates)
                .setPopup(popup // add popups
                    .setHTML('<a id="map-link-' + marker.index + '" class="view-map">' + marker.properties.title + '</a><p class="map-description">' + marker.properties.description + '</p>'))
                .addTo(map);
            const maprec = { index: marker.index, map: marker.map, project: marker.project, popup: popup};
            mbmarker.getElement().addEventListener('click', mapLinkHandler.bind(null, maprec));
        });
    };

    const parseLatLimits = (lat) => {
        const latlow = store.getState().latlow;
        const lathigh = store.getState().lathigh;
        if (latlow === null || parseFloat(lat) < latlow) {
            store.dispatch(setLatLow(parseFloat(lat)));
        }
        if (lathigh === null || parseFloat(lat) > lathigh) {
            store.dispatch(setLatHigh(parseFloat(lat)));
        }
    }

    const parseLngLimits = (lng) => {
        const lnglow = store.getState().lnglow;
        const lnghigh = store.getState().lnghigh;
        if (lnglow === null || parseFloat(lng) < lnglow) {
            store.dispatch(setLngLow(parseFloat(lng)));
        }
        if (lnghigh === null || parseFloat(lng) > lnghigh) {
            store.dispatch(setLngHigh(parseFloat(lng)));
        }
    }

    const mapBounds = () => {
        mapboxmap.fitBounds([
            [store.getState().lnglow + 1.0, store.getState().lathigh + 1.0], // southwestern corner of the bounds
            [store.getState().lnghigh - 1.0, store.getState().latlow - 1.0] // northeastern corner of the bounds
        ]);
        dispatch(setLatLow(null));
        dispatch(setLatHigh(null));
        dispatch(setLngLow(null));
        dispatch(setLngHigh(null));
    };

    const mapLinkHandler = (maprec) => {
        const mapdata = kwapi.getMapData(maprec.map, maprec.project);

        if (kwapi.environment() === 'development' && (store.getState().map === null || store.getState().map.ID !== mapdata.ID)) {
          store.dispatch(setMap(mapdata));
        }
        initMapLinkClick(maprec.index, mapdata);
    };

    const initMapLinkClick = (lindex, mapdata) => {
        const maplink = document.getElementById('map-link-' + lindex);
        if (maplink === null) {
            setTimeout(() => {
            initMapLinkClick(lindex, mapdata);
            }, 300);
        } else {
            maplink.addEventListener('click', (e) => {
                if (kwapi.environment() === 'development') {
                    history.push('/surveys/viewmap');
                } else {
                    history.push('/surveys/map/' + mapdata.URLToken);
                }
            });
        }
    };

    const dismissPopup = () => {
        if (!popupdismiss === false) {
            localStorage.setItem('map-welcome-dismiss', 0);
        } else {
            localStorage.setItem('map-welcome-dismiss', 1);
        }
        setPopupDismiss(!popupdismiss);
    }

    const togglePopup = () => {
        setPopupVisible(!popupvisible);
    };

    return (
        <Fragment>
            <Popup
                visible={popupvisible}
                onHiding={togglePopup}
                dragEnabled={false}
                shading={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                className="welcome-popup"
                title="Welcome"
                width={450}
                height={450}
            >
                <h4>Welcome to the Kier & Wright Virtual Tours App!</h4>
                <p>The markers on this map show the location of all of your projects that a survey is available.</p>
                <p>When you click on a marker a dialog will appear with a link to the survey map.</p>
                <p>You can also click the menu icon in the top left and click "Maps" to see a list of available maps.</p>
                <br/>
                <p>Happy Exploring!</p>
                <div className="dx-field">
                    <div className="dx-field-label">Don't show this again</div>
                    <div className="dx-field-value">
                        <CheckBox value={popupdismiss} onValueChanged={dismissPopup}/>
                    </div>
                </div>
                <Button
                    width={120}
                    text="Okay"
                    type="normal"
                    stylingMode="outlined"
                    onClick={togglePopup}
                />
            </Popup>
            <div className='map-container' ref={mapContainerRef} />
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={(!apipulled && !apierror)}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
            <ErrorPopup visible={apierror !== false} message={apierror}/>
        </Fragment>
    );
};
