export const status_filter_list = [
    {id: '', name: 'None', icon: null},
    {id: 'Open', name: 'Open', icon: 'export'},
    {id: 'Pending', name: 'Pending', icon: 'more'},
    {id: 'Resolved', name: 'Resolved', icon: 'check'},
    {id: 'Closed', name: 'Closed', icon: 'exportxlsx'}
];
export const status_list = [
    {id: 'Open', name: 'Open', icon: 'export'},
    {id: 'Pending', name: 'Pending', icon: 'more'},
    {id: 'Resolved', name: 'Resolved', icon: 'check'},
    {id: 'Closed', name: 'Closed', icon: 'exportxlsx'}
];