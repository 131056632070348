import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step3 = ({step}) => {

    const captions = {
        0: 'To pan/tilt the map, hold down the right mouse button (Ctrl + Click on a Mac), then drag the mouse in the direction you want to pan/tilt the map.'
    }
                       //0,  1 ,  2  , 3   ,  4  ,  5  ,  6  ,  7  ,  8  ,  9  ,  10  ,  11  ,  12  ,  13
    const timeIndexes = [0]

    return <MT_Video step={step} basictutorial={true} title="Pan/Tilt the Map" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/715737735/299378c4bd"/>;
}
export default MT_Step3;