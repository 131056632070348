import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step4 = ({step}) => {

    const captions = {
        0: 'Some maps have markers with different icons you can click on.  When you click on a marker a popup window will come up with content relating to where the marker is located (images, videos, panoramic views) on the map.',
        1: 'This marker is an example of a image gallery marker.',
        2: 'An image gallery marker allows you to view/click on images of the marker location and even zoom into an image to view it in more detail.',
        3: 'Marker popups appear by default inside the browser window.  However, you can click the detach button to make the popup an actual detached popup window that you can move anywhere on your screen or other screens (if applicable).',
    }
                       //0,  1 ,  2  , 3   ,  4  ,  5  ,  6  ,  7  ,  8  ,  9  ,  10  ,  11  ,  12  ,  13
    const timeIndexes = [0, 12.0, 25.0, 50.0]

    return <MT_Video step={step} basictutorial={true} title="Map Markers" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/717593946/79219b849e"/>;
}
export default MT_Step4;