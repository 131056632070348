import React, { useRef, useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { LoadPanel } from 'devextreme-react/load-panel';
import {Popup, Position} from 'devextreme-react/popup';
import {default as Gallery} from "../../components/gallery/gallery";
import ReactPlayer from "react-player"
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import UserComments from "./usercomments";
import ThreadPopup from "./threadpopup";
import { Tooltip } from 'devextreme-react/tooltip';
import store from '../../api/store/store';
import {
    setMBMap,
    setGeoJson,
    setPullLayers,
    setPullMarkers,
    setLng,
    setLat,
    setSelectedLayers,
    setSideMenuExpand,
    setZoom,
    setCustomMarkers,
    setShowComments,
    setCommentsTool,
    setCurrentView,
    setViewsTool,
    setShowMapTutorial,
    setError,
    setSubscriberList,
    setLayers,
    setShowThreads,
    setShowViews,
    setShowMarkersList,
    setMapLoaded,
    setMapFirstRender,
    setLayersLoaded,
    setMarkersLoaded,
    setSubLabels,
    setContourLabels,
    setMapCenter
} from '../../api/actions/actions';
import {mbapi} from "../../api/mbapi";
import {kwapi, user} from "../../api/kwapi";
import ErrorPopup from "../../components/error/ErrorPopup";
import './map.scss';
import StyleMenu from "./stylemenu";
import SideMenu from "./sidemenu";
import UserThreads from "./userthreads";
import {CommentsTool} from "../../components/header/CommentsTool";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import UserViews from "./userviews";
import MapMarkers from "./mapmarkers";
import ViewPopup from "./viewpopup";
import MapTutorial from "../../components/tutorials/MapTutorial";
import ToastMessage from "../../components/toast/ToastMessage";
import useBrowserDetection from "../../hooks/useBrowserDetection.";

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const MBMap = () => {

    const history = useHistory();
    const mapObj = store.getState().map;
    const dispatch = useDispatch();

    if (mapObj === null) {

        history.push('/surveys');
    }

    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);
    const [apiresp, setAPIResp] = useState(false);
    const [apierror, setStateAPIError] = useState(false);
    const [markerphotos, setMarkerPhotos] = useState([]);
    const [popupvisible, setPopupVisible] = useState(false);
    const [popupdata, setPopupData] = useState({});
    const [layerstooltip, setLayersTooltip] = useState(false);
    const [detachpopup, setdetachpopup] = useState(null);
    const [existingSubLayers, setExistingSubLayers] = useState([]);
    const [existingSubLabels, setExistingSubLabels] = useState([]);
    const [existingContourLabels, setExistingContourLabels] = useState([]);
    const disable_aerials = useSelector((state) => state.disable_aerials);
    const lng = useSelector((state) => state.lng);
    const lat = useSelector((state) => state.lat);
    const zoom = useSelector((state) => state.zoom);
    const layers = useSelector((state) => state.selectedlayers);
    const layersicon = useSelector((state) => state.layersicon);
    const showcomments = useSelector((state) => state.showcomments);
    const showthreads = useSelector((state) => state.showthreads);
    const commentsdata = useSelector((state) => state.commentsdata);
    const comments_tool = useSelector((state) => state.comments_tool);
    const showthreadpopup = useSelector((state) => state.showthreadpopup);
    const showviews = useSelector((state) => state.showviews);
    const showviewspopup = useSelector((state) => state.showviewspopup);
    const showmaptutorial = useSelector((state) => state.showmaptutorial);
    const showmarkerslist = useSelector((state) => state.showmarkerslist);
    const toastvisible = useSelector((state) => state.toastvisible);
    const mapRendered = useSelector((state) => state.map_first_render);
    const layers_loaded = useSelector((state) => state.layers_loaded);
    let sublabels = useSelector((state) => state.sublabels);
    let contourlabels = useSelector((state) => state.contourlabels);

    const browser = useBrowserDetection()

    const nonbase_layers = ['aerial', 'nhdflowline'];

    mapboxgl.accessToken = mapObj.Token;

    const year = moment().format('YYYY');

    // Initialize map when component mounts
    useEffect(() => {
        apiPull();
        const appfooter = document.querySelector('footer.footer');
        appfooter.style.display = 'none';

        // Clean up on unmount
        return unloadMap;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof popupdata.type !== 'undefined') {
            setPopupVisible(true);
        } else {
            setPopupVisible(false);
        }
    }, [popupdata])

    useEffect(() => {
        if (!popupvisible) {
            setMarkerPhotos([]);
        }
    }, [popupvisible]);

    useEffect(() => {
        if (typeof popupdata.type !== 'undefined' && popupdata.type === 'page') {
            if (detachpopup !== null) {
                detachPopup();
            }
        }
    }, [popupdata]);

    useEffect(() => {
        if (comments_tool && commentsdata !== null) {
            addCommentMarkers();
        } else {
            removeCommentMarkers();
        }
    }, [comments_tool, commentsdata]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (layers.length ) {
            const newlayers = [];
            layers.map((layer) => { //eslint-disable-line array-callback-return
                const layerid = typeof layer.mapbox_id !== 'undefined' ? layer.mapbox_id : layer.name;

                if (isNonBaseLayer(layerid) && disable_aerials) {
                    layer = initializeLayer(layer, map, false, true);
                } else if (isNonBaseLayer(layerid)) {
                    layer = initializeLayer(layer, map, true, false);
                }

                newlayers.push(layer);
            });

            dispatch(setSelectedLayers(newlayers));
        }
    }, [disable_aerials]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mapRendered && layers.length) {
            layers.map((layer) => {
                initializeLayer(layer, map, layer.selected, false);
            });
            toggleMarkerVisibility();
        }
    }, [mapRendered, layers_loaded]);

    useEffect(() => {
        showthreads && dismissPopupsExcept('showthreads');
    }, [showthreads]);

    useEffect(() => {
        showviews && dismissPopupsExcept('showviews');
    }, [showviews]);

    useEffect(() => {
        showmaptutorial && dismissPopupsExcept('showmaptutorial');
    }, [showmaptutorial]);

    useEffect(() => {
        showmarkerslist && dismissPopupsExcept('showmarkerslist');
    }, [showmarkerslist]);


    const setAPIError = (apierror) => {
        if (typeof apierror === 'object') {
            if (apierror.message !== 'Style is not done loading') {
                setStateAPIError(apierror);
            }
        } else {
            setStateAPIError(apierror);
        }
    };

    const initializeLayer = (layer, map, selected, use_item_ids) => {
        if (map !== null) {
            if (typeof use_item_ids === 'undefined') {
                use_item_ids = false;
            }

            layer.selected = selected;
            const layerid = typeof layer.mapbox_id !== 'undefined' ? layer.mapbox_id : layer.name;
            const visibility = (selected && !use_item_ids) || (!layer.visible && layer.is_label) ? 'visible' : 'none';
            const is_custom_layer = layer.id !== null ? layer.id.toString().indexOf('c_') !== -1 : false;

            if (!is_custom_layer && (typeof layer.items === 'undefined' || layer.parent_layer)) {
                map.setLayoutProperty(layerid, 'visibility', visibility);
            }

            if (layer.is_label && layer.selected) {
                map.setLayoutProperty(layerid, 'visibility', 'visible');
            }

            if (typeof layer.items !== 'undefined' && !layer.parent_layer && !is_custom_layer) {
                const itemmax = layer.items.length;
                for (let i = 0; i < itemmax; i++) {
                    let itemid;

                    if (use_item_ids) {
                        itemid = typeof layer.items[i].id !== 'undefined' ? layer.items[i].id : layer.items[i].name;
                    } else {
                        itemid = typeof layer.items[i].mapbox_id !== 'undefined' ? layer.items[i].mapbox_id : layer.items[i].name;
                    }
                    const is_custom_item = layer.items[i].id !== null ? layer.items[i].id.toString().indexOf('c_') !== -1 : false;
                    if (!is_custom_item) {
                        const item_visibility = layer.items[i].selected && !use_item_ids ? 'visible' : 'none';
                        map.setLayoutProperty(itemid, 'visibility', item_visibility);
                    } else if (layer.items[i].is_label && layer.items[i].selected) {
                        map.setLayoutProperty(itemid, 'visibility', 'visible');
                    }
                }
            }
        }
        return layer;
    }

    const dismissPopupsExcept = (activePopup) => {
        const dismissFunctions = {showthreads: [setShowThreads, setCommentsTool], showviews: [setShowViews, setViewsTool], showmaptutorial: setShowMapTutorial, showmarkerslist: setShowMarkersList };

        for (const key in dismissFunctions) {
            if (String(key) !== String(activePopup)) {
                if (Array.isArray(dismissFunctions[key])) {
                    dismissFunctions[key].map((dismiss_func) => {
                        dispatch(dismiss_func(false));
                    });
                } else {
                    dispatch(dismissFunctions[key](false));
                }
            }
        }
    }

    const apiPull = () => {
        let style_url = mapObj.styleURL;
        style_url = style_url.replace('mapbox://', '');
        const stylesarr = style_url.split('/');
        const mbuser = stylesarr[1];
        const mapid = stylesarr[2];
        const apiuri = mbuser + '/' + mapid + '?access_token=' + mapObj.Token;
        kwapi.getMapLayers(layersApiResp, apiError);
        mbapi.doAPICall(apiuri, 'get', undefined, apiResp, apiError, '*');
        if (user.isLoggedIn()) {
            kwapi.getThreadSubscriberList(subscriberListSuccess, subscriberListError);
        }
    }

    const apiResp = (resp) => {
        //populate Map obj with API results from mapbox
        if (typeof resp === 'object' && resp !== null) {
            dispatch(setLng(resp.center[0]));
            dispatch(setLat(resp.center[1]));
            dispatch(setMapCenter(resp.center));
            dispatch(setZoom(resp.zoom));
            setAPIResp(true);

            loadMap(resp.sources, resp.layers);
        } else {
            setAPIError('Invalid Response from server');
        }
    };

    const getValidLayerAttributes = (layer_jsonobj) => {
        let valid_cond = false;
        const valid_attrs = [];
        if (layer_jsonobj !== null && typeof layer_jsonobj.paint !== 'undefined' && Object.keys(layer_jsonobj.paint).length) {
            const sub_attributes = ['line-opacity', 'line-width', 'text-opacity', 'fill-opacity', 'circle-stroke-width', 'circle-opacity'];
            for (let i=0; i < sub_attributes.length; i++) {
                const attr = sub_attributes[i];
                if (typeof layer_jsonobj.paint[attr] !== 'undefined' && layer_jsonobj.paint[attr].length) {
                    valid_attrs.push({
                        attribute: attr,
                        data: layer_jsonobj.paint[attr]
                    });
                    valid_cond = !valid_cond ? true : valid_cond;
                }
            }
        }
        return [valid_cond, valid_attrs];
    }

    const compare_sortfield = ( a, b, fieldname ) => {
        /*if ( a[fieldname].toLowerCase() < b[fieldname].toLowerCase()){
            return -1;
        }
        if ( a[fieldname].toLowerCase() > b[fieldname].toLowerCase()){
            return 1;
        }
        return 0;*/
        const isNumber = (v) => (+v).toString() === v;
        const aPart = a[fieldname].toLowerCase().match(/\d+|\D+/g);
        const bPart = b[fieldname].toLowerCase().match(/\d+|\D+/g);
        let i = 0; let len = Math.min(aPart.length, bPart.length);
        while (i < len && aPart[i] === bPart[i]) { i++; };
        if (i === len) {
            return aPart.length - bPart.length;
        }
        if (isNumber(aPart[i]) && isNumber(bPart[i])) {
            return aPart[i] - bPart[i];
        }
        return aPart[i].localeCompare(bPart[i]);
    }

    const compare_groups_layers = (a, b) => {
        return compare_sortfield(a, b, 'name');
    }


    const layersApiResp = (mblayers) => {
        dispatch(setPullLayers(false));
        const newlayers = [];

        const groupidxs = {}
        let groupidx = 0;
        if (mblayers.groups.length) {
            mblayers.groups.sort(compare_groups_layers);
            while ((groupidx + 1) <= mblayers.groups.length) {
                groupidxs[mblayers.groups[groupidx].id] = groupidx;
                const groupobj = {};
                groupobj.id = mblayers.groups[groupidx].id;
                groupobj.name = mblayers.groups[groupidx].name;
                groupobj.order = mblayers.groups[groupidx].order;
                groupobj.items = [];
                groupobj.selected = mblayers.groups[groupidx].active === 1;
                newlayers.push(groupobj);
                groupidx++;
            }
        }
        let layersidx = 0;
        if (mblayers.layers.length) {
            mblayers.layers.sort(compare_groups_layers);
            dispatch(setLayers(mblayers.layers));

            while ((layersidx + 1) <= mblayers.layers.length) {
                const layerobj = {}
                let layer_jsonobj;
                try {
                    layer_jsonobj = JSON.parse(mblayers.layers[layersidx].json_obj);
                } catch (e) {
                    layer_jsonobj = null;
                }
                const parsed_attrs = getValidLayerAttributes(layer_jsonobj);

                layerobj.id = mblayers.layers[layersidx].id;
                layerobj.name = mblayers.layers[layersidx].name;
                layerobj.mapbox_id = mblayers.layers[layersidx].mapbox_id;
                layerobj.order = mblayers.layers[layersidx].order;
                layerobj.json_obj = layer_jsonobj;
                layerobj.is_label = mblayers.layers[layersidx].is_label;
                layerobj.type = 'mapbox';
                layerobj.visible = mblayers.layers[layersidx].visible;
                layerobj.selected = mblayers.layers[layersidx].active === 1 || (mblayers.layers[layersidx].visible !== 1 && mblayers.layers[layersidx].is_label);
                layerobj.parent_layer = false;

                if (layer_jsonobj !== null && parsed_attrs[0] !== false) {

                    for (let i = 0; i < parsed_attrs[1].length; i++) {
                        const attr = parsed_attrs[1][i];
                        if (attr.data[0] === 'interpolate' || attr.data[0] === 'match') {
                            const attr_data = attr.data;
                            let attr_arr, conditions;
                            if (attr.data[0] === 'interpolate') {
                                attr_arr = attr_data[4];
                                conditions = true;
                            } else if (attr.data[0] === 'match') {
                                attr_arr = attr_data;
                                conditions = false;
                            }
                            if (typeof attr_arr !== 'undefined' && Array.isArray(attr_arr)) {
                                const layer_label = attr_arr[1][1];
                                if (layer_label === 'layer') {
                                    layerobj.items = !Array.isArray(layerobj.items) ? [] : layerobj.items;
                                    for (let k = 0; k < attr_arr.length; k++) {
                                        const elem = attr_arr[k];
                                        if (Array.isArray(elem)) {
                                            const sub_obj = layer_jsonobj.paint[attr.attribute];
                                            if (typeof elem[0] !== 'undefined' && elem[0].indexOf('_sub_') !== -1) {
                                                layerobj.parent_layer = true;
                                                const sublayer_name = elem[0].replace('_sub_', '');
                                                if (!existingSubLayers.includes(sublayer_name)) {
                                                    existingSubLayers.push(sublayer_name);
                                                    setExistingSubLayers(existingSubLayers);
                                                    layerobj.items.push({
                                                        id: mblayers.layers[layersidx].id + '_sub_' + sublayer_name,
                                                        name: sublayer_name,
                                                        type: 'sub_mapbox',
                                                        subattr: attr.attribute,
                                                        selected: mblayers.layers[layersidx].active === 1 || (mblayers.layers[layersidx].visible !== 1 && mblayers.layers[layersidx].is_label),
                                                        conditions: conditions,
                                                        sub_jsonobj: sub_obj,
                                                    });
                                                } else {
                                                    console.log('Duplicate Sublayer name: "' + sublayer_name + '" found.  Skipping sublayer with duplicate name...');
                                                    //setAPIError('Duplicate Sublayer name: "' + sublayer_name + '" found.  Skipping sublayer with duplicate name...');
                                                }
                                            }

                                        }
                                    }
                                } else if (layer_label === 'prelayer') {
                                    for (let k = 0; k < attr_arr.length; k++) {
                                        const elem = attr_arr[k];
                                        if (Array.isArray(elem)) {
                                            if (typeof elem[0] !== 'undefined' && elem[0].indexOf('_subl_') !== -1) {
                                                const sublayer_name = elem[0].replace('_subl_', '');

                                                if (!existingSubLabels.includes(sublayer_name)) {
                                                    existingSubLabels.push(sublayer_name);
                                                    setExistingSubLabels(existingSubLabels);
                                                    sublabels.push({
                                                        layeridxs: [newlayers.length, k],
                                                        attribute: parsed_attrs[1],
                                                        layer: sublayer_name,
                                                        label: elem[0],
                                                    });
                                                    dispatch(setSubLabels(sublabels));
                                                } else {
                                                    console.log('Duplicate Sublabel name: "' + sublayer_name + '" found.  Skipping sublabel with duplicate name...');
                                                    //setAPIError('Duplicate Sublayer name: "' + sublayer_name + '" found.  Skipping sublayer with duplicate name...');
                                                }
                                            } else if (typeof elem[0] !== 'undefined' && elem[0].indexOf('_ctrsl_') !== -1) {
                                                const sublayer_name = elem[0].replace('_ctrsl_', '');
                                                if (!existingContourLabels.includes(sublayer_name)) {
                                                    existingContourLabels.push(sublayer_name);
                                                    setExistingContourLabels(existingContourLabels);
                                                    contourlabels.push({
                                                        layeridxs: [newlayers.length, k],
                                                        attribute: parsed_attrs[1],
                                                        layer: sublayer_name,
                                                        label: elem[0]
                                                    });
                                                    dispatch(setContourLabels(contourlabels));
                                                } else {
                                                    console.log('Duplicate Contours Label name: "' + sublayer_name + '" found.  Skipping Contours Label with duplicate name...');
                                                    //setAPIError('Duplicate Sublayer name: "' + sublayer_name + '" found.  Skipping sublayer with duplicate name...');
                                                }
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                // Sort child layers if they exist
                typeof layerobj.items !== 'undefined' && layerobj.items.sort(compare_groups_layers);

                if (mblayers.layers[layersidx].group_id !== null) {
                    const groupid = mblayers.layers[layersidx].group_id;
                    if (typeof newlayers[groupidxs[groupid]] !== 'undefined') {
                        newlayers[groupidxs[groupid]].items.push(layerobj);
                    }
                } else {
                    newlayers.push(layerobj);
                }
                layersidx++;
            }
        }
        //setStateLayers(newlayers);
        dispatch(setSelectedLayers(newlayers));
        //dispatch(setLayersLoaded(true));
    };

    const addCommentMarkers = () => {
        // add markers to map
        for (let m = 0; m < commentsdata.length; m++) {
            const markerobj = commentsdata[m];
            if (markerobj.type === 'marker') {
                // create a DOM element for the marker
                var el = document.createElement('div');
                el.id = 'thread-' + markerobj.id; //for popup position
                el.className = 'kw-comment-marker thread-' + markerobj.id;
                /*el.innerHTML = '<img src="'+kwapi.getCDNURL()+'assets/images/comments_marker_icon_red.png"/>';*/
                el.innerHTML = '<svg style="width: 37px;height: 58px;" xmlns="http://www.w3.org/2000/svg"\n' +
                    ' width="37.000000pt" height="58.000000pt" viewBox="0 0 368.000000 582.000000"\n' +
                    ' preserveAspectRatio="xMidYMid meet">\n' +
                    '\n' +
                    '<g transform="translate(0.000000,582.000000) scale(0.100000,-0.100000)"\n' +
                    '>\n' +
                    '<path fill="'+markerobj.color+'" stroke="none" d="M1636 5799 c-395 -42 -781 -218 -1067 -487 -584 -550 -729 -1373\n' +
                    '-366 -2082 208 -407 632 -1244 1247 -2463 210 -417 386 -755 391 -750 7 7 268\n' +
                    '522 1081 2133 171 338 377 746 458 905 163 320 206 424 244 595 131 590 -45\n' +
                    '1190 -475 1618 -305 304 -684 484 -1121 531 -110 12 -276 12 -392 0z m516\n' +
                    '-298 c550 -119 989 -511 1156 -1031 53 -166 66 -256 66 -455 0 -198 -17 -306\n' +
                    '-74 -477 -126 -377 -422 -708 -790 -882 -599 -283 -1299 -167 -1761 293 -241\n' +
                    '240 -377 498 -435 824 -22 127 -22 362 1 492 108 619 592 1112 1212 1235 140\n' +
                    '28 149 28 348 25 135 -3 204 -9 277 -24z m-993 -3176 c89 -44 226 -90 339\n' +
                    '-115 332 -72 660 -39 990 100 139 59 121 40 -33 -35 -119 -58 -251 -103 -385\n' +
                    '-131 -119 -25 -383 -26 -498 -1 -152 34 -314 100 -442 182 -70 44 -61 44 29 0z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1685 5364 c-597 -65 -1078 -506 -1181 -1084 -24 -135 -22 -336 5\n' +
                    '-465 39 -191 93 -321 195 -474 203 -305 503 -502 868 -572 98 -19 136 -21 288\n' +
                    '-16 144 4 193 10 275 31 257 65 444 172 631 360 195 196 307 405 361 674 24\n' +
                    '120 24 366 0 482 -130 617 -646 1049 -1272 1065 -71 2 -148 1 -170 -1z m369\n' +
                    '-270 c258 -45 485 -160 637 -322 137 -146 189 -263 197 -438 7 -160 -20 -261\n' +
                    '-102 -389 -142 -220 -417 -387 -726 -441 l-95 -16 -120 -116 c-243 -237 -391\n' +
                    '-363 -448 -381 -71 -24 -117 26 -117 127 0 28 22 123 55 237 30 103 53 190 50\n' +
                    '193 -2 2 -41 20 -86 38 -231 95 -431 279 -507 467 -39 96 -55 192 -48 286 27\n' +
                    '367 363 669 841 756 101 19 361 18 469 -1z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4765 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1310 4555 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4335 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4085 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1230 3895 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '</g>\n' +
                    '</svg>';

                el.setAttribute('title', 'Comment thread from ' + markerobj.user.firstname + ' ' + markerobj.user.lastname);
                /*el.style.backgroundSize = '100%';*/

                el.addEventListener('click', (e) => {
                    store.dispatch(setShowComments('comment-marker-' + markerobj.id));
                });
                const marker = JSON.parse(markerobj.json);
                // make a marker for each feature and add to the map
                new mapboxgl.Marker(el)
                    .setLngLat([marker.lng, marker.lat])
                    .addTo(map);

                el.style.display = 'none';
            } else if (markerobj.type === 'draw') {
                //@TODO add code to handle draw comment type
            }
        }
    };

    const removeCommentMarkers = () => {
        const comment_markers = document.getElementsByClassName('kw-comment-marker');
        for (let m = 0; m < comment_markers.length; m++) {
            const marker = comment_markers[m];
            marker.remove();
        }
    };

    const toggleMarkerVisibility = () => {
        const markers = document.getElementsByClassName('kw-marker');

        for (let i = 0; i < markers.length; i++) {
            let marker_visibility = true;
            let found_layer = false;
            const markerid = markers.item(i).id.replace('kw-marker-', '');

            var markerindex = store.getState().custom_markers.findIndex(obj => {
                return parseInt(obj.id) === parseInt(markerid)
            });

            if (typeof store.getState().custom_markers[markerindex] !== 'undefined') {
                store.getState().selectedlayers.map((layer) => {
                    if (!found_layer) {
                        if (typeof layer.items === 'undefined') {
                            const is_custom_layer = layer.id !== null ? layer.id.toString().indexOf('c_') !== -1 : false;
                            const matched_layer = is_custom_layer ? layer.id === store.getState().custom_markers[markerindex].layer_id : parseInt(layer.id) === parseInt(store.getState().custom_markers[markerindex].layer_id);
                            if (matched_layer) {
                                found_layer = true;
                                if (!layer.selected) {
                                    marker_visibility = false;
                                }
                            }
                        } else {
                            const itemmax = layer.items.length;
                            for (let m = 0; m < itemmax; m++) {
                                const is_custom_layer = layer.items[m].id !== null ? layer.items[m].id.toString().indexOf('c_') !== -1 : false;
                                const matched_layer = is_custom_layer ? layer.items[m].id === store.getState().custom_markers[markerindex].layer_id : parseInt(layer.items[m].id) === parseInt(store.getState().custom_markers[markerindex].layer_id);
                                if (matched_layer) {
                                    found_layer = true;
                                    if (!layer.items[m].selected) {
                                        marker_visibility = false;
                                    }
                                    break;
                                }
                            }

                        }
                    }
                });
            }

            if (markers.item(i).style.display === 'none' && (marker_visibility || !found_layer)) {
                markers.item(i).style.display = 'block';
            } else if (!marker_visibility) {
                markers.item(i).style.display = 'none';
            }
        }
    };

    const addCustomMarkers = (markers) => {
        let markernum = 1;
        markers.forEach(function (marker) {
            // create a DOM element for the marker
            var el = document.createElement('div');
            el.id = 'kw-marker-'+marker.id;
            el.className = 'kw-marker';
            /*el.style.backgroundImage =
                'url(/backend/mg/sitetours/map/marker/'+iconchar+'/'+color+'.svg)';*/
            el.innerHTML = '<svg class="circle-icon" width="32px" height="32px">\n' +
                '        <g>\n' +
                '            <circle fill="' + marker.color + '" cx="15" cy="15" r="15"></circle>\n' +
                '            <text class="icon" x=5 y=23>&#x' + marker.icon + ';</text>\n' +
                '        </g>\n' +
                '        </svg>';
            el.style.width = '41.6px';
            el.style.height = '41.6px';
            el.style.backgroundSize = '100%';

            // make a marker for each feature and add to the map
            switch (marker.type) {
                case 'richtext':
                    new mapboxgl.Marker(el)
                        .setLngLat([marker.lng, marker.lat])
                        .setPopup(new mapboxgl.Popup({offset: 25}) // add popups
                            .setHTML(marker.html))
                        .addTo(store.getState().mbmap);
                    break;
                case 'video':
                    el.addEventListener('click', () => {
                            setMarkerPhotos([]);
                            cyclePopup(marker, true);
                        }
                    );
                    new mapboxgl.Marker(el)
                        .setLngLat([marker.lng, marker.lat])
                        .addTo(store.getState().mbmap);
                    break;
                case 'gallery':
                    el.addEventListener('click', () => {
                            /*const galleryimgs = marker.gallery_imgs;*/
                            if (typeof marker.gallery_imgs !== 'undefined' && marker.gallery_imgs !== null) {
                                const photos = [];
                                marker.gallery_imgs.map((imageobj, i) => { //eslint-disable-line array-callback-return
                                    photos.push(imageobj.url);
                                });
                                setMarkerPhotos(photos);
                                cyclePopup(marker, true);
                            }
                        }
                    );
                    new mapboxgl.Marker(el)
                        .setLngLat([marker.lng, marker.lat])
                        .addTo(store.getState().mbmap);
                    break;
                case 'tour':
                    el.addEventListener('click', (e) => {
                        openPopup(marker, true);
                    });
                    new mapboxgl.Marker(el)
                        .setLngLat([marker.lng, marker.lat])
                        .addTo(store.getState().mbmap);
                    break;
                default:
                    new mapboxgl.Marker(el)
                        .setLngLat([marker.lng, marker.lat])
                        .setPopup(new mapboxgl.Popup({offset: 25}) // add popups
                            .setHTML('<a id="marker-link-' + markernum + '" class="view-marker" style="margin: 10px;">' + marker.title + '</a>'))
                        .addTo(store.getState().mbmap);
            }
            markernum++;
        });
        if (store.getState().mbmap !== null) {
            const mapobj = store.getState().mbmap;
            store.getState().selectedlayers.map((layerobj) => {
                if (!layerobj.selected) {
                    let is_custom_layer = layerobj.id.toString().indexOf('c_') !== -1;
                    const layerid = typeof layerobj.mapbox_id !== 'undefined' ? layerobj.mapbox_id : layerobj.name;

                    (typeof layerobj.items === 'undefined' && !is_custom_layer) || (layerobj.parent_layer) && mapobj.setLayoutProperty(layerid, 'visibility', 'none');

                    if (typeof layerobj.items !== 'undefined' && !layerobj.parent_layer) {
                        const itemmax = layerobj.items.length;
                        for (let i = 0; i < itemmax; i++) {
                            const itemid = typeof layerobj.items[i].mapbox_id !== 'undefined' ? layerobj.items[i].mapbox_id : layerobj.items[i].name;
                            is_custom_layer = layerobj.items[i].id.toString().indexOf('c_') !== -1;
                            if (!is_custom_layer) {
                                mapobj.setLayoutProperty(itemid, 'visibility', 'none');
                            }
                        }
                    }

                }
            });

            toggleMarkerVisibility();
        }
        markers.length && store.getState().mbmap.on('zoomend', () => {
            if (store.getState().map.MarkerMinZoom !== null) {
                if (store.getState().mbmap.getZoom() < store.getState().map.MarkerMinZoom) {
                    const markers = document.getElementsByClassName('kw-marker');

                    for (let i = 0; i < markers.length; i++) {
                        if (markers.item(i).style.display !== 'none') {
                            markers.item(i).style.display = 'none';
                        }
                    }
                } else {
                    toggleMarkerVisibility();
                }
            }
        });
        dispatch(setMarkersLoaded(true));
    };
    const markersAPIResp = (markers) => {
        dispatch(setPullMarkers(false));
        dispatch(setCustomMarkers(markers));
        addCustomMarkers(markers);
    };

    const subscriberListError = () => {
        dispatch(setError('Unable to retrieve subscribers from API'));
    };
    const subscriberListSuccess = (subscriberList) => {
        dispatch(setSubscriberList(subscriberList));
    }

    const apiError = (error) => {
        setAPIError(error);
    };

    const isNonBaseLayer = (layername) => {
        let nbase = false;
        const nbasecnt = nonbase_layers.length
        for (let i = 0; i < nbasecnt; i++) {
            nbase = !nbase ? layername.toLowerCase().indexOf(nonbase_layers[i]) !== -1 || nonbase_layers[i] === layername.toLowerCase() : nbase;
        }

        return nbase;
    };

    const unloadMap = () => {
        dispatch(setLayersLoaded(false));
        dispatch(setMapFirstRender(false));
        dispatch(setMapLoaded(false));
        dispatch(setMarkersLoaded(false));
        dispatch(setMapCenter(null));

        const appfooter = document.querySelector('footer.footer');
        if (appfooter !== null) {
            appfooter.style.display = 'block';
        }

        // Clean up on unmount
        if (map !== null) {
            return () => map.remove();
        } else {
            return () => {
            }
        }
    };

    const loadMap = (sources, layers) => {
        const mapobj = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapObj.styleURL,
            center: [lng, lat],
            zoom: zoom,
            attributionControl: false,
            preserveDrawingBuffer: true
        });

        // GeoJSON object to hold our measurement features
        const geojson = {
            'type': 'FeatureCollection',
            'features': []
        };

        /* Given a query in the form "lng, lat" or "lat, lng"
        * returns the matching geographic coordinate(s)
        * as search results in carmen geojson format,
        * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
        var coordinatesGeocoder = function (query) {
            // Match anything which looks like
            // decimal degrees coordinate pair.
            var matches = query.match(
                /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
            );
            if (!matches) {
                return null;
            }

            function coordinateFeature(lng, lat) {
                return {
                    center: [lng, lat],
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    },
                    place_name: 'Lat: ' + lat + ' Lng: ' + lng,
                    place_type: ['coordinate'],
                    properties: {},
                    type: 'Feature'
                };
            }

            var coord1 = Number(matches[1]);
            var coord2 = Number(matches[2]);
            var geocodes = [];

            if (coord1 < -90 || coord1 > 90) {
                // must be lng, lat
                geocodes.push(coordinateFeature(coord1, coord2));
            }

            if (coord2 < -90 || coord2 > 90) {
                // must be lat, lng
                geocodes.push(coordinateFeature(coord2, coord1));
            }

            if (geocodes.length === 0) {
                // else could be either lng, lat or lat, lng
                geocodes.push(coordinateFeature(coord1, coord2));
                geocodes.push(coordinateFeature(coord2, coord1));
            }

            return geocodes;
        };

        // Add the control to the map.
        mapobj.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                localGeocoder: coordinatesGeocoder,
                zoom: 16,
                placeholder: 'Search',
                mapboxgl: mapboxgl,
                limit: false
            })
        );
        mapobj.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            })
        );
        // Add navigation control (the +/- zoom buttons)
        mapobj.addControl(new mapboxgl.NavigationControl({options: {visualizePitch: true}}), 'top-right');

        mapobj.addControl(new mapboxgl.ScaleControl({unit: 'imperial', maxWidth: 200}), 'bottom-left');

        //hacky way of adding Scale label to mapbox scale control
        const mapboxctrlleft = document.querySelector('.mapboxgl-ctrl-bottom-left');

        const ScaleLabel = document.createElement("div");
        ScaleLabel.id = 'scale-label';
        ScaleLabel.innerHTML = 'Scale';
        mapboxctrlleft.prepend(ScaleLabel);

        mapboxctrlleft.className = "mapboxgl-ctrl-bottom-center";

        const topcenterctrls = document.createElement("div");
        topcenterctrls.className = "mapboxgl-ctrl-top-center";

        mapobj.on('move', () => {
            dispatch(setLng(mapobj.getCenter().lng.toFixed(4)));
            dispatch(setLat(mapobj.getCenter().lat.toFixed(4)));
            dispatch(setZoom(mapobj.getZoom().toFixed(2)));
        });
        mapobj.on('load', () => {
            //Do stuff on map load
            dispatch(setMapLoaded(true));

            mapobj.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1'
            });

            dispatch(setMapCenter(mapobj.getCenter()));
        });

        setMap(mapobj);
        dispatch(setMBMap(mapobj));
        dispatch(setGeoJson(geojson));
        //setLayerFilters(layers.map(({ id }) => id));
        kwapi.getMapMarkers(markersAPIResp, apiError);

        const toprightctrls = document.querySelector('.mapboxgl-ctrl-top-right');
        toprightctrls.id = 'mapboxgl-ctrl-top-right';
        const mblogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
        //const mbattrib = document.getElementsByClassName('mapboxgl-ctrl-bottom-right')[0];
        mblogo.style.position = 'absolute';
        mblogo.style.left = 0;
        const bottomcenterctrls = document.querySelector('.mapboxgl-ctrl-bottom-center');
        /*bottomcenterctrls.style.left = 'unset';*/
        bottomcenterctrls.style.right = '10px';
        bottomcenterctrls.style.bottom = '70px';
        const stylemenu = document.getElementById('stylemenu');
        stylemenu.style.bottom = '60px';
        const existing_titleblock = document.querySelector('.footer-title-block');
        let titleblock;
        //mbattrib.innerHTML = '';
        if (existing_titleblock === null) {
            titleblock = document.createElement('div');
            titleblock.className = 'footer-title-block';
            titleblock.innerHTML ='<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-title-attrib"><div class="mapboxgl-ctrl-attrib-inner title-attrib">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';
            const mapboxctrls = document.querySelector('.mapboxgl-control-container');
            mapboxctrls.appendChild(titleblock);
            const titleattrib = document.querySelector('.title-attrib');

            titleattrib.innerHTML = '<img src="'+kwapi.getCDNURL()+'assets/images/logo.svg" style="width: 180px;margin-top: 10px;margin-left: 5px;"><div class="vt-title" style="font-size: 20px;margin-left: 190px;margin-top: -34px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div><div class="mb-attribution">Map &amp; Satellite Data included in this map  \n' +
                '<a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox" role="listitem">© Mapbox</a>&nbsp;<a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a>&nbsp;<a href="https://www.maxar.com/" target="_blank" title="Maxar" aria-label="Maxar" role="listitem">© Maxar</a></div>';
        }
        //mbattrib.innerHTML = '<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib"><div class="mapboxgl-ctrl-attrib-inner">Copyright © ' + new Date().getFullYear() + ' <a href="https://kierwright.com" target="_blank">Kier & Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';

        if (kwapi.getThreadRoute() !== null) {
            CommentsTool.getInstance().create();
            dispatch(setCommentsTool(true));
        }

        const layersTooltipInit = () => {
            setLayersTooltip(true);
            setTimeout(() => {
                setLayersTooltip(false);
                mapobj.off('idle', layersTooltipInit);
            }, 10000);
        };

        mapobj.on('idle', layersTooltipInit);

        mapobj.on('render', () => {
            !mapRendered && dispatch(setMapFirstRender(true));
            const currentview = {
                zoom: mapobj.getZoom(),
                pitch: mapobj.getPitch(),
                center: mapobj.getCenter(),
                bearing: mapobj.getBearing(),
            }
            dispatch(setCurrentView(currentview));

            if (kwapi.getMapView() !== null) {
                dispatch(setViewsTool(true));
            }
        });

        if ((localStorage.getItem('map-tutorial-dismiss') === null || localStorage.getItem('map-tutorial-dismiss') === 0) && kwapi.getThreadRoute() === null && kwapi.getMapView() === null) {
            setTimeout(() => {
                dispatch(setShowMapTutorial(true));
            }, 2000)
        }
    };

    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars

    const clearSelectedMarker = () => {
        const customMarkers = document.querySelectorAll('.kw-marker circle');
        customMarkers.forEach((marker) => {
            marker.style.stroke = '#fff';
        });
    };

    const cyclePopup = (marker, markerposition) => {
        dismissPopup();
        setTimeout(() => {
            openPopup(marker, markerposition);
        }, 50);
    };

    const openPopup = (marker, markerposition) => {
        if (typeof markerposition === 'undefined') { markerposition = false; }
        clearSelectedMarker();
        if (markerposition) {
            //const lngOffset = height > 1180 ? 0.002 : height > 1000 ? 0.001 : 0.001;
            //1010 total current: 485
            const lngOffset = height > 1180 ? 0.000744 : height > 1000 ? 0.000372 : 0.000372;
            const latOffset = 0;
            store.getState().mbmap.setCenter([parseFloat(marker.lng) + lngOffset, parseFloat(marker.lat) + latOffset]);
            setTimeout(() => {
                store.getState().mbmap.setZoom(19.7);
                const activeMarker = document.querySelector('#kw-marker-' + marker.id + ' circle');
                if (activeMarker !== null) { activeMarker.style.stroke = '#d6001c'; }
            },50);
        }
        setPopupData(marker);
    };
    const dismissPopup = () => {
        clearSelectedMarker();
        setPopupData({});
    };
    const expandSideMenu = () => {
        setLayersTooltip(false);
        dispatch(setSideMenuExpand(true))
    };

    const detachPopup = () => {
        if (detachpopup === null) {
            let dpopupheight, dpopupwidth;
            if (popupdata.type === 'embed' && browser === 'firefox') {
                dpopupheight = '420';
                dpopupwidth = '500';
            } else if (popupdata.type === 'video') {
                dpopupheight = '542';
                dpopupwidth = '938';
            } else {
                dpopupheight = '680';
                dpopupwidth = '670';
            }
            const newDetachPopup = window.open('', 'detachedPopup', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,height='+dpopupheight+',width='+dpopupwidth+',top=0,left=425,resizable=0');
            let popupCode;
            if (popupdata.embed_code !== null && typeof popupdata.embed_code !== 'undefined' && popupdata.embed_code !== '') {
                const popupCode = '<div id="detach-embed-code">' + popupdata.embed_code + '</div>';
                newDetachPopup.document.write(popupCode);
            } else if (popupdata.type === 'gallery') {
                popupCode = '' +
                    '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/lightgallery.js@1.4.0/dist/css/lightgallery.min.css">' +
                    '<style>' +
                    '.gallery-thumb {\n' +
                    '    display: flex;\n' +
                    '    flex-wrap: wrap;\n' +
                    '}\n' +
                    '.gallery-thumb .column {\n' +
                    '    flex: 33.33% 1;\n' +
                    '    padding: 5px;\n' +
                    '    max-width: 265px;\n' +
                    '}' +
                    '.gallery-thumb .column img {\n' +
                    '    border: 1px solid #ddd;\n' +
                    '    border-radius: 4px;\n' +
                    '    padding: 10px;\n' +
                    '    max-width: 250px;\n' +
                    '    max-height: 150px;\n' +
                    '    cursor: pointer;\n' +
                    '}' +
                    '.item img {\n' +
                    '    max-width: 100%;\n' +
                    '    display: block;\n' +
                    '}' +
                    '</style>' +
                    '<script src="'+kwapi.getCDNURL()+'assets/js/lightgallery.min.js"></script>' +
                    '<script src="'+kwapi.getCDNURL()+'assets/js/lg-thumbnail.min.js"></script>' +
                    '<script src="'+kwapi.getCDNURL()+'assets/js/lg-fullscreen.min.js"></script>' +
                    '<div id="popup-gallery">' +
                    '<div class="content">' +
                    '<div>' +
                    '<div id="lightgallery" class="gallery-thumb">';
                popupdata.gallery_imgs.map((imgobj) => {
                    popupCode +=
                        '<div class="column">' +
                        '<div class="item" data-src="'+imgobj.url+'">' +
                        '<img src="'+imgobj.url+'" alt="Gallery Item" style="width: 100%;">' +
                        '</div></div>';
                });
                popupCode += '</div></div></div></div>' +
                    '<script>lightGallery(document.getElementById(\'lightgallery\'), {' +
                    '    selector: \'.item\',\n' +
                    '    download: false'+
                    '});</script>';
                newDetachPopup.document.write(popupCode);
            } else if (popupdata.type === 'video') {
                popupCode = document.querySelector('.video-popup .dx-popup-normal .dx-popup-content').innerHTML;
                popupCode = popupCode.replace('<div style="width: 640px; height: 360px;">','<div style="width: 100%; height: 100%;">');
                newDetachPopup.document.write(popupCode);
            }
            newDetachPopup.onbeforeunload = () => {
                setdetachpopup(null);
                clearSelectedMarker();
            }
            setdetachpopup(newDetachPopup);

        } else {
            const embedDiv = detachpopup.document.getElementById('detach-embed-code');
            embedDiv.innerHTML = '';
            embedDiv.innerHTML = popupdata.embed_code;
        }
        dismissPopup();
    }

    const commentsVisible = showcomments !== false;

    //Dynamic popup dimensions based on screen size

    let vidpopupWidth, vidpopupHeight, galpopupWidth, galpopupHeight, ebpopupWidth, ebpopupHeight;
    if (width > 840) {
        vidpopupWidth = 840;
        vidpopupHeight = 580;
        galpopupWidth = 871;
        galpopupHeight = 715;
        ebpopupWidth = 670;
        ebpopupHeight = 715;
    } else if (width > 670) {
        vidpopupWidth = 616;
        vidpopupHeight = 409;
        galpopupWidth = 670;
        galpopupHeight = 530;
        ebpopupWidth = 515;
        ebpopupHeight = 715;
    } else if (width <= 670 && width >= 500) {
        vidpopupWidth = 412;
        vidpopupHeight = 297;
        galpopupWidth = 515;
        galpopupHeight = 433;
        ebpopupWidth = 391;
        ebpopupHeight = 715;
    } else if (width < 500) {
        vidpopupWidth = 379;
        vidpopupHeight = 279;
        galpopupWidth = 391;
        galpopupHeight = 356;
        ebpopupWidth = 291;
        ebpopupHeight = 715;
    }

    //Dynamically change embed popup position based on screen size
    const offsetY = height > 1180 ? 700 : height > 1000 ? 600 : height > 700 ? 500 : 100;
    const offsetX = /*height > 760 ? 125 : 70*/50;

    return (
        <Fragment>
            <div className="layers-icon" title="Layers Menu">
                <img id="layersmenu" src={kwapi.getCDNURL()+'assets/images/' + layersicon + '.png'} alt="Layers Menu" onClick={expandSideMenu}/>
                <Tooltip
                    target="#layersmenu"
                    position="right"
                    visible={layerstooltip}
                    closeOnOutsideClick={false}
                >
                    Toggle Layer Selection<br />
                    Here
                </Tooltip>
            </div>

            <div id="lnglatzoom-display" className='sidebarStyle'>
                <div>
                    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                </div>
            </div>
            <div id="spotelevation-display" className='sidebarStyle'>
                <div className="elevation-container"/>
            </div>

            <SideMenu/>

            <div className='map-container' ref={mapContainerRef}/>
            <StyleMenu/>
            <div id="distance" className="distance-container"/>
            <div className="calculation-box">
                <i id="polytools-icon" className="dx-icon-sortup"
                   style={{fontSize: '30px', float: 'right', marginRight: '-17px'}}/>
                <p id="polytools-info" style={{textAlign: 'left'}}>Use the polygon tools to start drawing</p>
                <div id="calculated-area"/>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={!apiresp && !apierror && !store.getState().maplink}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
            {typeof popupdata.type !== 'undefined' ?
                popupdata.type === 'video' ?
                    <Popup
                        visible={popupvisible}
                        onHiding={dismissPopup}
                        dragEnabled={true}
                        resizeEnabled={true}
                        closeOnOutsideClick={false}
                        showCloseButton={true}
                        showTitle={true}
                        title={popupdata.title}
                        className="video-popup"
                        defaultHeight={vidpopupHeight}
                        defaultWidth={vidpopupWidth}
                        maxWidth={1080}
                        maxHeight={720}
                        shading={false}
                    >
                        <Position
                            at="top"
                            my="left"
                            of={window}
                            offset={offsetX + ' ' + offsetY}
                            collision="fit"
                        />
                        <ReactPlayer
                            url={popupdata.vimeo_url}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        autoplay: true,
                                        controls: true,
                                        color: 'D6001C'
                                    }
                                },
                            }}
                        />
                        {width > 800 &&
                            <div id="detach-btn-container">
                                <div
                                    className="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
                                    aria-label="detach" role="button" onClick={detachPopup}>
                                    <div className="dx-button-content">
                                        <i className="dx-icon dx-icon-detach"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </Popup>
                    : popupdata.type === 'gallery' ?
                        <Popup
                            visible={popupvisible}
                            onHiding={dismissPopup}
                            dragEnabled={true}
                            resizeEnabled={true}
                            closeOnOutsideClick={false}
                            showCloseButton={true}
                            showTitle={true}
                            className="gallery-popup"
                            title={popupdata.title}
                            defaultHeight={galpopupHeight}
                            defaultWidth={galpopupWidth}
                            maxWidth={837}
                            maxHeight={715}
                            shading={false}
                        >
                            <Position
                                at="top"
                                my="left"
                                of={window}
                                offset={offsetX + ' ' + offsetY}
                                collision="fit"
                            />
                            <Gallery photos={markerphotos}/>
                            {width > 800 &&
                                <div id="detach-btn-container">
                                    <div
                                        className="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
                                        aria-label="detach" role="button" onClick={detachPopup}>
                                        <div className="dx-button-content">
                                            <i className="dx-icon dx-icon-detach"/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Popup>
                        : popupdata.type === 'tour' ?
                            <Popup
                                visible={popupvisible}
                                onHiding={dismissPopup}
                                dragEnabled={true}
                                resizeEnabled={true}
                                closeOnOutsideClick={false}
                                showCloseButton={true}
                                showTitle={true}
                                title={popupdata.title}
                                className="page-popup"
                                defaultHeight={ebpopupHeight}
                                defaultWidth={ebpopupWidth}
                                maxWidth={837}
                                maxHeight={715}
                                shading={false}
                                dragOutsideBoundary={true}
                            >
                                <Position
                                    at="top"
                                    my="left"
                                    of={window}
                                    offset={offsetX + ' ' + offsetY}
                                    collision="fit"
                                />
                                <div className="tool-embed" dangerouslySetInnerHTML={{__html: popupdata.embed_code}}/>
                                {width > 800 &&
                                    <div id="detach-btn-container">
                                        <div
                                            className="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
                                            aria-label="detach" role="button" onClick={detachPopup}>
                                            <div className="dx-button-content">
                                                <i className="dx-icon dx-icon-detach"/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Popup>
                            :
                            ''
                :
                ''
            }
            {showthreads &&
                <UserThreads/>
            }
            {commentsVisible &&
                <UserComments showComments={commentsVisible} commentsId={showcomments}/>
            }
            {showthreadpopup &&
                <ThreadPopup visible={showthreadpopup}/>
            }
            {showviews &&
                <UserViews/>
            }
            {showviewspopup &&
                <ViewPopup visible={showviewspopup}/>
            }
            {showmaptutorial &&
                <MapTutorial/>
            }
            {showmarkerslist &&
                <MapMarkers openPopup={openPopup} setMarkerPhotos={setMarkerPhotos} clearSelectedMarker={clearSelectedMarker}/>
            }
            {apierror &&
                <ErrorPopup visible={true} message={apierror}/>
            }
            {toastvisible &&
                <ToastMessage/>
            }
        </Fragment>
    );
};

export default MBMap;