import React, {useState, useRef, useEffect, Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import TinyMce from "../tinymce/TinyMce";
import {setCommentsData, setError, setActiveThread} from "../../api/actions/actions";
import {kwapi, user} from "../../api/kwapi";
import './user-comments.scss';
import ConfirmPopup from "../confirmpopup/ConfirmPopup";


export default function () {
    const replyRef = useRef(null);
    const active_thread = useSelector((state) => state.active_thread);
    const commentsdata = useSelector((state) => state.commentsdata);
    const [commenthtml, setCommentHtml] = useState('');
    const [editoractive, setEditorActive] = useState(false);
    const [activereply, setActiveReply] = useState(null);
    const [submitreply, setSubmitReply] = useState(false);
    const [showconfirm, setShowConfirm] = useState(false);
    const [confirmmsg, setConfirmMsg] = useState('Are you sure you want to delete this comment?');
    const [confirmtitle, setConfirmTitle] = useState('Delete Comment');
    const [actionobj, setActionObj] = useState(null);
    const dispatch = useDispatch();
    const account = kwapi.getClientAccount();

    // Initialize when component mounts
    useEffect(() => {
        scrollTimer();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const scrollTimer = () => {
        const threadroute = kwapi.getThreadRoute();
        if (threadroute !== null) {
            kwapi.unsetThreadRoute();
            if (threadroute.reply_id !== '0') {
                setTimeout(() => {
                    setActiveReply('reply-' + threadroute.reply_id + '-reply-form');
                    scrollToReply();
                }, 3000);
            }
        }
    };

    const highlightFor = (id, color, seconds) => {
        const element = document.getElementById(id)
        const origcolor = element.style.background;
        const replybtn = document.querySelector('#'+id+' .reply-btn');
        const spacer = document.querySelector('#'+id+' .spacer-30');
        const actionbtns = document.getElementsByClassName('action-btn');

        replybtn.style.display = 'none';
        spacer.classList.toggle('active');
        element.style.backgroundColor = color;
        for (let i=0;i < actionbtns.length; i++) {
            actionbtns[i].disabled = true;
        }

        setTimeout(() => {
            element.style.background = origcolor;
            spacer.classList.toggle('active');
            replybtn.style.display = 'block';
            for (let i=0;i < actionbtns.length; i++) {
                actionbtns[i].disabled = false;
            }
        },(seconds*1000));
    }

    const scrollToReply = () => {
        replyRef.current.scrollIntoView(false);
        highlightFor(replyRef.current.id, '#c5ccff', 2);
    }; // run this function from an event handler or pass it to useEffect to execute scroll

    const deleteHighlightReply = () => {
        highlightFor(replyRef.current.id, '#c27070', 1, true);
    }

    const toggleReplyForm = (e) => {
       const target = e.target;
       const replyForm = document.getElementById(target.getAttribute("data-target"));
       if (target.classList.contains('reply-btn')) {
           setCommentHtml('');
           const otherReplyForms = document.getElementsByClassName('reply-form');
           for (let i = 0; i < otherReplyForms.length; i++) {
               if (!otherReplyForms[i].classList.contains('d-none')) {
                   otherReplyForms[i].classList.toggle("d-none");
               }
           }
           setActiveReply(target.getAttribute("data-target"));
           setEditorActive(true);
       } else {
           if (actionobj !== null) {
               let commentp, replybtn;
               if (typeof actionobj.user !== 'undefined') {
                   commentp = document.getElementById('thread-comment-'+actionobj.id);
                   replybtn = document.querySelector('#comment-1 .comment-body .reply-btn');
               } else {
                   commentp = document.getElementById('reply-comment-' + actionobj.id);
                   replybtn = document.querySelector('#reply-'+actionobj.id+' .comment-body .reply-btn');
               }
               commentp.innerHTML = commenthtml;
               commentp.style.display = 'block';
               replybtn.style.display = 'block';
           }
           setEditorActive(false);
           setActiveReply(null);
           setCommentHtml('');
           setActionObj(null);
       }

        replyForm.classList !== null && replyForm.classList.toggle("d-none");
    };

    const toggleThreadEditForm = () => {
        setCommentHtml(active_thread.thread.comment);
        setActionObj(active_thread);
        const otherReplyForms = document.getElementsByClassName('reply-form');
        for (let i = 0; i < otherReplyForms.length; i++) {
            if (!otherReplyForms[i].classList.contains('d-none')) {
                otherReplyForms[i].classList.toggle("d-none");
            }
        }
        const replybtn = document.querySelector('#comment-1 .comment-body .reply-btn');
        replybtn.style.display = 'none';
        const commentp = document.getElementById('thread-comment-'+active_thread.id);
        commentp.style.display = 'none';
        setActiveReply('comment-'+active_thread.id+'-reply-form');
        setEditorActive(true);
        const editForm = document.getElementById('comment-'+active_thread.id+'-reply-form');
        editForm.classList !== null && editForm.classList.toggle("d-none");
    };

    const toggleReplyEditForm = (replyobj) => {
        const reply_id =  replyobj.id;
        const comment_markup = document.getElementById('reply-comment-'+reply_id).innerHTML;
        setCommentHtml(comment_markup);
        const newreplyobj = replyobj;
        newreplyobj.comment = comment_markup;
        setActionObj(newreplyobj);
        const otherReplyForms = document.getElementsByClassName('reply-form');
        for (let i = 0; i < otherReplyForms.length; i++) {
            if (!otherReplyForms[i].classList.contains('d-none')) {
                otherReplyForms[i].classList.toggle("d-none");
            }
        }
        const replybtn = document.querySelector('#reply-'+reply_id+' .comment-body .reply-btn');
        replybtn.style.display = 'none';
        const commentp = document.getElementById('reply-comment-'+reply_id);
        commentp.style.display = 'none';
        setActiveReply('reply-'+reply_id+'-reply-form');
        setEditorActive(true);
        const editForm = document.getElementById('reply-'+reply_id+'-reply-form');
        editForm.classList !== null && editForm.classList.toggle("d-none");
    }
    const onReplyDelete = (replyobj) => {
        setActionObj(replyobj);
        setConfirmTitle('Delete Comment');
        setConfirmMsg('Are you sure you want to delete this comment?');
        setShowConfirm(true);
    };

    const doReplyDelete = () => {
        //match user
        if (parseInt(account.id) === parseInt(actionobj.user_id) || user.isAdminAccount()) {
            setActiveReply('reply-'+actionobj.id+'-reply-form');
            deleteHighlightReply();
            kwapi.deleteThreadReply(actionobj, replyDeleteSuccess, replyError);
        }
    };

    const threadEditSuccess = (thread) => {
        const updatedThread = active_thread;
        updatedThread.thread.comment = thread.comment;
        const threadidx = commentsdata.findIndex(x => x.id.toString() === updatedThread.id.toString());
        const updatedCommentsData = commentsdata;
        updatedCommentsData[threadidx] = updatedThread;
        const otherReplyForms = document.getElementsByClassName('reply-form');
        for (let i = 0; i < otherReplyForms.length; i++) {
            if (!otherReplyForms[i].classList.contains('d-none')) {
                otherReplyForms[i].classList.toggle("d-none");
            }
        }
        dispatch(setActiveThread(updatedThread));
        dispatch(setCommentsData(updatedCommentsData));
        setSubmitReply(false);
        const replybtn = document.querySelector('#comment-1 .comment-body .reply-btn');
        const commentp = document.getElementById('thread-comment-'+active_thread.id);
        setActionObj(null);
        commentp.innerHTML = commenthtml;
        replybtn.style.display = 'block';
        commentp.style.display = 'block';
    };

    const replyEditSuccess = (reply) => {
        const replyIndex = active_thread.thread.replies.findIndex( x => x.id.toString() === reply.id.toString() );
        const updatedThread = active_thread;
        updatedThread.thread.replies[replyIndex] = reply;
        const threadidx = commentsdata.findIndex(x => x.id.toString() === updatedThread.id.toString());
        const updatedCommentsData = commentsdata;
        updatedCommentsData[threadidx] = updatedThread;
        const otherReplyForms = document.getElementsByClassName('reply-form');
        for (let i = 0; i < otherReplyForms.length; i++) {
            if (!otherReplyForms[i].classList.contains('d-none')) {
                otherReplyForms[i].classList.toggle("d-none");
            }
        }
        setSubmitReply(false);
        dispatch(setActiveThread(updatedThread));
        dispatch(setCommentsData(updatedCommentsData));
        setSubmitReply(false);
        const commentp = document.getElementById('reply-comment-' + actionobj.id);
        const replybtn = document.querySelector('#reply-'+actionobj.id+' .comment-body .reply-btn');
        setActionObj(null);
        commentp.innerHTML = commenthtml;
        commentp.style.display = 'block';
        replybtn.style.display = 'block';
    };

    const replyDeleteSuccess = (reply_id) => {
        setActionObj(null);
        const removeIndex = active_thread.thread.replies.findIndex( reply => reply.id.toString() === reply_id.toString() );
        const updatedThread = active_thread;
        updatedThread.thread.replies.splice(removeIndex, 1);
        const threadidx = commentsdata.findIndex(x => x.id.toString() === updatedThread.id.toString());
        const updatedCommentsData = commentsdata;
        updatedCommentsData[threadidx] = updatedThread;
        dispatch(setActiveThread(updatedThread));
        dispatch(setCommentsData(updatedCommentsData));
    };

    const replyError = (error) => {
        setError(error);
    };
    const onReplySubmit = (e) => {
        const target = e.target;
        const parent_id = typeof target.dataset.target !== 'undefined' ? target.dataset.target : null;
        if (commenthtml.length) {
            setSubmitReply(true);
            if (actionobj !== null) {
                if (typeof actionobj.user !== 'undefined') {
                    //Save Thread comment changes
                    kwapi.editThreadComment(actionobj, commenthtml, submitSuccess, submitError);
                } else {
                    //Save reply changes
                    actionobj.comment = commenthtml;
                    kwapi.editThreadReply(actionobj, submitSuccess, submitError);
                }
            } else {
                kwapi.saveThreadReply({
                    thread_id: active_thread.id,
                    parent_id: parent_id,
                    comment: commenthtml
                }, submitSuccess, submitError);
            }
        }
    };

    const submitSuccess = (replyobj) => {
        if (actionobj !== null) {
            if (typeof actionobj.user !== 'undefined') {
                threadEditSuccess(replyobj);
            } else {
                replyEditSuccess(replyobj);
            }
        } else {
            const new_commentsdata = commentsdata;

            var dataindex = new_commentsdata.findIndex(obj => {
                return obj.id === active_thread.id
            });
            if (typeof dataindex !== 'undefined') {
                new_commentsdata[dataindex].thread.replies.push(replyobj);
                dispatch(setCommentsData(new_commentsdata));
            }
            const otherReplyForms = document.getElementsByClassName('reply-form');
            for (let i = 0; i < otherReplyForms.length; i++) {
                if (!otherReplyForms[i].classList.contains('d-none')) {
                    otherReplyForms[i].classList.toggle("d-none");
                }
            }
            setSubmitReply(false);
            setActiveReply('reply-' + replyobj.id + '-reply-form');
            scrollToReply();

            const commentsscroll = document.getElementById('comments-scroll');
            commentsscroll.style.marginTop = 0;
            setTimeout(() => {
                commentsscroll.style.marginTop = '30px';
            }, 100);
        }
    }
    const submitError = (error) => {
        setSubmitReply(false);
        setError(error);
    };
    const getSubReplies = (parent_id) => {

        const subreplies = active_thread.thread.replies.filter((x) => {
            return x.parent_id !== null && x.parent_id.toString() === parent_id.toString();
        });

        if (typeof subreplies !== 'undefined' && subreplies.length) {
            return (
                <div className="replies">
                    {subreplies.map((sr, s) => (getReply(sr, s)))}
                </div>
            );
        } else {
            return '';
        }
    }
    const getReply = (replyobj, key) => {
        if (typeof replyobj !== 'undefined') {
            const firstinit = replyobj.firstname.charAt(0);
            const lastinit = replyobj.lastname.charAt(0);
            const subreplies = getSubReplies(replyobj.id);
            return (
                <details ref={activereply !== null &&  activereply === 'reply-'+replyobj.id+'-reply-form' ? replyRef : null } key={key} open className="comment" id={'reply-'+replyobj.id}>
                    <a href={'#reply-'+replyobj.id} className="comment-border-link">
                        <span className="sr-only">{'Jump to reply-'+replyobj.id}</span>
                    </a>
                    <summary>
                        <div className="comment-heading">
                            <img src={'https://ui-avatars.com/api/name='+firstinit+lastinit+'&background=random&rounded=true'}
                                 alt="User Avatar"/>
                            <div className="comment-info">
                                <a className="comment-author" title={replyobj.email}>{replyobj.firstname+' '+replyobj.lastname}</a> {/* eslint-disable-line jsx-a11y/anchor-is-valid*/}
                                {(account.id.toString() === replyobj.user_id.toString() || user.isAdminAccount()) &&
                                <Fragment>
                                    {(account.id.toString() === replyobj.user_id.toString() || user.isAdminAccount()) &&
                                        <button id={'edit-btn-' + replyobj.id} type="button" className="edit-btn action-btn" title="Edit Comment" onClick={() => {toggleReplyEditForm(replyobj)}}><i className="dx-icon dx-icon-edit"/></button>
                                    }
                                    {(typeof subreplies === 'string' || user.isAdminAccount()) &&
                                        <button id={'delete-btn-' + replyobj.id} type="button" className="delete-btn action-btn" title="Delete Comment" onClick={() => {onReplyDelete(replyobj)}}><i className="dx-icon dx-icon-trash"/></button>
                                    }
                                </Fragment>
                                }
                                <p className="m-0">
                                    &bull; {moment(replyobj.updated_at).fromNow()}
                                </p>
                            </div>
                        </div>
                    </summary>

                    <div className="comment-body">
                        <p id={'reply-comment-'+replyobj.id} dangerouslySetInnerHTML={{__html: replyobj.comment}}/>
                        <span className="spacer-30"/>
                        <button type="button" className="reply-btn" onClick={toggleReplyForm} data-target={'reply-'+replyobj.id+'-reply-form'} disabled={submitreply}>
                            <i className="dx-icon dx-icon-reply"/>
                            Reply
                        </button>
                        <div className="reply-form d-none" id={'reply-'+replyobj.id+'-reply-form'}>
                            <TinyMce active={editoractive && activereply === 'reply-'+replyobj.id+'-reply-form'} setValue={setCommentHtml} value={commenthtml}/>
                            <button type="submit" className="comment-submit" data-target={replyobj.id} onClick={onReplySubmit} disabled={submitreply}>
                                Submit
                                {submitreply &&
                                    //eslint-disable-next-line jsx-a11y/alt-text
                                    <img src={kwapi.getCDNURL()+'assets/images/button-pending.gif'} style={{width: '10px', height: '10px', marginLeft: '4px', marginTop: '5px'}}/>
                                }
                            </button>
                            <button type="button" onClick={toggleReplyForm} data-toggle="reply-form" data-target={'reply-'+replyobj.id+'-reply-form'} disabled={submitreply}>Cancel</button>
                        </div>
                    </div>
                    {subreplies}
                </details>
            );
        } else {
            return '';
        }
    };

    const firstinit = active_thread !== null ? active_thread.user.firstname.charAt(0) : 'N';
    const lastinit = active_thread !== null ? active_thread.user.lastname.charAt(0) : 'A';

    const replies = active_thread.thread.replies.filter((x) => { return x.parent_id === null });

    return (
        <Fragment>
            <div className="comment-thread">
                <details ref={activereply !== null && activereply === 'comment-'+active_thread.id+'-reply-form' ? replyRef : null } open className="comment" id="comment-1">
                    <a href={'#comment-'+active_thread.id} className="comment-border-link">
                        <span className="sr-only">{'Jump to comment-'+active_thread.id}</span>
                    </a>
                    <summary>
                        <div className="comment-heading">
                            <img src={'https://ui-avatars.com/api/name='+firstinit+lastinit+'&background=random&rounded=true'} alt="User Avatar"/>
                            <div className="comment-info">
                                <a className="comment-author" title={active_thread.email}>{active_thread.firstname + ' ' + active_thread.lastname}</a> {/* eslint-disable-line jsx-a11y/anchor-is-valid*/}
                                {(account.id.toString() === active_thread.user.id.toString() || user.isAdminAccount()) &&
                                <Fragment>
                                    <button id={'edit-thread-btn-'+active_thread.id} type="button" className="edit-btn action-btn" title="Edit Comment" onClick={toggleThreadEditForm}><i className="dx-icon dx-icon-edit"/></button>
                                </Fragment>
                                }
                                <p className="m-0">
                                    &bull; {moment(active_thread.updated_at).fromNow()}
                                </p>
                            </div>
                        </div>
                    </summary>
                        <div className="comment-body">
                            <p id={'thread-comment-'+active_thread.id} dangerouslySetInnerHTML={{__html: active_thread.thread.comment}}/>
                            <span className="spacer-30"/>
                            <button type="button" className="reply-btn" onClick={toggleReplyForm} data-target={'comment-'+active_thread.id+'-reply-form'}>
                                <i className="dx-icon dx-icon-reply"/>
                                Reply
                            </button>

                            <div className="reply-form d-none" id={'comment-'+active_thread.id+'-reply-form'}>
                                <TinyMce active={editoractive && activereply === 'comment-'+active_thread.id+'-reply-form'} setValue={setCommentHtml} value={commenthtml}/>
                                <button type="submit" className="comment-submit" data-target={null} onClick={onReplySubmit} disabled={submitreply}>
                                    Submit
                                    {submitreply &&
                                        //eslint-disable-next-line jsx-a11y/alt-text
                                        <img src={kwapi.getCDNURL()+'assets/images/button-pending.gif'} style={{width: '10px', height: '10px', marginLeft: '4px', marginTop: '5px'}}/>
                                    }
                                </button>
                                <button type="button" onClick={toggleReplyForm} data-toggle="reply-form" data-target={'comment-'+active_thread.id+'-reply-form'} disabled={submitreply}>Cancel</button>
                            </div>
                        </div>
                        <div className="replies">
                            {replies.map((replyobj, i) => (
                                getReply(replyobj, i)
                            ))}
                        </div>
                </details>
            </div>
            {showconfirm &&
                <ConfirmPopup title={confirmtitle} message={confirmmsg} showConfirm={setShowConfirm} confirmCallback={doReplyDelete}/>
            }
        </Fragment>
    );
}