import axios from 'axios';

export const apibaseurl = 'https://api.mapbox.com/styles/v1/';
const terrainapiurl = 'https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/';


export const mbapi = {
    doAPICall: (calluri, method, data, callback, errorcallback, returnindex, errorcondition, returnresp) => {
        const cbisfunc = funchelper.isFunction(callback);
        const errorcbisfunc = funchelper.isFunction(errorcallback);
        /*data.apikey = requestkey();*/
        const apiserverurl = calluri.indexOf('tilequery') !== -1 ? terrainapiurl : apibaseurl;

        if (typeof returnresp === 'undefined') { returnresp = false; }

        axios.defaults.withCredentials = false;
        axios.defaults.crossDomain = true;
        let axiosconfig = {};

        if (method === 'post') {
            axios.post(apiserverurl + calluri, data, axiosconfig).then(function(res) {
                if (typeof res.data.error !== 'undefined') {
                    errorcbisfunc && errorcallback();
                } else if (typeof errorcondition !== 'undefined' && typeof res.data[errorcondition] !== 'undefined' && res.data[errorcondition]) {
                    errorcbisfunc && errorcallback(res.data);
                } else {
                    if (cbisfunc) {
                        if (typeof returnindex !== 'undefined') {
                            if (returnresp)  {
                                if (returnindex !== '*') {
                                    return callback(res.data[returnindex]);
                                } else {
                                    return callback(res.data);
                                }
                            }
                            else {
                                if (returnindex !== '*') {
                                    callback(res.data[returnindex]);
                                } else {
                                    callback(res.data);
                                }
                            }
                        } else {
                            if (returnresp) { return callback(res.data); }
                            else { callback(); }
                        }

                    }
                }
            }).catch(function (error) {
                if (typeof errorcondition === 'undefined' && ((typeof error[errorcondition] !== 'undefined' && !error[errorcondition]) || (typeof error[errorcondition] === 'undefined'))) {
                    errorhandler.default_error_handler();
                }
                if (errorcbisfunc && returnresp) {
                    console.error(error);
                    errorcbisfunc && errorcallback();
                } else {
                    errorcbisfunc && errorcallback(error);
                }

            });
        } else if(method === 'get') {
            axios.get(apiserverurl + calluri, axiosconfig).then(function(res) {
                if (typeof res.data.error !== 'undefined') {
                    errorcbisfunc && errorcallback(res.data.error);
                } else if (typeof errorcondition !== 'undefined' && typeof res.data[errorcondition] !== 'undefined' && res.data[errorcondition]) {
                    if (errorcbisfunc && returnresp) {
                        return errorcallback({error: 'errorcondition is true'});
                    } else {
                        errorcbisfunc && errorcallback({error: 'errorcondition is true'});
                    }
                } else {
                    if (cbisfunc && typeof returnindex !== 'undefined') {

                        if (returnresp)  {
                            if (returnindex !== '*') {
                                return callback(res.data[returnindex]);
                            } else {
                                return callback(res.data);
                            }
                        }
                        else {
                            if (returnindex !== '*') {
                                callback(res.data[returnindex]);
                            } else {
                                callback(res.data);
                            }
                        }

                    } else {
                        if (cbisfunc && returnresp) {
                            return callback(res.data);
                        } else {
                            cbisfunc && callback();
                        }
                    }
                }
            }).catch(function (error) {
                if (typeof errorcondition === 'undefined' && ((typeof error[errorcondition] !== 'undefined' && !error[errorcondition]) || (typeof error[errorcondition] === 'undefined'))) {
                    errorhandler.default_error_handler();
                }
                if (errorcbisfunc && returnresp) {
                    console.error(error);
                    errorcbisfunc && errorcallback();
                } else {
                    errorcbisfunc && errorcallback(error);
                }
            });
        } else {
            console.error('API method handler not defined');
            errorhandler.default_error_handler();
        }
    },
    getTerrainFeatures: (lng, lat, callback, errorcallback) => {
        const apiuri = 'tilequery/' + lng + ',' + lat + '.json?&access_token=pk.eyJ1IjoibXJnc3dpZnQiLCJhIjoiY2tpNDJ5cXowMXVpMDJycGRrZzJsaTJlYSJ9.IF-IOVlEu5gf7A2F75yoGA';
        mbapi.doAPICall(apiuri, 'get', undefined, callback, errorcallback, '*');
    }

}

const funchelper = {
    isFunction: (func) => {
        const getType = {};
        return func && getType.toString.call(func) === '[object Function]';
    }
};

const errorhandler = {
    default_error_handler: (error, errormodal, errorattrs) => {
        /*let modal_callback;
        switch (errormodal) {
            case 'custom':
                modal_callback = errorhandler.custom_error_modal;
                break;
            default:
                modal_callback = errorhandler.default_error_modal;
        }
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && parseInt(error.response.status) === 401) {
            user.checkLoginExpiration(modal_callback, errorattrs, true);
        } else if (typeof error !== 'undefined' && error.toString().indexOf('Request failed with status code 401') !== -1) {
            user.checkLoginExpiration(modal_callback, errorattrs, true);
        } else {
            typeof errorattrs !== 'undefined' ? modal_callback(errorattrs) : modal_callback();
        }*/
    },
    default_error_modal: () => {
        /*if ($.isFunction($.fn.dialog)) {
            $('#errordialog').dialog({
                modal: true,
                resizable: false,
                width: 'auto',
                height: 'auto',
                buttons: {
                    Ok: function () {
                        $(this).dialog("close");
                    }
                },
                open: function (event, ui) {
                    $(".ui-dialog-titlebar-close", ui.dialog | ui).hide();
                    $(".ui-dialog-buttonset button").addClass('btn btn-md btn-warning');
                }
            });
        } else {
            setTimeout(() => {
                errorhandler.default_error_modal();
            }, 300)
        }*/
    },
    custom_error_modal: (errorattrs) => {
        /*if (typeof errorattrs !== 'undefined' && errorattrs.length === 2) {
            const errordlg = $('#errordialog');
            errordlg.attr('title', errorattrs[0]);
            $('#errordialog p:first').text(errorattrs[1]);
            errorhandler.default_error_modal();
        }*/
    }
};