import defaultUser from '../utils/default-user';
import {kwapi, user} from './kwapi';

export async function signIn(email, password) {
  try {
    // Send request
    //console.log(email, password);

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {

  try {
    // Send request

    if (user.isLoggedIn()) {
      const userData = user.getUserSession();
      //@TODO Allow user to upload their own avatar image
      userData.avatarUrl = kwapi.getCDNURL()+'assets/images/user-icon.png';
      kwapi.getClientAccount();
      user.setJustSignedIn(true);
      return {
        isOk: true,
        data: userData
      };
    }
    if (kwapi.environment() === 'development') {
      return {
        isOk: true,
        data: defaultUser
      };
    }
    const url = getLocation(window.location.href);
    url.pathname.indexOf('surveys/map') === -1 && sessionStorage.setItem('KWAccessPage', url.pathname);
    throw 'No user session found!'; //eslint-disable-line no-throw-literal
  }
  catch {
      return {
        isOk: false
      };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    //console.log(email, password);

    return {
      isOk: true
    };
  }
  catch { //eslint-disable-line no-unreachable
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    //console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch { //eslint-disable-line no-unreachable
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    //console.log(email);

    return {
      isOk: true
    };
  }
  catch { //eslint-disable-line no-unreachable
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

const getLocation = function(href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
};
