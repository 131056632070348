import React, {useEffect, useState} from "react";
import PT from "prop-types";
import {
    LightgalleryProvider,
    LightgalleryItem,
    /*withLightgallery,
    useLightgallery*/
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import "./gallery.scss";

const PhotoItem = ({ image, thumb, group }) => (
    <div className="column">
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} style={{ width: "100%" }} alt="Gallery Item"/>
        </LightgalleryItem>
    </div>
);
PhotoItem.propTypes = {
    image: PT.string.isRequired,
    thumb: PT.string,
    group: PT.string.isRequired
};

/*const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
    return (
        <button
            {...props}
            onClick={() => {
                openGallery("group1");
            }}
            className={["button is-primary", props.className || ""].join(" ")}
        >
            Open first photos group (using hoc)
        </button>
    );
});

const OpenButtonWithHook = props => {
    const { openGallery } = useLightgallery();
    return (
        <button
            {...props}
            onClick={() => openGallery("group2")}
            className={["button is-primary", props.className || ""].join(" ")}
        >
            Open second photos group (using hook)
        </button>
    );
};
OpenButtonWithHook.propTypes = {
    className: PT.string
};*/

export default ({photos}) => {
    const [visible, setVisible] = useState(true); //eslint-disable-line no-unused-vars
    // Initialize map when component mounts
    useEffect(() => {



    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="content">
            <div>
                {visible ? (
                    <LightgalleryProvider
                        lightgallerySettings={{ download: false }}
                        /*onBeforeOpen={() => console.info("onBeforeOpen")}
                        onAfterOpen={() => console.info("onAfterOpen")}
                        onSlideItemLoad={() => console.info("onSlideItemLoad")}
                        onBeforeSlide={() => console.info("onBeforeSlide")}
                        onAfterSlide={() => console.info("onAfterSlide")}
                        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                        onDragstart={() => console.info("onDragstart")}
                        onDragmove={() => console.info("onDragmove")}
                        onDragend={() => console.info("onDragend")}
                        onSlideClick={() => console.info("onSlideClick")}
                        onBeforeClose={() => console.info("onBeforeClose")}
                        onCloseAfter={() => console.info("onCloseAfter")}*/
                    >
                        <div className="gallery-thumb">
                            {photos.map((p, idx) => (
                                <PhotoItem key={idx} image={p} group="marker-photos"/>
                            ))}
                        </div>
                    </LightgalleryProvider>
                ) : null}
            </div>
        </div>
    );
}