import React, { Fragment } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';

export default function TinyMce({active, value, setValue}) {
    const apiKey = 'p323iuq57ipbuymv4xp69qmsad4pgkgoztt0tzbvemrkbmk1';

    const scrollview = useSelector((state) => state.scrollview);

    return (
        <Fragment>
        {
            active ?
                <Editor
                    apiKey={apiKey}
                    init={{
                        plugins: 'link',
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        force_br_newlines : false,
                        force_p_newlines : false,
                        forced_root_block : '',
                        content_style:
                            "@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap'); body { font-family: Raleway; font-size: 13px}",
                        width: '100%',
                        setup: function(ed) {
                            ed.on('init', function() {
                                const iframe = document.querySelector('.tox-edit-area__iframe');
                                iframe.contentWindow.addEventListener('wheel', function(e) {
                                    if (scrollview !== null) {
                                        e.preventDefault();
                                        scrollview.scrollBy(e.deltaY);
                                    }
                                }, { passive: false });
                            });
                        }
                    }}
                    value={value}
                    toolbar={'bold italic underline link'}
                    onEditorChange={(newValue, editor) => setValue(newValue)}
                    link={{
                        addTargetToExternalLinks: true
                    }}
                />
                :
                ''
        }
        </Fragment>
    );
}