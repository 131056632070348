import React, {Fragment, useState} from 'react';
import ReactPlayer from "react-player"

const MT_Video = ({step, basictutorial, title, captions, timeIndexes, vimeoURL}) => {
    const [caption, setCaption] = useState(captions[0]);
    const [timeindex, setTimeindex] = useState(0);
    const [captionBackground, setCaptionBackground] = useState(false);

    const videoProgress = (progress) => {
        if (typeof timeIndexes[timeindex + 1] !== 'undefined' && progress.playedSeconds >= timeIndexes[timeindex + 1]) {
            setCaption(captions[timeindex + 1]);
            setTimeindex(timeindex + 1)
            setCaptionBackground(true);
            setTimeout(() => {
                setCaptionBackground(false);
            }, 800);
        }
    };

    const backgroundCaption = captionBackground ? '#fff7e2' : 'none';
    const videoWidth = basictutorial ? '890px' : '1180px';
    const videoHeight = basictutorial ? '495px' : '480px';
    const leftMargin = basictutorial ? '-22px' : '-166px';
    const captionTopMargin = step === '1d' ? '-10px' : 0;

    return (
        <Fragment>
            <span style={{fontSize: '19px'}}>{title}</span>
            <br/>
            <ReactPlayer
                url={vimeoURL}
                width={videoWidth}
                height={videoHeight}
                style={{marginLeft: leftMargin}}
                onProgress={videoProgress}
                config={{
                    vimeo: {
                        playerOptions: {
                            autoplay: true,
                            controls: false,
                            color: 'D6001C'
                        }
                    },
                }}
            />
            <p style={{fontSize: '15px', marginTop: captionTopMargin, paddingTop: '7px', paddingBottom: '7px', background: backgroundCaption}}><strong>{caption}</strong></p>
        </Fragment>
    )
};
export default MT_Video;