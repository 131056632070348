import React from 'react';
import MT_Video from "./MT_Video";

const MT_Step6 = ({step}) => {

    const captions = {
        0: 'The Point-to-point distance tool can be found in the top toolbar.',
        1: 'Once activated, click on the map to mark the points you want to measure between.',
        2: 'You can measure between more then two points if desired.',
        3: 'Double-click on the last point to get the distance measurement.',
        4: 'The trash can icon removes/resets any measurement point and lines that you have on the map.',
    }

    const timeIndexes = [0, 4.0, 10.2, 18.0, 22.0]

    return <MT_Video step={step} basictutorial={false} title="Using the Point to point distance tool" captions={captions} timeIndexes={timeIndexes} vimeoURL="https://vimeo.com/698024196"/>
}
export default MT_Step6;