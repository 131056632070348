export const navigation = [
  {
    text: 'Home',
    path: '/surveys/',
    icon: 'home'
  },
  {
    text: 'Maps',
    path: '/surveys/maps/',
    icon: 'event'
  },
  {
    text: 'Dashboard',
    path: '/surveys/dashboard/',
    icon: 'event'
  }
/*  {
    text: 'Examples',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tasks',
        path: '/tasks'
      },
      {
        text: 'Appointments',
        path: '/appointments'
      }
    ]
  }*/
  ];
