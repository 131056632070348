import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, Position } from 'devextreme-react/popup';
import {Button} from "devextreme-react/button";
import {setShowMapTutorial} from "../../api/actions/actions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MT_Intro from "./MT_Intro";
import MT_Step1 from "./MT_Step1";
import MT_Step2 from "./MT_Step2";
import MT_Step3 from "./MT_Step3";
import MT_Step4 from "./MT_Step4";
import MT_Step5 from "./MT_Step5";
import MT_Step6 from "./MT_Step6";
import MT_Step7 from "./MT_Step7";
import MT_Step8 from "./MT_Step8";
import MT_Step9 from "./MT_Step9";
import MT_Step10 from "./MT_Step10";
import MT_Step11 from "./MT_Step11";
import MT_Done from "./MT_Done";
import './maptutorial.scss';

const MapTutorial = () => {
    const dispatch = useDispatch();
    const popupvisible = useSelector((state) => state.showmaptutorial);
    let [popupdismiss, setPopupDismiss] = useState(localStorage.getItem('map-tutorial-dismiss') === '1');
    const [step, setStep] = useState('intro');
    const [basicTutorial, setBasicTutorial] = useState(false);

    // Initialize when component mounts
    useEffect(() => {

        return onUnmount();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onUnmount = () => {

    };

    const dismissPopup = () => {
        if (!popupdismiss === false) {
            localStorage.setItem('map-tutorial-dismiss', 0);
        } else {
            localStorage.setItem('map-tutorial-dismiss', 1);
        }
        setPopupDismiss(!popupdismiss);
    }

    const togglePopup = () => {
        dispatch(setShowMapTutorial(!popupvisible));
    };

    const getStepContent = () => {
        if (step === 'intro') return <MT_Intro popupdismiss={popupdismiss} dismissPopup={dismissPopup}/>;
        else if (step === 1) return <MT_Step1 step={step}/>;
        else if (step === 2) return <MT_Step2 step={step}/>;
        else if (step === 3) return <MT_Step3 step={step}/>;
        else if (step === 4) return <MT_Step4 step={step}/>;
        else if (step === 5) return <MT_Step5 step={step}/>;
        else if (step === 6) return <MT_Step6 step={step}/>;
        else if (step === 7) return <MT_Step7 step={step}/>;
        else if (step === 8) return <MT_Step8 step={step}/>;
        else if (step === 9) return <MT_Step9 step={step}/>;
        else if (step === 10) return <MT_Step10 step={step}/>;
        else if (step === 11) return <MT_Step11 step={step}/>;
        else return <MT_Done/>;
    };

    const nextStep = () => {
        if (step === 'intro') {
            setBasicTutorial(true);
            setStep(1);
        } else if (step <= 5) {
            setBasicTutorial(true);
            setStep(step + 1);
        } else if (step > 5 && step < 9) {
            setBasicTutorial(false);
            setStep(step + 1);
        } else if (step >= 9 && step <= 11) {
            setBasicTutorial(true);
            setStep(step + 1);
        } else {
            togglePopup();
        }
    }

    const lastStep = () => {
        if (parseInt(step) && step - 1 > 0) {
            setStep(step - 1);
        } else {
            setStep('intro');
        }
    }

    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars

    //Dynamic popup dimensions based on screen size

    let vidpopupWidth, vidpopupHeight;
    let tutorialShowable = true;
    if (width >= 890) {
        vidpopupWidth = 890;
        vidpopupHeight = 700;
    } else {
        tutorialShowable = false;
    }

    const buttontxt = (step === 'intro' || basicTutorial || parseInt(step) < 11) ? 'Next' : 'Done';
    const nextBtnClass = step === 'intro' ? 'intro-next' : '';
    const contentStyle = basicTutorial ? {marginBottom: '-20px'} : {};
    return (
        <Popup
            visible={popupvisible && tutorialShowable}
            onHiding={togglePopup}
            dragEnabled={true}
            resizeEnabled={true}
            closeOnOutsideClick={false}
            shading={false}
            showCloseButton={true}
            showTitle={true}
            className="tutorial-popup"
            title="Map Tutorial"
            defaultHeight={vidpopupHeight}
            defaultWidth={vidpopupWidth}
            maxWidth={1080}
            maxHeight={720}

        >
            <div className="tutorial-content" style={contentStyle}>
                {getStepContent()}
            </div>
            <br/>
            <div className="tutorial-btns">
                {step !== 'intro' &&
                    <Button
                    width={120}
                    text="Back"
                    type="normal"
                    stylingMode="outlined"
                    onClick={lastStep}
                    />
                }
                <Button
                    width={120}
                    text={buttontxt}
                    type="normal"
                    className={nextBtnClass}
                    stylingMode="outlined"
                    onClick={nextStep}
                />
            </div>
        </Popup>
    );
};
export default MapTutorial;