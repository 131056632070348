import React, {useRef} from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from '../../components/google/GoogleMap'
//import store from '../../api/store/store';

const handleApiLoaded = (map, maps) => {
    // use map and maps objects
};

const GMap = () => {
    const defaultProps = {
        mapId: '1hQMaLAE4xNQ7EEhcUXArLcb9j82noUtk',
        center: {
            lat: 32.93459551653693,
            lng: -100.96725405154902
        },
        zoom: 14
    };

    return (
        <Wrapper apiKey={"AIzaSyDrPXwLpdhf37Zd5Zicex_n7fEF1aSzgq0"}>
            <GoogleMap mapId={defaultProps.mapId} center={defaultProps.center} zoom={defaultProps.zoom}/>
        </Wrapper>
    );
}
export default GMap;