export const public_url = {
    //@TODO make this more flexible

    validate: function() {
        const uriarr = window.location.pathname.split('/');

        if (uriarr.length && typeof uriarr[1] !== 'undefined' && uriarr[1] === 'surveys') {
            if (typeof uriarr[2] !== 'undefined' && uriarr[2] === 'map') {
                if (typeof uriarr[3] !== 'undefined' && uriarr[3].length) {
                    return true;
                }
            }
        }
        return false;
    }
};