import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux'
import { Tooltip } from 'devextreme-react/tooltip';

export default ({}) => {
    const showlinetoolip = useSelector((state) => state.showlinetoolip);
    const showpolygontoolip = useSelector((state) => state.showpolygontoolip);
    const showspotelevtooltip = useSelector((state) => state.showspotelevtooltip);
    const showelevcontrstooltip = useSelector((state) => state.showelevcontrstooltip);
    const showprinttooltip = useSelector((state) => state.showprinttooltip);
    const showcommentstooltip = useSelector((state) => state.showcommentstooltip);
    const showviewstooltip = useSelector((state) => state.showviewstooltip);
    const showmarkerstooltip = useSelector((state) => state.showmarkerstooltip);
    const [showTooltip, setShowTooltip] = useState(false);
    const [target, setTarget] = useState(null);
    const [tooltipText, setTooltipText] = useState('');


    useEffect(() => {
        if (!showlinetoolip) {
            setShowTooltip(false);
        } else {
            setTarget('#measure-tool-btn');
            setTooltipText('Point to Point Distance Tool');
            setShowTooltip(true);
        }
    }, [showlinetoolip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showpolygontoolip) {
            setShowTooltip(false);
        } else {
            setTarget('#area-tool-btn');
            setTooltipText('Polygon Area Tool');
            setShowTooltip(true);
        }
    }, [showpolygontoolip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showspotelevtooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#spot-elevation-tool-btn');
            setTooltipText('Spot Elevation Tool');
            setShowTooltip(true);
        }
    }, [showspotelevtooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showelevcontrstooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#contours-tool-btn');
            setTooltipText('Elevation Contours Tool');
            setShowTooltip(true);
        }
    }, [showelevcontrstooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showprinttooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#print-tool-btn');
            setTooltipText('Print map to PDF');
            setShowTooltip(true);
        }
    }, [showprinttooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showcommentstooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#comments-tool-btn');
            setTooltipText('Show Comments');
            setShowTooltip(true);
        }
    }, [showcommentstooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showviewstooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#views-tool-btn');
            setTooltipText('Show Map Views');
            setShowTooltip(true);
        }
    }, [showviewstooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showmarkerstooltip) {
            setShowTooltip(false);
        } else {
            setTarget('#markers-tool-btn');
            setTooltipText('Show List of Map Markers');
            setShowTooltip(true);
        }
    }, [showmarkerstooltip]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tooltip
            target={target}
            position="bottom"
            visible={showTooltip}
            closeOnOutsideClick={false}
        >
            <span>{tooltipText}</span>
        </Tooltip>
    )
};