import React, {Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Popup, Position } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import {kwapi} from "../../api/kwapi";
import TinyMce from "../../components/tinymce/TinyMce";
import {
    setViewsData,
    setSelectedView,
    setShowViewsPopup,
    setToastConfig,
    setToastVisible
} from "../../api/actions/actions";

const ViewPopup = ({visible}) => {
    const dispatch = useDispatch();
    const selectedView = useSelector((state) => state.selectedview);
    const viewsData = useSelector((state) => state.viewsdata);
    const currentMapView = useSelector((state) => state.currentview);

    const toastConfig = {
        type: 'info',
        message: ''
    };

    const [editorActive, setEditorActive] = useState(false);


    // Initialize when component mounts
    useEffect(() => {
        setEditorActive(true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onFlashHiding = () => {
        dispatch(setToastVisible(false));
    };

    const saveSuccess = (views) => {
        const new_viewsdata = viewsData;
        dispatch(setViewsData(null));
        new_viewsdata.push(views);
        dispatch(setViewsData(new_viewsdata));

        dispatch(setToastConfig({
            ...toastConfig,
            type: 'success',
            message: 'Map View saved!',
            onHiding: onFlashHiding,
            time: 2000
        }));
        dispatch(setToastVisible(true));
        hidePopup();
    };

    const saveError = () => {
        dispatch(setToastConfig({
            ...toastConfig,
            type: 'error',
            message: 'Unable to save Map View!',
            onHiding: onFlashHiding,
            time: 2000
        }));
        dispatch(setToastVisible(true));
    }

    const hidePopup = () => {
        setEditorActive(false);
        dispatch(setShowViewsPopup(false));
    };

    const setViewName = (e) => {
        const target = e.target;
        const viewname = target.value;
        const new_viewData = selectedView !== null ? selectedView : {};
        new_viewData.name = viewname;
        dispatch(setSelectedView(new_viewData));
    };

    const setViewDesc = (desc) => {
        const new_viewData = selectedView !== null ? selectedView : {};
        new_viewData.description = desc;
        dispatch(setSelectedView(new_viewData));
    };

    const handleSave = () => {
        const formdata = selectedView;
        let formvalid = true;
        if (formdata.name === null) {
            formvalid = false;
        }
        formdata.bearing = typeof selectedView.bearing !== 'undefined' ? selectedView.bearing : currentMapView.bearing;
        formdata.pitch = typeof selectedView.pitch !== 'undefined' ?  selectedView.pitch : currentMapView.pitch;
        formdata.center = typeof selectedView.center !== 'undefined' ? selectedView.center : currentMapView.center;
        formdata.zoom = typeof selectedView.zoom !== 'undefined' ? selectedView.zoom : currentMapView.zoom;
        formvalid && kwapi.saveMapView(formdata, saveSuccess, saveError);
    };

    return (
        <Fragment>
            <Popup
                visible={visible}
                onHiding={hidePopup}
                resizeEnabled={true}
                dragEnabled={true}
                focusStateEnabled={true}
                closeOnOutsideClick={false}
                showCloseButton={true}
                shading={false}
                showTitle={true}
                title={'New Map View'}
                container=".dx-viewport"
                defaultWidth={430}
                defaultHeight={500}
                minWidth={260}
                maxWidth={600}
                minHeight={270}
                maxHeight={500}
                className='new-thread-popup'
            >
                <Position
                    at="center"
                    my="right"
                    of={'#new-thread-marker'}
                    offset="-90 50"
                />
                <div id="form" className="dx-form dx-widget dx-visibility-change-handler" role="form">
                    <div className="dx-layout-manager dx-widget">
                        <div className="dx-widget dx-collection dx-responsivebox-screen-sm dx-responsivebox" style={{width: '100%', height: '100%'}}>
                            <div className="dx-box-flex dx-box dx-widget dx-visibility-change-handler dx-collection" style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'stretch'}}>
                                <div id="name-field" className="dx-item dx-box-item"
                                     style={{display: 'flex', minHeight: 'auto', flexGrow: 1, flexShrink: 1}}>
                                    <div
                                        className="dx-item-content dx-box-item-content dx-box-flex dx-box dx-widget dx-visibility-change-handler dx-collection"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'stretch',
                                            width: 'auto',
                                            height: 'auto',
                                            flexBasis: '0px',
                                            flexGrow: 1,
                                            marginLeft: '12px',
                                        }}>
                                        <div className="dx-item dx-box-item"
                                             style={{display: 'flex', minWidth: '0px', flex: '1 1 0px'}}>
                                            <div className="dx-item-content dx-box-item-content dx-root-simple-item"
                                                 style={{
                                                     width: 'auto',
                                                     height: 'auto',
                                                     display: 'flex',
                                                     flexBasis: '0px',
                                                     flexGrow: 1,
                                                     flexDirection: 'column'
                                                 }}>
                                                <div
                                                    className="dx-first-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align">
                                                    <label
                                                        className="dx-field-item-label dx-field-item-label-location-top"
                                                        htmlFor="cm-coordinates">
                                                    <span className="dx-field-item-label-content">
                                                        <span className="dx-field-item-label-text">View Name:</span>
                                                    </span>
                                                    </label>
                                                    <div
                                                        className="dx-field-item-content dx-field-item-content-location-bottom">
                                                        <div
                                                            className="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-outlined dx-widget">
                                                            <div className="dx-texteditor-container">
                                                                <div className="dx-texteditor-input-container">
                                                                    {
                                                                        // eslint-disable-next-line jsx-a11y/no-redundant-roles
                                                                    }<input
                                                                    autoComplete="off"
                                                                    id="view-name"
                                                                    className="dx-texteditor-input" type="text"
                                                                    spellCheck="false" name="name"
                                                                    tabIndex="0"
                                                                    role="textbox"
                                                                    aria-required="false"
                                                                    value={selectedView !== null ? selectedView.name : ''}
                                                                    onChange={setViewName}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="comments-field" className="dx-item dx-box-item" style={{display: 'flex', minHeight: 'auto', flexGrow: 1, flexShrink: 1}}>
                                    <div className="dx-first-row dx-first-col dx-field-item dx-col-0 dx-field-item-optional dx-flex-layout dx-label-v-align" style={{width: '100% !important'}}>
                                        <label className="dx-field-item-label dx-field-item-label-location-top"><span
                                            className="dx-field-item-label-content"><span
                                            className="dx-field-item-label-text">Description:</span></span></label>
                                        <div className="dx-field-item-content dx-field-item-content-location-bottom">
                                            <div className="dx-show-invalid-badge dx-numberbox dx-texteditor dx-editor-outlined dx-widget">
                                                <div className="dx-texteditor-container">
                                                    <div className="dx-texteditor-input-container">
                                                        <TinyMce active={editorActive} value={selectedView !== null ? selectedView.description : ''} setValue={setViewDesc}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <Button
                                        id="save-view"
                                        width={100}
                                        text="Save"
                                        type="success"
                                        stylingMode="contained"
                                        onClick={handleSave}
                                    />
                                    <Button
                                        id="cancel-view"
                                        width={100}
                                        text="Cancel"
                                        type="normal"
                                        stylingMode="contained"
                                        onClick={hidePopup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </Fragment>
    );
}
export default ViewPopup;