import React, {Fragment, useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {user, kwapi} from '../../api/kwapi';
import Toolbar, {Item} from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/user-panel";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
    setCommentsTool as setGlobalCommentsTool,
    setShowMarkersList,
    setSharePopupVisible,
    setShowElevcontrsTooltip,
    /*setShowSpotelevTooltip,*/
    setShowCommentsTooltip,
    setShowPolygonTooltip,
    setShowLineTooltip,
    setShowPrintTooltip,
    setShowViewsTooltip,
    setShowSpotelevTooltip,
    setShowMarkersTooltip
} from "../../api/actions/actions";
import {Template} from "devextreme-react/core/template";
import ToolNavTooltip from "./ToolNavTooltip";
import {MeasureTool as DistanceTool} from "./MeasureTool";
import {PolygonAreaTool} from "./PolygonAreaTool";
/*import {SpotElevationTool} from "./SpotElevationTool";*/
import {CoordinatesTool} from "./CoordinatesTool";
import {ContoursTool} from "./ContoursTool";
import {CommentsTool} from "./CommentsTool";
import {MapViewsTool} from "./MapViewsTool";
import {MapMarkersTool} from "./MapMarkersTool";


export default ({title, menuToggleEnabled, menuExpanded, toggleMenu, setPrintMapVisible, setPopoverTarget}) => {

    const dispatch = useDispatch();
    const comments_tool = useSelector((state) => state.comments_tool);
    const views_tool = useSelector((state) => state.views_tool);
    const showmarkerslist = useSelector((state) => state.showmarkerslist);
    const map = useSelector((state) => state.map);
    const [printenabled, setPrintEnabled] = useState(true);
    const [distancetool, setDistanceTool] = useState(false);
    const [polygonareatool, setPolygonAreaTool] = useState(false);
    /*const [spotelevationtool, setSpotElevationTool] = useState(false);*/
    const [coordinatestool, setCoordinatesTool] = useState(false);
    const [contourstool, setContoursTool] = useState(false);
    const [commentstool, setCommentsTool] = useState(false);
    const [viewstool, setViewsTool] = useState(false);
    const [markerstool, setMarkersTool] = useState(false);
    //const [threeDterrain, setThreeDterrain] = useState(false);
    const [trashbtnclass, setTrashBtnClass] = useState('btn-mapbox-trash');
    const [sharebtnclass, setShareBtnClass] = useState('btn-share-map');
    const [sharebtntext, setShareBtnText] = useState(null);
    const [companyname, setCompanyName] = useState('');
    const [clientlogo, setClientLogo] = useState(kwapi.getCDNURL() + "assets/images/k+w_single_logo.png");

    useEffect(() => {
        if (window.screen.width <= 640) {
            setPrintEnabled(false);
            setShareBtnClass('btn-share-map btn-share-mobile');
            setShareBtnText(null);
        } else {
            setPrintEnabled(true);
            setShareBtnClass('btn-share-map');
            setShareBtnText('Share Map');
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!comments_tool) {
            commentsButtonOptions.destroy();
        }
    }, [comments_tool]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (views_tool && !MapViewsTool.hasInstance()) {
            viewsButtonOptions.create();
        } else if (!views_tool) {
            viewsButtonOptions.destroy();
        }
    }, [views_tool]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!showmarkerslist) {
            markersButtonOptions.destroy();
        }
    }, [showmarkerslist]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (map.Company !== null) {
            setCompanyName(map.Company);
            map.LogoURL !== null && setClientLogo(kwapi.getMediaURL()+map.LogoURL);
        } else {
            if (user.isLoggedIn()) {
                setCompanyName(kwapi.getClientData().name);
                kwapi.getClientData().logo_url !== null && setClientLogo(kwapi.getMediaURL()+kwapi.getClientData().logo_url);
            }
        }
    }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

    const env = kwapi.environment();

    const toolButtonHover = (e) => {
        const btnTooltip = {
            measuretoolbtn_setter: setShowLineTooltip,
            areatoolbtn_setter: setShowPolygonTooltip,
            spotelevationtoolbtn_setter: setShowSpotelevTooltip,
            contourstoolbtn_setter: setShowElevcontrsTooltip,
            commentstoolbtn_setter: setShowCommentsTooltip,
            printtoolbtn_setter: setShowPrintTooltip,
            viewstoolbtn_setter: setShowViewsTooltip,
            markerstoolbtn_setter: setShowMarkersTooltip
        }
        const tooltip_setter = e.target.id.replaceAll('-', '') + '_setter';
        if (e.type === 'mouseenter' && typeof btnTooltip[tooltip_setter] !== undefined) {
            // Commands to execute when the pointer enters the item
            dispatch(btnTooltip[tooltip_setter](true));
        } else if (e.type === 'mouseleave' && typeof btnTooltip[tooltip_setter] !== undefined) {
            // Commands to execute when the pointer leaves the item
            dispatch(btnTooltip[tooltip_setter](false));
        }
    };

    const handleToolButtonHover = (e) => {
        if (!e.element.classList.contains('btn-initialized')) {
            e.element.addEventListener('mouseenter', (e) => {
                toolButtonHover(e);
            });
            e.element.addEventListener('mouseleave', (e) => {
                toolButtonHover(e);
            });
            e.element.classList.add('btn-initialized');
        }
    }

    const distanceButtonOptions = {
        icon: 'measuretool',
        cssClass: distancetool ? 'btn-measure-tool btn-active' : 'btn-measure-tool',
        elementAttr: { id: 'measure-tool-btn' },
        create: () => {
            const measuretool = DistanceTool.getInstance();
            setDistanceTool(true);
            measuretool.create(distanceButtonOptions.destroy);
            setPopoverTarget('#measure-tool-btn');
            setTrashBtnClass('btn-mapbox-trash trash-visible');
        },
        destroy: () => {
            const measuretool = DistanceTool.getInstance();
            setDistanceTool(false);
            measuretool.destroy();
            setPopoverTarget('');
            setTrashBtnClass('btn-mapbox-trash');
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            disableOtherTools('distancetool')
            if (!distancetool) {
                distanceButtonOptions.create();
            } else {
                distanceButtonOptions.destroy();
            }
        }
    };

    const polygonAreaButtonOptions = {
        icon: 'polygonareatool',
        cssClass: polygonareatool ? 'btn-polygonarea-tool btn-active' : 'btn-polygonarea-tool',
        elementAttr: { id: 'area-tool-btn' },
        create: () => {
            const polyareatool = PolygonAreaTool.getInstance();
            setPolygonAreaTool(true);
            polyareatool.create(polygonAreaButtonOptions.destroy);
            setPopoverTarget('#area-tool-btn');
            setTrashBtnClass('btn-mapbox-trash trash-visible');
        },
        destroy: () => {
            const polyareatool = PolygonAreaTool.getInstance();
            setPolygonAreaTool(false);
            polyareatool.destroy();
            setTrashBtnClass('btn-mapbox-trash');
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            disableOtherTools('polygonareatool')
            if (!polygonareatool) {
                polygonAreaButtonOptions.create();
            } else {
                polygonAreaButtonOptions.destroy();
            }
        }
    };

    /*const spotElevationButtonOptions = {
        icon: 'spotelevationtool',
        cssClass: spotelevationtool ? 'btn-spotelevation-tool btn-active' : 'btn-spotelevation-tool',
        elementAttr: { id: 'spot-elevation-tool-btn' },
        create: () => {
            const elevationtool = SpotElevationTool.getInstance();
            setSpotElevationTool(true);
            elevationtool.create();
            setPopoverTarget('#spot-elevation-tool-btn');
        },
        destroy: () => {
            const elevationtool = SpotElevationTool.getInstance();
            setSpotElevationTool(false);
            elevationtool.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            disableOtherTools('spotelevationtool')
            if (!spotelevationtool) {
                spotElevationButtonOptions.create();
            } else {
                spotElevationButtonOptions.destroy();
            }
        }
    };*/

    const coordinatesButtonOptions = {
        icon: 'coordinatestool',
        cssClass: coordinatestool ? 'btn-coordinates-tool btn-active' : 'btn-coordinates-tool',
        elementAttr: { id: 'coordinates-tool-btn' },
        create: () => {
            const coordstool = CoordinatesTool.getInstance();
            setCoordinatesTool(true);
            coordstool.create();
        },
        destroy: () => {
            const coordstool = CoordinatesTool.getInstance();
            setCoordinatesTool(false);
            coordstool.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            disableOtherTools('coordinatestool')
            if (!coordinatestool) {
                coordinatesButtonOptions.create();
            } else {
                coordinatesButtonOptions.destroy();
            }
        }
    };

    const contoursButtonOptions = {
        icon: 'contourstool',
        cssClass: contourstool ? 'btn-contours-tool btn-active' : 'btn-contours-tool',
        elementAttr: { id: 'contours-tool-btn' },
        create: () => {
            const contours = ContoursTool.getInstance();
            setContoursTool(true);
            contours.create();
        },
        destroy: () => {
            const contours = ContoursTool.getInstance();
            setContoursTool(false);
            contours.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            if (!contourstool) {
                contoursButtonOptions.create();
            } else {
                contoursButtonOptions.destroy()
            }
        }
    };
    const commentsButtonOptions = {
        icon: 'commentstool',
        cssClass: commentstool ? 'btn-comments-tool btn-active' : 'btn-comments-tool',
        elementAttr: { id: 'comments-tool-btn' },
        create: () => {
            const comments = CommentsTool.getInstance();
            dispatch(setGlobalCommentsTool(true));
            setCommentsTool(true);
            comments.create();
        },
        destroy: () => {
            const comments = CommentsTool.getInstance();
            dispatch(setGlobalCommentsTool(false));
            setCommentsTool(false);
            comments.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            if (!commentstool) {
                commentsButtonOptions.create();
            } else {
                commentsButtonOptions.destroy()
            }
        }
    };

    const viewsButtonOptions = {
        icon: 'viewstool',
        cssClass: viewstool ? 'btn-views-tool btn-active' : 'btn-views-tool',
        elementAttr: { id: 'views-tool-btn' },
        create: () => {
            const views = MapViewsTool.getInstance();
            setViewsTool(true);
            views.create();
        },
        destroy: () => {
            const views = MapViewsTool.getInstance();
            setViewsTool(false);
            views.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            if (!viewstool) {
                viewsButtonOptions.create();
            } else {
                viewsButtonOptions.destroy()
            }
        }
    };

    const markersButtonOptions = {
        icon: 'markerstool',
        cssClass: markerstool ? 'btn-markers-tool btn-active' : 'btn-markers-tool',
        elementAttr: { id: 'markers-tool-btn' },
        create: () => {
            const markerslist = MapMarkersTool.getInstance();
            setMarkersTool(true);
            markerslist.create();
        },
        destroy: () => {
            const markerslist = MapMarkersTool.getInstance();
            setMarkersTool(false);
            markerslist.destroy();
        },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            if (!markerstool) {
                markersButtonOptions.create();
            } else {
                markersButtonOptions.destroy();
            }
        }
    };

    const printButtonOptions = {
        icon: 'print',
        cssClass: 'btn-print',
        elementAttr: { id: 'print-tool-btn' },
        onInitialized: (e) => {
            handleToolButtonHover(e);
        },
        onClick: () => {
            setPrintMapVisible(true);
        }
    };

    const mapboxTrashOptions = {
        icon: 'trash',
        cssClass: 'btn-mapbox-trash',
        elementAttr: {title: 'Delete all drawings'},
        onClick: () => {
            const mbtrashbtn = document.querySelector('.mapbox-gl-draw_trash');
            mbtrashbtn.click();
            PolygonAreaTool.hasInstance() && polygonAreaButtonOptions.destroy();
        }
    }

    const shareMapOptions = {
        text: sharebtntext,
        icon: 'share',
        elementAttr: { title: 'Share this map'},
        onClick: () => {
            dispatch(setSharePopupVisible(true));
        }
    };

    /*const threeDButtonOptions = {
        icon: '3Dtool',
        cssClass: threeDterrain ? 'btn-3Dtool btn-active' : 'btn-3Dtool',
        elementAttr: { title: 'Show 3D terrain' },
        create: () => {
            const threeDtool = ThreeDimensionalTerrainTool.getInstance();
            setThreeDterrain(true);
            threeDtool.create();
        },
        destroy: () => {
            const threeDtool = ThreeDimensionalTerrainTool.getInstance();
            setThreeDterrain(false);
            threeDtool.destroy();
        },
        onClick: () => {
            if (!threeDterrain) {
                threeDButtonOptions.create();
            } else {
                threeDButtonOptions.destroy();
            }
        }
    };*/

    const disableOtherTools = (toggletool) => {
        toggletool = typeof toggletool === 'undefined' ? null : toggletool;
        const tools = {
            coordinatestool: [coordinatestool, coordinatesButtonOptions],
            distancetool: [distancetool, distanceButtonOptions],
            polygonareatool: [polygonareatool, polygonAreaButtonOptions]
            /*spotelevationtool: [spotelevationtool, spotElevationButtonOptions]*/
        };
        for (const [prop, tooldata] of Object.entries(tools)) {
            if (prop !== toggletool && tooldata[0]) {
                tooldata[1].destroy();
            }
        }
    };

    if (user.isLoggedIn() || env === 'development') {
        if (printenabled) {
            return (
                <Fragment>
                    <Toolbar className={'header-toolbar'}>
                        <Item
                            visible={menuToggleEnabled}
                            location={'before'}
                            widget={'dxButton'}
                            cssClass={'menu-button'}
                        >
                            <Button icon="menu" stylingMode="text" onClick={toggleMenu}/>
                        </Item>
                        <Item
                            location={'before'}
                            cssClass={'header-title'}
                            text={title}
                            visible={!!title}
                        />
                        <Item
                            location={'before'}
                            cssClass={'logo-container'}
                            visible={!menuExpanded}
                        >
                            <img src={clientlogo} alt={companyname+' Logo'}/>
                            <div className="logo-text">{companyname}</div>
                        </Item>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={distancetool ? 'btn-measure-tool btn-active' : 'btn-measure-tool'}
                              options={distanceButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={polygonareatool ? 'btn-polygonarea-tool btn-active' : 'btn-polygonarea-tool'}
                              options={polygonAreaButtonOptions}/>
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={spotelevationtool ? 'btn-spotelevation-tool btn-active' : 'btn-spotelevation-tool'}
                              options={spotElevationButtonOptions}/>*/}
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={coordinatestool ? 'btn-coordinates-tool btn-active' : 'btn-coordinates-tool'}
                              options={coordinatesButtonOptions}/>*/}
                        <Item location="center"
                              widget="dxButton"
                              cssClass={contourstool ? 'btn-contours-tool btn-active' : 'btn-contours-tool'}
                              options={contoursButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              options={printButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={commentstool ? 'btn-comments-tool btn-active' : 'btn-comments-tool'}
                              options={commentsButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={viewstool ? 'btn-views-tool btn-active' : 'btn-views-tool'}
                              options={viewsButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={markerstool ? 'btn-markers-tool btn-active' : 'btn-markers-tool'}
                              options={markersButtonOptions}/>
                        <Item location="center"
                              cssClass={trashbtnclass}
                              widget="dxButton"
                              options={mapboxTrashOptions}/>
                        <Item location="after"
                              cssClass="date-last-updated"
                              elementAttr={{title: 'Map Last Updated'}}>
                            {'Last Map Update: ' + moment(map.Updated).format('l')}
                        </Item>
                        <Item location="after"
                              widget="dxButton"
                              options={shareMapOptions}
                              cssClass={sharebtnclass}
                        />

                        <Item
                            location={'after'}
                            locateInMenu={'auto'}
                            menuItemTemplate={'userPanelTemplate'}
                        >
                            <Button
                                className={'user-button authorization'}
                                width={210}
                                height={'100%'}
                                stylingMode={'text'}
                            >
                                <UserPanel menuMode={'context'}/>
                            </Button>
                        </Item>

                        <Template name={'userPanelTemplate'}>
                            <UserPanel menuMode={'list'}/>
                        </Template>
                    </Toolbar>
                    <ToolNavTooltip/>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Toolbar className={'header-toolbar'}>
                        <Item
                            visible={menuToggleEnabled}
                            location={'before'}
                            widget={'dxButton'}
                            cssClass={'menu-button'}
                        >
                            <Button icon="menu" stylingMode="text" onClick={toggleMenu}/>
                        </Item>
                        <Item
                            location={'before'}
                            cssClass={'header-title'}
                            text={title}
                            visible={!!title}
                        />
                        <Item
                            location={'before'}
                            cssClass={'logo-container'}
                            visible={!menuExpanded}
                        >
                            <img src={clientlogo} alt={companyname+' Logo'}/>
                            <div className="logo-text">{companyname}</div>
                        </Item>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={distancetool ? 'btn-measure-tool btn-active' : 'btn-measure-tool'}
                              options={distanceButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={polygonareatool ? 'btn-polygonarea-tool btn-active' : 'btn-polygonarea-tool'}
                              options={polygonAreaButtonOptions}/>
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={spotelevationtool ? 'btn-spotelevation-tool btn-active' : 'btn-spotelevation-tool'}
                              options={spotElevationButtonOptions}/>*/}
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={coordinatestool ? 'btn-coordinates-tool btn-active' : 'btn-coordinates-tool'}
                              options={coordinatesButtonOptions}/>*/}
                        <Item location="center"
                              widget="dxButton"
                              cssClass={contourstool ? 'btn-contours-tool btn-active' : 'btn-contours-tool'}
                              options={contoursButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={commentstool ? 'btn-comments-tool btn-active' : 'btn-comments-tool'}
                              options={commentsButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={viewstool ? 'btn-views-tool btn-active' : 'btn-views-tool'}
                              options={viewsButtonOptions}/>
                        <Item location="center"
                              cssClass={trashbtnclass}
                              widget="dxButton"
                              options={mapboxTrashOptions}/>
                        <Item location="after"
                              widget="dxButton"
                              options={shareMapOptions}
                              cssClass={sharebtnclass}
                        />

                        <Item
                            location={'after'}
                            locateInMenu={'auto'}
                            menuItemTemplate={'userPanelTemplate'}
                        >
                            <Button
                                className={'user-button authorization'}
                                width={210}
                                height={'100%'}
                                stylingMode={'text'}
                            >
                                <UserPanel menuMode={'context'}/>
                            </Button>
                        </Item>

                        <Template name={'userPanelTemplate'}>
                            <UserPanel menuMode={'list'}/>
                        </Template>
                    </Toolbar>
                    <ToolNavTooltip/>
                </Fragment>
            );
        }
    } else {
        if (printenabled) {
            return (
                <Fragment>
                    <Toolbar className={'header-toolbar'}>
                        <Item
                            location={'before'}
                            cssClass={'header-title'}
                            text={title}
                            visible={!!title}
                        />
                        <Item
                            location={'before'}
                            cssClass={'logo-container'}
                            visible={!menuExpanded}
                        >
                            <img src={clientlogo} alt={companyname+' Logo'}/>
                            <div className="logo-text">{companyname}</div>
                        </Item>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={distancetool ? 'btn-measure-tool btn-active' : 'btn-measure-tool'}
                              options={distanceButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={polygonareatool ? 'btn-polygonarea-tool btn-active' : 'btn-polygonarea-tool'}
                              options={polygonAreaButtonOptions}/>
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={spotelevationtool ? 'btn-spotelevation-tool btn-active' : 'btn-spotelevation-tool'}
                              options={spotElevationButtonOptions}/>*/}
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={coordinatestool ? 'btn-coordinates-tool btn-active' : 'btn-coordinates-tool'}
                              options={coordinatesButtonOptions}/>*/}
                        <Item location="center"
                              widget="dxButton"
                              cssClass={contourstool ? 'btn-contours-tool btn-active' : 'btn-contours-tool'}
                              options={contoursButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              options={printButtonOptions}/>
                        <Item location="center"
                              cssClass={trashbtnclass}
                              widget="dxButton"
                              options={mapboxTrashOptions}/>
                    </Toolbar>
                    <ToolNavTooltip/>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Toolbar className={'header-toolbar'}>
                        <Item
                            location={'before'}
                            cssClass={'header-title'}
                            text={title}
                            visible={!!title}
                        />
                        <Item
                            location={'before'}
                            cssClass={'logo-container'}
                            visible={!menuExpanded}
                        >
                            <img src={clientlogo} alt={companyname+' Logo'}/>
                            <div className="logo-text">{companyname}</div>
                        </Item>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={distancetool ? 'btn-measure-tool btn-active' : 'btn-measure-tool'}
                              options={distanceButtonOptions}/>
                        <Item location="center"
                              widget="dxButton"
                              cssClass={polygonareatool ? 'btn-polygonarea-tool btn-active' : 'btn-polygonarea-tool'}
                              options={polygonAreaButtonOptions}/>
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={spotelevationtool ? 'btn-spotelevation-tool btn-active' : 'btn-spotelevation-tool'}
                              options={spotElevationButtonOptions}/>*/}
                        {/*<Item location="center"
                              widget="dxButton"
                              cssClass={coordinatestool ? 'btn-coordinates-tool btn-active' : 'btn-coordinates-tool'}
                              options={coordinatesButtonOptions}/>*/}
                        <Item location="center"
                              widget="dxButton"
                              cssClass={contourstool ? 'btn-contours-tool btn-active' : 'btn-contours-tool'}
                              options={contoursButtonOptions}/>
                        <Item location="center"
                              cssClass={trashbtnclass}
                              widget="dxButton"
                              options={mapboxTrashOptions}/>
                    </Toolbar>
                    <ToolNavTooltip/>
                </Fragment>
            );
        }
    }
}