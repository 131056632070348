import store from "../../api/store/store";
import {setDisableAerials, setLayersIcon, setShowElevcontrsTooltip} from "../../api/actions/actions";

export const ContoursTool = (() => {
    let instance;

    function createInstance() {
        const map = store.getState().mbmap;

        return {
            create: () => {
                if (map !== null) {
                    if (typeof map.getLayer("hillshading") === 'undefined' && typeof map.getSource('mapbox-dem') === 'undefined') {
                        /*map.addSource('mapbox-dem', {
                            'type': 'raster-dem',
                            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1'
                        });*/
                        if (!map.getLayer('land')) {
                            const mblayers = map.getStyle().layers
                            let firstLayerId;
                            for (let i = 0; i < mblayers.length; i++) {
                                if (mblayers[i].type === 'symbol') {
                                    firstLayerId = mblayers[i].id;
                                    break;
                                }
                            }
                            map.addLayer({
                                "id": "land",
                                "type": "background",
                                "layout": {},
                                "paint": {"background-color": "hsl(0,0%,82%)"},
                                "metadata": {"mapbox:featureComponent": "land-and-water", "mapbox:group": "Land & water, land"}
                            }, firstLayerId);
                        }
                        map.getLayer('hillshading') && map.removeLayer('hillshading');
                        map.addLayer({
                            'id': 'hillshading',
                            "source": 'mapbox-dem',
                            'type': 'hillshade',
                            'paint': {'hillshade-shadow-color': 'hsl(139,76%,16%)'}
                        });
                    }
                    if (typeof map.getLayer("contour-labels") === 'undefined' && typeof map.getSource('contour-text') === 'undefined') {
                        map.addSource('contour-text',{
                            type: 'vector',
                            url: 'mapbox://mapbox.mapbox-terrain-v2'
                        });
                        map.addLayer({
                            "id": "contour-labels",
                            "type": "symbol",
                            "source": 'contour-text',
                            "source-layer": "contour",
                            'layout': {
                                'visibility': 'visible',
                                'symbol-placement': 'line',
                                'text-field': ["concat", ["to-string", ["*", ["get", "ele"], 3.3]], " ft"],
                                'text-anchor': 'bottom',
                                'text-font': [
                                    "Open Sans Semibold",
                                    "Arial Unicode MS Bold"
                                ],
                                'text-size': 16
                            },
                            'paint': {
                                'icon-color': '#ffffff',
                                'icon-halo-width': 1,
                                'text-color': '#002fff',
                                'text-halo-width': 2
                            }
                        });
                    }
                    if (typeof map.getLayer("contours") === 'undefined' && typeof map.getSource('terrain-v2') === 'undefined') {
                        map.addSource('terrain-v2', {
                            type: 'vector',
                            url: 'mapbox://mapbox.mapbox-terrain-v2',
                        });
                        map.addLayer({
                            "id": "contours",
                            "type": "line",
                            "source": 'terrain-v2',
                            "source-layer": "contour",
                            'layout': {
                                'visibility': 'visible',
                                'line-join': 'round',
                                'line-cap': 'round'
                            },
                            'paint': {
                                'line-color': '#040083',
                                'line-width': 1.4
                            },
                        });
                    }

                    map.setLayoutProperty('satellite', 'visibility', 'none');
                    map.getZoom() < 14 && map.setZoom(14);

                    store.dispatch(setDisableAerials(true));
                } else {
                    ContoursTool.getInstance().destroy();
                }
                store.dispatch(setLayersIcon('layers-black'));
                store.dispatch(setShowElevcontrsTooltip(false));
            },
            destroy: () => {
                if (map !== null) {
                    try {
                        //map.removeLayer('hillshading');
                        map.removeLayer('contour-labels');
                        map.removeLayer('contours');
                        //map.removeSource('mapbox-dem');
                        map.removeSource('contour-text');
                        map.removeSource('terrain-v2');
                    }
                    catch(err) {
                        console.error(err);
                    }

                    map.setLayoutProperty('satellite', 'visibility', 'visible');

                    store.dispatch(setDisableAerials(false));
                }
                store.dispatch(setLayersIcon('layers'));
            },
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();