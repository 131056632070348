import React, {useState, useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, Position } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import Toolbar, {Item} from "devextreme-react/toolbar";
import DropDownButton from 'devextreme-react/drop-down-button';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
    setShowComments,
    setShowThreads,
    setActiveThread,
    setCommentsTool,
    setCommentsAreaTool,
    setCommentsMarkerTool,
    setSubscriberList,
    setError,
    setPolygonColor,
    setCommentsData,
    setSharePopupText,
    setSharePopupURL,
    setSharePopupVisible,
    setLatHigh,
    setLatLow,
    setLngHigh,
    setLngLow
} from "../../api/actions/actions";
import {CommentsMarkerTool} from "./CommentsMarkerTool";
import {CommentsAreaTool} from "./CommentsAreaTool";
import {status_filter_list} from "../../components/user-comments/status-list";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import './userthreads.scss';
import {kwapi, user} from "../../api/kwapi";
import ConfirmPopup from "../../components/confirmpopup/ConfirmPopup";
import store from "../../api/store/store";

const UserThreads  = () => {
    const dispatch = useDispatch();
    const showThreads = useSelector((state) => state.showthreads);
    const commentsdata = useSelector((state) => state.commentsdata);
    const markertool = useSelector((state) => state.comments_marker_tool);
    const areatool = useSelector((state) => state.comments_area_tool);
    const account = kwapi.getClientAccount();
    let [statusfilter, setStatusFilter] = useState(null);
    const [showconfirm, setShowConfirm] = useState(false);
    const [actionobj, setActionObj] = useState(null);
    const mapObj = useSelector((state) => state.map);
    const mbMap = useSelector((state) => state.mbmap);

    // Initialize when component mounts
    useEffect(() => {
        const threadroute = kwapi.getThreadRoute();
        if (threadroute !== null) {
            const e = {
                target: {
                    id: 'view-thread-' + threadroute.thread_id
                }
            };
            viewThread(e);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const commentMarkerButtonOptions = {
        icon: 'markertool',
        elementAttr: {title: 'Add Comments Marker'},
        text: 'Comments Marker',
        onClick: () => {
            if (markertool) {
                CommentsMarkerTool.getInstance().destroy()
            } else {
                if (CommentsAreaTool.hasInstance()) {
                    CommentsAreaTool.getInstance().destroy();
                    dispatch(setCommentsAreaTool(false));
                }
                CommentsMarkerTool.getInstance().create();
            }
            dispatch(setCommentsMarkerTool(!markertool));

            //Fire click event on menu icon to close menu
            const menubtn = document.querySelector('.user-threads .threads-toolbar .dx-toolbar-after .dx-icon-overflow');
            menubtn.click();
        }
    };

    const commentAreaButtonOptions = {
        icon: 'areatool',
        cssClass: areatool ? 'btn-area-tool btn-active' : 'btn-area-tool',
        elementAttr: {title: 'Add Comments Area'},
        text: 'Comments Area',
        onClick: () => {
            if (areatool) {
                CommentsAreaTool.getInstance().destroy();
            } else {
                if (CommentsMarkerTool.hasInstance()) {
                    CommentsMarkerTool.getInstance().destroy();
                    dispatch(setCommentsMarkerTool(false));
                }
                CommentsAreaTool.getInstance().create();
            }
            dispatch(setCommentsAreaTool(!areatool));

            //Fire click event on menu icon to close menu
            const menubtn = document.querySelector('.user-threads .threads-toolbar .dx-toolbar-after .dx-icon-overflow');
            menubtn.click();
        }
    };

    const hideThreads = () => {
        dispatch(setShowThreads(false));
        dispatch(setCommentsTool(false));
        if (CommentsAreaTool.hasInstance()) {
            dispatch(setPolygonColor('#d20c0c'));
            CommentsAreaTool.getInstance().destroy();
        }
        if (CommentsMarkerTool.hasInstance()) {
            CommentsMarkerTool.getInstance().destroy();
        }
    };

    const onThreadDelete = (threadobj) => {
        setActionObj(threadobj);
        setShowConfirm(true);
    };

    const doThreadDelete = () => {
        //match user
        if (parseInt(account.id) === parseInt(actionobj.user_id) || user.isAdminAccount()) {
            kwapi.deleteThread(actionobj, threadDeleteSuccess, threadError);
        }
    };

    const threadDeleteSuccess = (thread_id) => {
        setActionObj(null);
        const threadidx = commentsdata.findIndex(x => x.id.toString() === thread_id.toString());
        const updateCommentsData = commentsdata;
        updateCommentsData.splice(threadidx, 1);
        dispatch(setCommentsData(updateCommentsData));
    };

    const threadError = (error) => {
       dispatch(setError(error));
    };
    const shareThread = (e) => {
       const id = e.target.id.replace('share-thread-', '');
       dispatch(setSharePopupURL(kwapi.getAppURL()+'thread/'+id+'/0/'+mapObj.URLToken));
       dispatch(setSharePopupText('Shareable Thread URL'));
       dispatch(setSharePopupVisible(true));
    };
    const viewThread = (e) => {
        const id = e.target.id.replace('view-thread-', '');
        const marker = document.getElementById('thread-' + id);
        const thread = commentsdata.find(x => x.id.toString() === id.toString());

        if (typeof thread !== 'undefined') {
            dispatch(setActiveThread(thread));
            const clientacct = kwapi.getClientAccount();
            if (clientacct !== null && thread.user_id === clientacct.id) {
                kwapi.getThreadSubscriberList(subscriberListSuccess, subscriberListError);
            } else {
                dispatch(setShowComments(true));
            }
            if (marker !== null) {
                marker.style.display = 'block';
                //dispatch(setShowComments(true));
            } else if (thread.type === 'polygon') {
                //dispatch(setShowComments(true));
            }
            if (thread.center === null) {
                if (thread.type !== 'polygon') {
                    mbMap.fitBounds([
                        [parseFloat(thread.marker.lng) + 0.007, parseFloat(thread.marker.lat) + 0.007], // southwestern corner of the bounds
                        [parseFloat(thread.marker.lng) - 0.007, parseFloat(thread.marker.lat) - 0.007] // northeastern corner of the bounds
                    ]);
                } else {
                    thread.marker[0].map((lnglat) => {
                        parseLngLimits(lnglat[0]);
                        parseLatLimits(lnglat[1]);
                    });
                    mbMap.fitBounds([
                        [store.getState().lnglow + 0.007, store.getState().lathigh + 0.007], // southwestern corner of the bounds
                        [store.getState().lnghigh - 0.007, store.getState().latlow - 0.007] // northeastern corner of the bounds
                    ]);
                    dispatch(setLatLow(null));
                    dispatch(setLatHigh(null));
                    dispatch(setLngLow(null));
                    dispatch(setLngHigh(null));
                }
            } else {
                mbMap.setCenter(JSON.parse(thread.center));
            }

            if (thread.zoom !== null) {
                mbMap.setZoom(thread.zoom);
            }
            if (thread.bearing !== null) {
                mbMap.setBearing(thread.bearing);
            }
            if (thread.pitch !== null) {
                mbMap.setPitch(thread.pitch);
            }
        }
    };

    const parseLatLimits = (lat) => {
        const latlow = store.getState().latlow;
        const lathigh = store.getState().lathigh;
        if (latlow === null || parseFloat(lat) < latlow) {
            store.dispatch(setLatLow(parseFloat(lat)));
        }
        if (lathigh === null || parseFloat(lat) > lathigh) {
            store.dispatch(setLatHigh(parseFloat(lat)));
        }
    }

    const parseLngLimits = (lng) => {
        const lnglow = store.getState().lnglow;
        const lnghigh = store.getState().lnghigh;
        if (lnglow === null || parseFloat(lng) < lnglow) {
            store.dispatch(setLngLow(parseFloat(lng)));
        }
        if (lnghigh === null || parseFloat(lng) > lnghigh) {
            store.dispatch(setLngHigh(parseFloat(lng)));
        }
    };

    const subscriberListSuccess = (subscriberList) => {
        dispatch(setSubscriberList(subscriberList));
        dispatch(setShowComments(true))
    };

    const subscriberListError = () => {
        dispatch(setError('Unable to retrieve subscribers from API'));
    };

    const onStatusClick = (e) => {
        //notify(e.itemData.name || e.itemData, 'success', 600);
        setStatusFilter(e.itemData.id);
    };
    
    const status_id = statusfilter === null ? '' : statusfilter;

    var filterindex = status_filter_list.findIndex(obj => {
        return obj.id === status_id
    });
    const status_filter = status_filter_list[filterindex];

    //Dynamically change popup position based on screen size
    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars
    const offsetY = height > 800 ? 299 : height > 760 ? 250 : 100;
    const offsetX = height > 760 ? 86 : 70;
    const popupHeight = height > 940 ? 600 : 470;

    return (
        <Fragment>
            <Popup
                visible={showThreads}
                onHiding={hideThreads}
                resizeEnabled={true}
                dragEnabled={true}
                focusStateEnabled={true}
                closeOnOutsideClick={false}
                showCloseButton={true}
                shading={false}
                showTitle={true}
                title={commentsdata !== null ? commentsdata.length + ' Comment Threads' : ''}
                container=".dx-viewport"
                defaultWidth={390}
                defaultHeight={popupHeight}
                minWidth={260}
                maxWidth={600}
                className='user-threads'
            >
                <Position
                    at="bottom"
                    my="right"
                    of={'#mapboxgl-ctrl-top-right'}
                    offset={offsetX + ' ' + offsetY}
                />
                <Toolbar className={'threads-toolbar'}>
                    <Item
                        locateInMenu="always"
                        widget="dxButton"
                        cssClass={markertool ? 'btn-marker-tool btn-active' : 'btn-marker-tool'}
                        options={commentMarkerButtonOptions}
                    />
                    <Item
                        locateInMenu="always"
                        widget="dxButton"
                        cssClass={areatool ? 'btn-area-tool btn-active' : 'btn-area-tool'}
                        options={commentAreaButtonOptions}
                    />
                </Toolbar>
                <div className="dx-fieldset status-filter">
                    <div className="dx-field">
                        <div className="dx-field-value">
                            <DropDownButton
                                icon={status_filter.icon}
                                text={status_filter.id !== '' ? status_filter.name : 'Filter by Status'}
                                dropDownOptions={{width: 230}}
                                items={status_filter_list}
                                displayExpr="name"
                                keyExpr="id"
                                onItemClick={onStatusClick}
                            />
                        </div>
                    </div>
                </div>
                <ScrollView width="100%" height="100%">
                    <div style={{height: '100%'}}>
                        {commentsdata !== null && commentsdata.map((threadobj, i) => { //eslint-disable-line array-callback-return
                            if (status_filter.id === '' || threadobj.status === status_filter.id) {
                                var statusindex = status_filter_list.findIndex(obj => {
                                    return obj.id === threadobj.status
                                });
                                let status = status_filter_list[statusindex]
                                if (typeof status === 'undefined') {
                                    status = status_filter_list[1];
                                }
                                return (
                                    <div key={i} className="comment-thread">
                                        <div className="comment" id="comment-1">
                                            <summary>
                                                <div className="comment-heading">
                                                    <div className="comment-icon" onClick={viewThread}>
                                                        {threadobj.type === 'marker' ?
                                                            <img className="marker-icon" alt="Marker Icon"
                                                                 src={kwapi.getCDNURL()+"assets/images/comments_marker_icon_black.png"}/>
                                                            :
                                                            <img className="draw-icon" alt="Draw Icon"
                                                                 src={kwapi.getCDNURL()+"assets/images/comments_draw_icon.png"}/>
                                                        }
                                                    </div>
                                                    <div className="comment-info">
                                                        {
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                        }<a href="#"
                                                           className="comment-author">{threadobj.user.firstname + ' ' + threadobj.user.lastname}</a>
                                                        {(account.id.toString() === threadobj.user_id.toString() || user.isAdminAccount()) &&
                                                        <Fragment>
                                                            {(typeof threadobj.thread.replies === 'undefined' || !threadobj.thread.replies.length || user.isAdminAccount()) &&
                                                                <button id={'delete-btn-' + threadobj.id} type="button"
                                                                        className="delete-btn action-btn"
                                                                        title="Delete Comment" onClick={() => {
                                                                    onThreadDelete(threadobj)
                                                                }}><i className="dx-icon dx-icon-trash"/></button>
                                                            }
                                                        </Fragment>
                                                        }
                                                        <p className="m-0">
                                                            {moment(threadobj.updated_at).fromNow()}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="thread-status" title={'Status: ' + status.name}>
                                                    <p>Status: <i className={'dx-icon dx-icon-' + status.icon}/>
                                                        {status.name}
                                                    </p>
                                                </div>
                                            </summary>

                                            <div className="comment-body">
                                                <p>
                                                    {threadobj.thread.comment}
                                                </p>
                                                <button id={'view-thread-' + threadobj.id} className="view-thread-btn" type="button"
                                                        data-toggle="view-thread" onClick={viewThread}>View Thread
                                                </button>
                                                <button id={'share-thread-' + threadobj.id} onClick={shareThread} className="share-thread-btn" type="button">
                                                    <i className="dx-icon dx-icon-share"/>
                                                    Share
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </ScrollView>
            </Popup>
            {showconfirm &&
            <ConfirmPopup title="Delete Thread" message="Are you sure you want to delete this comment thread? This cannot be undone and will delete all comments/replies in this thread!" showConfirm={setShowConfirm} confirmCallback={doThreadDelete}/>
            }
        </Fragment>
    );
};
export default UserThreads;