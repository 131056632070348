import React from 'react';
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, {FilterRow, Paging, Scrolling, Selection} from "devextreme-react/data-grid";
import ScrollView from 'devextreme-react/scroll-view';
import CustomStore from 'devextreme/data/custom_store';
import {bindActionCreators} from 'redux';
import {setError, setSubscribers} from "../../api/actions/actions";
import store from "../../api/store/store";
import {connect} from 'react-redux';
import {kwapi} from "../../api/kwapi";
import useBrowserDetection from "../../hooks/useBrowserDetection.";

class SubscriptionDropdown extends React.Component
{
    constructor() {
        super();
        this.state = {
            subscribers: [],
            selectFiring: false
        }
        //this.gridDataSource = this.dataSource();
        this.dataGridRender = this.dataGridRender.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
    }
    componentDidMount() {
        this.getSubscribers();

        const browser = useBrowserDetection();
        if (browser === 'firefox') {
            /*const subDropdown = document.getElementById('thread-subscriptions');
            if (subDropdown !== null) {
                subDropdown.style.left = '20px';
            }*/
        }
    }

    dataSource = () =>  {
        const subscriberlist = typeof this.props !== 'undefined' ? this.props.appState.subscriberlist : store.getState().subscriberlist;
        return new CustomStore({
            loadMode: 'raw',
            key: 'ID',
            load: function() {
                return subscriberlist;
            }
        })
    }
    getSubscribers = () => {
        const {active_thread} = this.props.appState;
        if (active_thread !== null) {
            kwapi.getThreadSubscriptions(active_thread, this.subscribersSuccess, this.subscribersError);
        }
    }

    subscribersError = () => {
        const {setError} = this.props.actions;
        setError('Unable to retrieve subscribers from API');
    }

    subscribeSuccess = (subscriptions) => {
        this.setState({subscribers: subscriptions});
    }

    updateSubscribeSuccess = () => {
        //Do nothing
    }
    updateSubscribeError = () => {
        const {setError} = this.props.actions;
        setError('Unable to update thread subscriptions');
    }

    subscribeError = () => {
        const {setError} = this.props.actions;
        setError('Unable to update thread subscriptions');
    }

    subscribersSuccess = (subscribers) => {
        this.setState({subscribers: subscribers});
    }

    dataGrid_onSelectionChanged(e) {
        const {active_thread} = this.props.appState;
        const values = e.selectedRowKeys.length ? e.selectedRowKeys : [];
        if (active_thread !== null) {
            kwapi.updateThreadSubscriptions(active_thread, values, this.updateSubscribeSuccess, this.updateSubscribeError);
        } else {
            store.dispatch(setSubscribers(values));
        }
        this.setState({subscribers: values });
    }
    syncDataGridSelection(e) {
        const {active_thread} = this.props.appState;
        const values = e.value || [];
        if (active_thread !== null) {
            kwapi.updateThreadSubscriptions(active_thread, values, this.updateSubscribeSuccess, this.updateSubscribeError);
        } else {
            store.dispatch(setSubscribers(values));
        }
        this.setState({subscribers: values });
    }
    dataGridRender() {
        const {subscribers} = this.state;
        const gridColumns = ['Firstname', 'Lastname', 'Email'];
        return (
            <ScrollView width="100%" height="100%">
                <DataGrid
                    dataSource={this.dataSource()}
                    columns={gridColumns}
                    hoverStateEnabled={true}
                    selectedRowKeys={subscribers}
                    onSelectionChanged={this.dataGrid_onSelectionChanged}>
                    <Selection mode="multiple" />
                    <Scrolling mode="virtual" rowRenderingMode="virtual" />
                    <Paging enabled={true} pageSize={10} />
                    <FilterRow visible={true} />
                </DataGrid>
            </ScrollView>
        );
    }

    render() {
        const {subscribers} = this.state;
        if (store !== null) {
            return (
                <div id="thread-subscriptions" className="dx-field thread-subscriptions" title="Select users to subscribe...">
                    <div className="dx-field-value" title="Select users to subscribe...">
                        <DropDownBox
                            title="Select users to subscribe..."
                            value={subscribers}
                            valueExpr="ID" grid
                            deferRendering={false}
                            displayExpr="Email"
                            placeholder="Select subscribers..."
                            showClearButton={true}
                            dropDownOptions={{width: '700px'}}
                            dataSource={this.dataSource()}
                            onValueChanged={this.syncDataGridSelection}
                            contentRender={this.dataGridRender}
                        />
                    </div>
                </div>
            );
        } else {
            return (<div/>);
        }
    }
}
function mapStateToProps(state) {
    return {
        appState: state
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setError
        }, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionDropdown);