import React from 'react';
import {LoadPanel} from "devextreme-react/load-panel";
import {kwapi} from "../../api/kwapi";

class MapView extends React.Component
{
    constructor() {
        super();
        const pageURL = window.location.href;
        const uriarr = pageURL.split('/');
        const view_id = uriarr[5];
        kwapi.setMapView(view_id);
    }

    componentDidMount() {
        const pageURL = window.location.href;
        const mapToken = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        window.location.href = '/surveys/map/'+mapToken;
    }

    render() {
        return (
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={true}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />
        );
    }
}
export default MapView;