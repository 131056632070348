import React, {useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, Position } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {setSelectedLayers, setShowMarkersList} from "../../api/actions/actions";
import './mapmarkers.scss';
import store from "../../api/store/store";
import {strhelper} from "../../utils/strhelper";

const MapMarkers = ({openPopup, setMarkerPhotos, clearSelectedMarker}) => {
    const dispatch = useDispatch();
    const showMarkersList = useSelector((state) => state.showmarkerslist);
    const custom_markers = useSelector((state) => state.custom_markers);
    const layers = useSelector((state) => state.selectedlayers);
    const map = useSelector((state) => state.mbmap);
    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars

    const hideMarkersList = () => {
        dispatch(setShowMarkersList(false));
    }

    const activateMarker = (e) => {
        const id = e.target.id.replace('view-marker-', '');
        const marker = custom_markers.find(x => x.id.toString() === id.toString());
        clearSelectedMarker();

        //Activate layer of selected marker
        const new_layers = [...layers];
        for (let i = 0;i < layers.length; i++) {
            let found_layer, is_custom_layer;
            const layer = layers[i];
            is_custom_layer = layer.id !== null ? layer.id.toString().indexOf('c_') !== -1 : false;
            found_layer = is_custom_layer ? marker.layer_id === layer.id : parseInt(marker.layer_id) === parseInt(layer.id);
            if (found_layer) {
                new_layers[i].selected = true;
                const markerDiv = document.getElementById('kw-marker-' + marker.id);
                markerDiv.style.display = '';
                dispatch(setSelectedLayers(new_layers));
                !is_custom_layer && toggleLayer(new_layers[i]);
            }

            if (typeof layer.items !== 'undefined' && layer.items.length) {
                for (let m = 0;m < layer.items.length; m++) {
                    is_custom_layer = layer.items[m].id !== null ? layer.items[m].id.toString().indexOf('c_') !== -1 : false;
                    found_layer = is_custom_layer ? marker.layer_id === layer.items[m].id : parseInt(marker.layer_id) === parseInt(layer.items[m].id);
                    if (found_layer) {
                        new_layers[i].items[m].selected = true;
                        const markerDiv = document.getElementById('kw-marker-' + marker.id);
                        markerDiv.style.display = '';
                        dispatch(setSelectedLayers(new_layers));
                        !is_custom_layer && toggleLayer(new_layers[i].items[m]);
                    }
                }
            }
            if (found_layer) { break; }
        }

        switch (marker.type) {
            case 'video':
                setMarkerPhotos([]);
                openPopup(marker, true);
                break;
            case 'gallery':
                if (marker.gallery_imgs !== null) {
                    const photos = [];
                    marker.gallery_imgs.map((imageobj, i) => { //eslint-disable-line array-callback-return
                        photos.push(imageobj.url);
                    });
                    setMarkerPhotos(photos);
                    openPopup(marker, true);
                }
                break;
            case 'page':
                    openPopup(marker, true);
                break;
            default:
                const lngOffset = height > 1180 ? 0.002 : height > 1000 ? 0.001 : 0.001;
                store.getState().mbmap.setCenter([parseFloat(marker.lng) + lngOffset, marker.lat]);
                document.getElementById('kw-marker-'+marker.id).click();
        }
    }

    const toggleLayer = (clickedLayer) => {
        let visibility = map.getLayoutProperty(clickedLayer.mapbox_id, 'visibility');

        // toggle layer visibility by changing the layout object's visibility property - initial value for visibility is undefined
        if (!clickedLayer.selected && (visibility === 'visible' || typeof visibility === 'undefined')) {
            map.setLayoutProperty(clickedLayer.mapbox_id, 'visibility', 'none');
        } else if (clickedLayer.selected && (visibility === 'none' || typeof visibility === 'undefined')) {
            map.setLayoutProperty(clickedLayer.mapbox_id, 'visibility', 'visible');
        }
    };

    //Dynamically change popup position based on screen size
    const offsetY = height > 800 ? 299 : height > 760 ? 250 : 100;
    const offsetX = height > 760 ? 86 : 70;
    const popupHeight = height > 940 ? 600 : 470;

    return (
       <Popup
           visible={showMarkersList}
           onHiding={hideMarkersList}
           resizeEnabled={true}
           dragEnabled={true}
           focusStateEnabled={true}
           closeOnOutsideClick={false}
           showCloseButton={true}
           shading={false}
           showTitle={true}
           title={custom_markers !== null ? custom_markers.length + ' Map Markers' : ''}
           container=".dx-viewport"
           defaultWidth={390}
           defaultHeight={popupHeight}
           minWidth={260}
           maxWidth={600}
           className='user-views'
       >
           <Position
               at="bottom"
               my="right"
               of={'#mapboxgl-ctrl-top-right'}
               offset={offsetX + ' ' + offsetY}
           />
           {/*<Toolbar className={'views-toolbar'}>
               <Item
                   widget="dxButton"
                   toolbar="top"
                   location="leftr"
                   cssClass={'btn-marker-tool'}
                   options={addViewButtonOptions}
               />
           </Toolbar>*/}
           <ScrollView width="100%" height="100%">
               <div style={{height: '100%'}}>
                   <Fragment>
                       {custom_markers !== null && custom_markers.length > 0 && custom_markers.map((markerobj, i) => { //eslint-disable-line array-callback-return
                           const iconstr = '&#x' + markerobj.icon + ';';
                           return (
                               <div key={i} className="map-view">
                                   <div className="view" id="view-1">
                                       <summary>
                                           <div className="view-heading">
                                               <div className="comment-icon">
                                                   <svg className="circle-icon" width="32px" height="32px">
                                                       <g>
                                                       <circle fill={markerobj.color} cx="15" cy="15" r="15"></circle>
                                                       <text className="icon" x="5" y="23" dangerouslySetInnerHTML={{__html: iconstr}}/>
                                                       </g>
                                                   </svg>
                                               </div>
                                               <div className="view-info">
                                                   {
                                                       // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                   }<a href="#"
                                                       className="view-name">{markerobj.title}</a>
                                               </div>
                                           </div>
                                       </summary>

                                       <div className="marker-body">
                                           <p>
                                               {strhelper.capitalizeFirstLetter(markerobj.type)}
                                           </p>
                                           <button id={'view-marker-' + markerobj.id} className="view-marker-btn" type="button"
                                                   data-toggle="view-marker" onClick={activateMarker}>View Marker
                                           </button>
                                       </div>
                                   </div>
                               </div>
                           );
                       })}
                       {(custom_markers === null || !custom_markers.length) &&
                           <div className="map-view">
                               <details open className="view" id="view-1">
                                   <summary>
                                       <div className="view-heading">
                                           <div className="view-info">
                                               There are no markers on this map
                                           </div>
                                       </div>
                                   </summary>
                               </details>
                           </div>
                       }
                   </Fragment>
               </div>
           </ScrollView>
       </Popup>
    )
};
export default MapMarkers;