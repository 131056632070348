import React, {Fragment} from 'react';
import {CheckBox} from "devextreme-react/check-box";

const MT_Intro = ({popupdismiss, dismissPopup}) => {
    return (
        <Fragment>
            <h1>Welcome to the Kier & Wright Virtual Tours App!</h1>
            <div style={{fontSize: '16px'}}>
                <p>The following tutorial will show you around the map to familiarize you with</p>
                <p>how to use to the tools on this map.</p>
                <br/>
                <p>Click Next to get started.</p>
            </div>
            <div className="dx-field dont-show-checkbox" style={{marginTop: '300px', marginLeft: '-1150px'}}>
                <div className="dx-field-label">Don't show this again</div>
                <div className="dx-field-value">
                    <CheckBox value={popupdismiss} onValueChanged={dismissPopup}/>
                </div>
            </div>
        </Fragment>
    );
};
export default MT_Intro;
