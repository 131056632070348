import store from "../../api/store/store";
import {setShowMarkersList, setShowMarkersTooltip} from "../../api/actions/actions";

export const MapMarkersTool = (() => {
    let instance;

    function createInstance() {

        return {
            create: () => {
                store.dispatch(setShowMarkersList(true));
                store.dispatch(setShowMarkersTooltip(false));

            },
            destroy: () => {
                store.dispatch(setShowMarkersList(false));
                store.dispatch(setShowMarkersTooltip(false));
            },
        };
    }
    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        hasInstance: function () {
            if (!instance) {
                return false;
            } else {
                return true;
            }
        }
    };
})();