import React, {useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Popup, Position } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import DropDownButton from 'devextreme-react/drop-down-button';
import { CheckBox } from 'devextreme-react/check-box';
import Comments from '../../components/user-comments/user-comments';
import SubscriptionDropdown from "./subscriptiondropdown";
import {setActiveThread, setShowComments, setCommentsData,setError, setScrollView} from "../../api/actions/actions";
import './usercomments.scss';
import {CommentsAreaTool} from "./CommentsAreaTool";
import {status_list} from "../../components/user-comments/status-list";
import {kwapi} from "../../api/kwapi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const UserComments = ({showComments, commentsId}) => {
    const dispatch = useDispatch();
    const thread = useSelector((state) => state.active_thread);
    const commentsdata = useSelector((state) => state.commentsdata);
    const account = kwapi.getClientAccount();
    const replies = thread.thread.replies.filter((x) => { return x.thread_id.toString() === thread.id.toString() });
    const commentcnt = replies.length + 1;
    const scrollview = useRef(null);

    const [subscribeChecked, setSubscribeChecked] = useState(false);

    useEffect(() => {
        // Clean up on unmount
        if (account.id !== thread.user_id) {
            getSubscribers();
        }
        dispatch(setScrollView(scrollview.current.instance));

        return unMountComments;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const unMountComments = () => {
        if (thread.type === 'polygon') {
            CommentsAreaTool.getInstance().removePolygon(thread.id);
        }
        dispatch(setScrollView(null));
    };
    const getSubscribers = () => {
        kwapi.getThreadSubscriptions(thread , subscribersSuccess, subscribersError);
    };

    const subscribersError = () => {
        dispatch(setError('Unable to retrieve subscribers from API'));
    };
    const subscribersSuccess = (subscriptions) => {
        setSubscribeChecked(subscriptions.includes(account.id));
    };

    const subscribeSuccess = () => {
        //Do nothing  - placeholder function for future subscribe event code
    };

    const subscribeError = () => {
        dispatch(setError('Unable to update subscription to thread'));
    };

    const onStatusClick = (e) => {
        thread.status = e.itemData.id;
        kwapi.updateThreadStatus(thread, onStatusUpdate, onStatusError);
    };

    const onStatusUpdate = (status) => {
        thread.status = status;
        dispatch(setActiveThread(thread));
        var threadindex = commentsdata.findIndex(obj => {
            return obj.id === thread.id
        });
        commentsdata[threadindex] = thread;
        dispatch(setCommentsData(commentsdata));
    };

    const onStatusError = () => {
        dispatch(setError('Unable to update thread status'));
    }

    const onSubscribeChecked = (args) => {
        setSubscribeChecked(args.value);
        args.value ? kwapi.subscribeToThread(thread, account.id, subscribeSuccess, subscribeError) : kwapi.unsubscribeFromThread(thread, account.id, subscribeSuccess, subscribeError);
    }
    const hideComments = () => {
        const marker = document.getElementById('thread-'+thread.id);
        if (marker !== null) {
            marker.style.display = 'none';
        }
        if (thread.type === 'polygon') {
            CommentsAreaTool.getInstance().removePolygon(thread.id);
        }
        dispatch(setShowComments(false));
        dispatch(setActiveThread(null));
    };

    if (thread.type === 'polygon') {
        CommentsAreaTool.getInstance().addPolygon(thread);
    }

    //Remove None option from list
    //status_list.splice(0, 1);

    var statusindex = status_list.findIndex(obj => {
        return obj.id === thread.status
    });
    const is_thread_author = thread.user_id === account.id
    const status = status_list[statusindex];
    const status_title = !is_thread_author ? 'Only the thread author can change status' : 'Thread Status';
    const thread_status_class = /*is_thread_author ? 'dx-field' : 'dx-field align-left'*/'dx-field';
    const {height, width} = useWindowDimensions(); //eslint-disable-line no-unused-vars
    //@TODO figure our popup dimensions based on screen size and offset x and y values
    /*const offsetY = height > 800 ? 330 : height > 760 ? 250 : 100;
    const offsetX = height > 760 ? 125 : 70;*/
    const offsetX = width > 1028 ? -390 : 130;
    const offsetY = -50;
    const popupHeight = height > 940 ? 600 : 470;
    const popupWidth = width > 1028 ? 419 : 350;
    return (
    <Popup
        visible={showComments}
        onHiding={hideComments}
        resizeEnabled={true}
        dragEnabled={true}
        focusStateEnabled={true}
        closeOnOutsideClick={false}
        showCloseButton={true}
        shading={false}
        showTitle={true}
        title={commentcnt+' Comments'}
        container=".dx-viewport"
        defaultWidth={popupWidth}
        defaultHeight={popupHeight}
        minWidth={260}
        maxWidth={600}
        className='user-comments'
    >
        <Position
            at="center"
            my="right"
            of={'#'+commentsId}
            offset={offsetX + ' ' + offsetY}
        />
        <div className="dx-fieldset thread-toolbar">
            {!is_thread_author ?
                <div id="thread-subscription" className="dx-field">
                    <div className="dx-field-label">Subscribed:</div>
                    <div className="dx-field-value">
                        <CheckBox value={subscribeChecked} onValueChanged={onSubscribeChecked}/>
                    </div>
                </div>
                :
                <SubscriptionDropdown/>
            }

            <div id="thread-status" className={thread_status_class} title={status_title}>
                <div className="dx-field-value" title={status_title}>
                    <DropDownButton
                        icon={status.icon}
                        disabled={!is_thread_author}
                        text={status.name}
                        dropDownOptions={{ width: 230 }}
                        items={status_list}
                        displayExpr="name"
                        keyExpr="id"
                        onItemClick={onStatusClick}
                    />
                </div>
            </div>
        </div>
        <ScrollView ref={scrollview} id="comments-scroll" width="100%" height="100%">
            <Comments/>
        </ScrollView>
    </Popup>
    );
};

export default UserComments;