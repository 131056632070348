import React, {useState} from 'react'
import { Popup } from 'devextreme-react/popup';

const ErrorPopup = ({visible, message}) => {

    const [popupdismiss, setPopupDismiss] = useState(false);

    const dismissPopup = () => setPopupDismiss(true);

    return (
        <Popup
            visible={visible && !popupdismiss}
            dragEnabled={false}
            onHiding={dismissPopup}
            resizeEnabled={false}
            closeOnOutsideClick={false}
            showCloseButton={true}
            showTitle={true}
            title={'Oops we ran into a problem'}
            className="error-popup"
            maxWidth={550}
            maxHeight={200}
        >
            <p style={{display: 'flex', marginTop: '8px'}}>
                <i className="dx-icon dx-icon-clear" style={{fontSize: '50px'}}/>
                <span style={{marginLeft: '10px', marginTop: '15px'}}>{message}</span>
            </p>
        </Popup>
    );
};
export default ErrorPopup;