import React from "react";
import { Range, getTrackBackground } from "react-range";

class RangeSlider extends React.Component {

    constructor() {
        super();
        this.state = {
            values: [0.1]
        }
    }

    componentWillMount() {
        const {initialValue} = this.props;
        if (typeof initialValue !== 'undefined') {
            this.setState({values: [initialValue]});
        }
    }

    handleSliderChange = (values) => {
        const {onSliderChange} = this.props;
        this.setState({values}, () => {
            onSliderChange(values[0]);
        });
    }

    render() {
        const {step, min, max} = this.props;
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginLeft: '10px',
                    marginRight: '70px'
                }}
            >
                <Range
                    values={this.state.values}
                    step={step}
                    min={min}
                    max={max}
                    onChange={this.handleSliderChange}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "36px",
                                display: "flex",
                                width: "100%"
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                        values: this.state.values,
                                        colors: ["#548BF4", "#ccc"],
                                        min: min,
                                        max: max
                                    }),
                                    alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "20px",
                                width: "20px",
                                borderRadius: "4px",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA"
                            }}
                        >
                            <div
                                style={{
                                    height: "16px",
                                    width: "5px",
                                    backgroundColor: isDragged ? "#548BF4" : "#CCC"
                                }}
                            />
                        </div>
                    )}
                />
                <output id="output" className="slider-output">
                    {this.state.values[0].toFixed(1)}
                </output>
            </div>
        );
    }
}
export default RangeSlider;