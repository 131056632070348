import React from 'react';

import store from '../../api/store/store';
import {setMap} from "../../api/actions/actions";
import { Link } from 'react-router-dom';

const formatDate = new Intl.DateTimeFormat('en-US').format;

const DataRow = (rowInfo) => {

    const viewMap = (e) => {
        store.dispatch(setMap(rowInfo.data));
    };

    return (
        <tbody className="dx-row">
        <tr className="main-row">
            <td/>
            <td>{rowInfo.data.MapName}</td>
            <td>{rowInfo.data.MapType}</td>
            <td>{rowInfo.data.MapDescription}</td>
            <td>{formatDate(new Date(rowInfo.data.Created))}</td>
            <td>{formatDate(new Date(rowInfo.data.Updated))}</td>
            <td><Link to={rowInfo.data.ViewURL} onClick={viewMap}>View Map</Link></td>
        </tr>
        </tbody>
    );
};
export default DataRow;