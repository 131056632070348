import store from "../../api/store/store";
import mapboxgl from 'mapbox-gl';
import {setShowThreadPopup, setMarkerType, setCommentsAreaTool} from "../../api/actions/actions";
import {CommentsAreaTool} from "./CommentsAreaTool";

export const CommentsMarkerTool = (() => {
    let instance;

    function createInstance() {
        const map = store.getState().mbmap;
        const commentsdata = store.getState().commentsdata;
        let clickHandler;

        return {
            create: () => {
                const add_markers = () => {
                    for (let m=0;m < commentsdata.length; m++) {
                        const markerobj = commentsdata[m];
                        if (markerobj.type === 'marker') {
                            // create a DOM element for the marker
                            const marker_color = markerobj.color !== null ? markerobj.color : '#dc2c41';
                            var el = document.createElement('div');
                            el.id = 'thread-' + markerobj.id; //for popup position
                            el.className = 'kw-comment-marker-placeholder thread-' + markerobj.id;
                            el.innerHTML = '<svg style="width: 37px;height: 58px;" xmlns="http://www.w3.org/2000/svg"\n' +
                                ' width="37.000000pt" height="58.000000pt" viewBox="0 0 368.000000 582.000000"\n' +
                                ' preserveAspectRatio="xMidYMid meet">\n' +
                                '\n' +
                                '<g transform="translate(0.000000,582.000000) scale(0.100000,-0.100000)"\n' +
                                '>\n' +
                                '<path fill="'+marker_color+'" stroke="none" d="M1636 5799 c-395 -42 -781 -218 -1067 -487 -584 -550 -729 -1373\n' +
                                '-366 -2082 208 -407 632 -1244 1247 -2463 210 -417 386 -755 391 -750 7 7 268\n' +
                                '522 1081 2133 171 338 377 746 458 905 163 320 206 424 244 595 131 590 -45\n' +
                                '1190 -475 1618 -305 304 -684 484 -1121 531 -110 12 -276 12 -392 0z m516\n' +
                                '-298 c550 -119 989 -511 1156 -1031 53 -166 66 -256 66 -455 0 -198 -17 -306\n' +
                                '-74 -477 -126 -377 -422 -708 -790 -882 -599 -283 -1299 -167 -1761 293 -241\n' +
                                '240 -377 498 -435 824 -22 127 -22 362 1 492 108 619 592 1112 1212 1235 140\n' +
                                '28 149 28 348 25 135 -3 204 -9 277 -24z m-993 -3176 c89 -44 226 -90 339\n' +
                                '-115 332 -72 660 -39 990 100 139 59 121 40 -33 -35 -119 -58 -251 -103 -385\n' +
                                '-131 -119 -25 -383 -26 -498 -1 -152 34 -314 100 -442 182 -70 44 -61 44 29 0z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1685 5364 c-597 -65 -1078 -506 -1181 -1084 -24 -135 -22 -336 5\n' +
                                '-465 39 -191 93 -321 195 -474 203 -305 503 -502 868 -572 98 -19 136 -21 288\n' +
                                '-16 144 4 193 10 275 31 257 65 444 172 631 360 195 196 307 405 361 674 24\n' +
                                '120 24 366 0 482 -130 617 -646 1049 -1272 1065 -71 2 -148 1 -170 -1z m369\n' +
                                '-270 c258 -45 485 -160 637 -322 137 -146 189 -263 197 -438 7 -160 -20 -261\n' +
                                '-102 -389 -142 -220 -417 -387 -726 -441 l-95 -16 -120 -116 c-243 -237 -391\n' +
                                '-363 -448 -381 -71 -24 -117 26 -117 127 0 28 22 123 55 237 30 103 53 190 50\n' +
                                '193 -2 2 -41 20 -86 38 -231 95 -431 279 -507 467 -39 96 -55 192 -48 286 27\n' +
                                '367 363 669 841 756 101 19 361 18 469 -1z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1130 4765 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1310 4555 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1130 4335 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1130 4085 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                                '<path fill="#000000" stroke="none" d="M1230 3895 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                                '</g>\n' +
                                '</svg>';

                            el.setAttribute('title', 'Comment thread from ' + markerobj.user.firstname + ' ' + markerobj.user.lastname);
                            /*el.style.backgroundSize = '100%';*/

                            el.addEventListener('click', (e) => {
                                /*setCommentData(markerobj);
                                store.dispatch(setShowComments('comment-marker-'+markerobj.id));*/
                            });
                            const marker = JSON.parse(markerobj.json);
                            // make a marker for each feature and add to the map
                            new mapboxgl.Marker(el)
                                .setLngLat([marker.lng, marker.lat])
                                .addTo(map);

                        }
                    }
                    clickHandler = (e) => {
                        reload_icon(e.lngLat, 'click');
                    }
                    map.on('click', clickHandler);
                    map.getCanvas().style.cursor = 'crosshair';
                }

                const reload_icon = (lnglat, event) => {
                    const marker = document.getElementById('new-thread-marker');
                    marker !== null && marker.remove();
                    CommentsMarkerTool.getInstance().addMarker(lnglat,event);
                    const coordinatesInput = document.getElementById('cm-coordinates');
                    const latInput = document.getElementById('cm-coordinates-lat');
                    const lngInput = document.getElementById('cm-coordinates-lng');
                    if (coordinatesInput !== null) {
                        coordinatesInput.value = lnglat.lat+', '+lnglat.lng;
                        latInput.value = lnglat.lat;
                        lngInput.value = lnglat.lng;
                    }
                }
                CommentsAreaTool.hasInstance() && CommentsAreaTool.getInstance().destroy();
                store.dispatch(setCommentsAreaTool(false));
                add_markers();
            },
            destroy: () => {
                const remove_markers = () => {
                    const comment_markers = document.querySelectorAll('.kw-comment-marker-placeholder');/*document.getElementsByClassName('kw-comment-marker-placeholder');*/

                    for (let marker of comment_markers) {
                        marker.remove();
                    }

                    const new_marker = document.getElementById('new-thread-marker');
                    new_marker !== null && new_marker.remove();
                }
                map.off('click', clickHandler);
                map.getCanvas().style.cursor = 'unset';
                remove_markers();
                store.dispatch(setShowThreadPopup(false));
                instance = undefined;
            },
            addMarker: (ltlng, event) => {
                let marker;

                const onDragEnd = () => {
                    var lngLat = marker.getLngLat();
                    const coordinatesInput = document.getElementById('cm-coordinates');
                    const latInput = document.getElementById('cm-coordinates-lat');
                    const lngInput = document.getElementById('cm-coordinates-lng');
                    if (coordinatesInput !== null) {
                        coordinatesInput.value = lngLat.lat+', '+lngLat.lng;
                        latInput.value = lngLat.lat;
                        lngInput.value = lngLat.lng;
                    }
                }

                // create a DOM element for the marker
                const marker_color = store.getState().marker_color !== null ? store.getState().marker_color : '#dc2c41';
                var el = document.createElement('div');
                el.id = 'new-thread-marker';
                el.className = 'kw-comment-marker-placeholder new-thread-marker';
                el.innerHTML = '<svg style="width: 37px;height: 58px;" xmlns="http://www.w3.org/2000/svg"\n' +
                    ' width="37.000000pt" height="58.000000pt" viewBox="0 0 368.000000 582.000000"\n' +
                    ' preserveAspectRatio="xMidYMid meet">\n' +
                    '\n' +
                    '<g transform="translate(0.000000,582.000000) scale(0.100000,-0.100000)"\n' +
                    '>\n' +
                    '<path fill="'+marker_color+'" stroke="none" d="M1636 5799 c-395 -42 -781 -218 -1067 -487 -584 -550 -729 -1373\n' +
                    '-366 -2082 208 -407 632 -1244 1247 -2463 210 -417 386 -755 391 -750 7 7 268\n' +
                    '522 1081 2133 171 338 377 746 458 905 163 320 206 424 244 595 131 590 -45\n' +
                    '1190 -475 1618 -305 304 -684 484 -1121 531 -110 12 -276 12 -392 0z m516\n' +
                    '-298 c550 -119 989 -511 1156 -1031 53 -166 66 -256 66 -455 0 -198 -17 -306\n' +
                    '-74 -477 -126 -377 -422 -708 -790 -882 -599 -283 -1299 -167 -1761 293 -241\n' +
                    '240 -377 498 -435 824 -22 127 -22 362 1 492 108 619 592 1112 1212 1235 140\n' +
                    '28 149 28 348 25 135 -3 204 -9 277 -24z m-993 -3176 c89 -44 226 -90 339\n' +
                    '-115 332 -72 660 -39 990 100 139 59 121 40 -33 -35 -119 -58 -251 -103 -385\n' +
                    '-131 -119 -25 -383 -26 -498 -1 -152 34 -314 100 -442 182 -70 44 -61 44 29 0z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1685 5364 c-597 -65 -1078 -506 -1181 -1084 -24 -135 -22 -336 5\n' +
                    '-465 39 -191 93 -321 195 -474 203 -305 503 -502 868 -572 98 -19 136 -21 288\n' +
                    '-16 144 4 193 10 275 31 257 65 444 172 631 360 195 196 307 405 361 674 24\n' +
                    '120 24 366 0 482 -130 617 -646 1049 -1272 1065 -71 2 -148 1 -170 -1z m369\n' +
                    '-270 c258 -45 485 -160 637 -322 137 -146 189 -263 197 -438 7 -160 -20 -261\n' +
                    '-102 -389 -142 -220 -417 -387 -726 -441 l-95 -16 -120 -116 c-243 -237 -391\n' +
                    '-363 -448 -381 -71 -24 -117 26 -117 127 0 28 22 123 55 237 30 103 53 190 50\n' +
                    '193 -2 2 -41 20 -86 38 -231 95 -431 279 -507 467 -39 96 -55 192 -48 286 27\n' +
                    '367 363 669 841 756 101 19 361 18 469 -1z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4765 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1310 4555 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4335 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1130 4085 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '<path fill="#000000" stroke="none" d="M1230 3895 l0 -25 705 0 705 0 0 25 0 25 -705 0 -705 0 0 -25z"/>\n' +
                    '</g>\n' +
                    '</svg>';

                el.setAttribute('title', 'New Comments Marker');

                el.draggable = true;
                el.style.cursor = 'move';

                el.addEventListener("mousedown", mdown, false);
                el.addEventListener("touchstart", mdown, false);

                marker = new mapboxgl.Marker(el)
                    .setLngLat(ltlng)
                    .addTo(map)
                    .on('dragend', onDragEnd);

                function onUp(e) {
                    map.dragPan.enable();
                    marker.setLngLat(e.lngLat)
                }

                function mmove(e) {
                    e.stopPropagation();
                    e.preventDefault();

                    if (e.type === "touchmove") {
                        e = e.changedTouches[0];
                    }
                    el.style.transform = 'translate(-50%, -50%) translate(' + (e.pageX - el._x) + 'px,' + (e.pageY - el._y) + 'px)'
                }

                function mup(e) {
                    document.body.removeEventListener("mousemove", mmove, false);
                    document.body.removeEventListener("touchmove", mmove, false);
                    document.body.removeEventListener("mouseleave", mup, false);
                    document.body.removeEventListener("touchleave", mup, false);
                    document.body.removeEventListener("touchend", mup, false);
                    document.body.removeEventListener("mouseup", mup, false);
                    onDragEnd();
                }
                function mdown(e) {

                    var rect = document.querySelector('.map-container').getBoundingClientRect();
                    el._x = rect.x;
                    el._y = rect.y;

                    document.body.addEventListener("mousemove", mmove, false);
                    document.body.addEventListener("touchmove", mmove, false);

                    document.body.addEventListener("mouseup", mup, false);
                    document.body.addEventListener("touchend", mup, false);
                    document.body.addEventListener("mouseleave", mup, false);
                    document.body.addEventListener("touchleave", mup, false);

                    map.dragPan.disable();
                    map.once('mouseup', onUp);
                    map.once('touchend', onUp);
                }

                store.dispatch(setMarkerType('marker'));
                store.dispatch(setShowThreadPopup(true));
                setTimeout(() => {
                    var lngLat = marker.getLngLat();
                    const coordinatesInput = document.getElementById('cm-coordinates');
                    const latInput = document.getElementById('cm-coordinates-lat');
                    const lngInput = document.getElementById('cm-coordinates-lng');
                    if (coordinatesInput !== null) {
                        coordinatesInput.value = lngLat.lat+', '+lngLat.lng;
                        latInput.value = lngLat.lat;
                        lngInput.value = lngLat.lng;
                    }
                },300);
            },
            reload_marker: (latlng, event) => {
                const latlngarr = latlng.split(',');
                const lnglat = {lng: latlngarr[1], lat: latlngarr[0]}
                const marker = document.getElementById('new-thread-marker');
                marker !== null && marker.remove();
                CommentsMarkerTool.getInstance().addMarker(lnglat, event);
            }
        };

    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        },
        hasInstance: function () {
            if (!instance) {
                return false;
            } else {
                return true;
            }
        }
    };
})();