import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, ProfilePage, MapPage, MapsPage, Thread, Dashboard, MapView } from './pages';
import MapLink from './pages/map/maplink';

const routes = [
  {
    path: '/surveys/account-settings',
    component: ProfilePage
  },
  {
    path: '/surveys',
    component: HomePage
  },
  {
    path: '/surveys/viewmap',
    component: MapPage
  },
  {
    path: '/surveys/map/:token',
    component: MapLink
  },
  {
    path: '/surveys/maps',
    component: MapsPage
  },
  {
    path: '/surveys/dashboard',
    component: Dashboard

  },
  {
    path: '/surveys/thread/:threadid/:replyid/:token',
    component: Thread
  },
  {
    path: '/surveys/mapview/:viewid/:token',
    component: MapView
  }

];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
