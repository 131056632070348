import turflength from "@turf/length";
import store from "../../api/store/store";
import Trashbutton from "../mapbox/trashbutton";
import {setPopoverVisible, setPopoverText, setShowLineTooltip} from "../../api/actions/actions";

//Create Singleton instance of MeasureTool

export const MeasureTool = (() => {
    let instance;

    function createInstance() {
        const map = store.getState().mbmap;
        const geojson = store.getState().geojson;
        const trashbtn = new Trashbutton();
        let tooltoggle = null;

        return {
            create: (toolbtn_toggle) => {
                if (map !== null) {
                    !map.getSource('geojson') && map.addSource('geojson', {
                        'type': 'geojson',
                        'data': geojson
                    });

                    map.addLayer({
                        id: 'measure-points',
                        type: 'circle',
                        source: 'geojson',
                        paint: {
                            'circle-radius': 5,
                            'circle-color': '#000'
                        },
                        filter: ['in', '$type', 'Point']
                    });
                    map.addLayer({
                        id: 'measure-lines',
                        type: 'line',
                        source: 'geojson',
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        paint: {
                            'line-color': '#a70000',
                            'line-width': 2.5
                        },
                        filter: ['in', '$type', 'LineString']
                    });
                    /*map.addLayer({
                        "id": "symbols",
                        "type": "symbol",
                        "source": "geojson",
                        "layout": {
                            "symbol-placement": "line",
                            "text-font": ["Open Sans Regular"],
                            "text-field": '{title}', // part 2 of this is how to do it
                            "text-size": 16
                        }
                    });*/
                    map.on('click', MeasureTool.getInstance().mapOnClick);
                    map.on('mousemove', MeasureTool.getInstance().mouseMove);
                    map.addControl(trashbtn);
                    const trashbtnctrl = document.getElementById('mapbox-gl-distance-trash');

                    if (trashbtnctrl !== null) {
                        trashbtnctrl.style.display = 'none';
                        trashbtnctrl.addEventListener('click', () => {
                            tooltoggle !== null && tooltoggle();
                        });
                    }
                    tooltoggle = toolbtn_toggle;

                } else {
                    MeasureTool.getInstance().destroy();
                }
                store.dispatch(setShowLineTooltip(false));
            },
            destroy: () => {
                if (map !== null) {
                    const distanceContainer = document.getElementById('distance');
                    map.off('click', MeasureTool.getInstance().mapOnClick);
                    map.off('mousemove', MeasureTool.getInstance().mouseMove);
                    map.removeLayer('measure-points');
                    map.removeLayer('measure-lines');
                    // Remove the linestring from the group
                    // So we can redraw it based on the points collection
                    if (geojson.features.length > 1) geojson.features.pop();
                    // Remove points from the map
                    geojson.features = geojson.features.filter(function (point) {
                        return point.properties.id === undefined;
                    });

                    map.getSource('geojson').setData(geojson);
                    map.removeSource('geojson');
                    distanceContainer.innerHTML = '';
                    map.getCanvas().style.cursor = "unset";
                    const trashbtnctrl = document.getElementById('mapbox-gl-distance-trash');
                    trashbtnctrl !== null && trashbtnctrl.removeEventListener('click', () => {
                        MeasureTool.getInstance().destroy();
                        MeasureTool.getInstance().create();
                    });
                    map.removeControl(trashbtn);
                    store.dispatch(setPopoverVisible(false));
                    store.dispatch(setPopoverText(''));
                    store.dispatch(setShowLineTooltip(false));
                }
            },
            mapOnClick: (e) => {
                const distanceContainer = document.getElementById('distance');
                const measurements = store.getState().measurements;
                //console.log(measurements);
                // Used to draw a line between points
                const linestring = {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': []
                    }
                };

                var features = map.queryRenderedFeatures(e.point, {
                    layers: ['measure-points']
                });

                // Remove the linestring from the group
                // So we can redraw it based on the points collection
                if (geojson.features.length > 1) geojson.features.pop();

                // Clear the Distance container to populate it with a new value
                distanceContainer.innerHTML = '';

                // If a feature was clicked, remove it from the map
                if (features.length) {
                    var id = features[0].properties.id;
                    geojson.features = geojson.features.filter(function (point) {
                        return point.properties.id !== id;
                    });
                } else {
                    var point = {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [e.lngLat.lng, e.lngLat.lat]
                        },
                        'properties': {
                            'id': String(new Date().getTime())
                        }
                    };

                    geojson.features.push(point);
                }

                if (geojson.features.length > 1) {
                    linestring.geometry.coordinates = geojson.features.map(
                        function (point) {
                            return point.geometry.coordinates;
                        }
                    );
                    const distancenum = turflength(linestring, {units: 'feet'}).toFixed(2);
                    //const distancetext = distancenum.toLocaleString() + ' ft';

                    if (typeof linestring.properties === 'undefined') { linestring.properties = {}; }

                    //linestring.properties.title = distancetext;

                    geojson.features.push(linestring);

                    measurements.push(distancenum);
                    // Populate the distanceContainer with total distance
                    var value = document.createElement('pre');
                    value.textContent =
                        'Total distance: ' +
                        distancenum.toLocaleString() +
                        ' ft';
                    distanceContainer.appendChild(value);
                    /*const textContent =
                        '<p><strong>Total distance:</strong></p><p>' +
                        distancetext +
                        '</p>';
                    store.dispatch(setMeasurements(measurements));
                    // Populate popover
                    store.dispatch(setPopoverText( textContent));
                    store.dispatch(setPopoverVisible(true));*/

                    //@TODO figure out how to make this work
                    //Also look at http://jsfiddle.net/brianssheldon/wm18a33d/27/

                    /*map.addLayer({
                        "id": "symbols",
                        "type": "symbol",
                        "source": "geojson",
                        "layout": {
                            "symbol-placement": "line",
                            "text-font": ["Open Sans Regular"],
                            "text-field": distancetext, // part 2 of this is how to do it
                            "text-size": 16
                        }
                    });  */
                }
                map.getSource('geojson').setData(geojson);
            },
            mouseMove: (e) => {
                var features = map.queryRenderedFeatures(e.point, {
                    layers: ['measure-points']
                });
                // UI indicator for clicking/hovering a point on the map
                map.getCanvas().style.cursor = features.length
                    ? 'pointer'
                    : 'crosshair';
            },
        };
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();