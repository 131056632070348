import React, {useState, useEffect, Fragment} from "react";
import { useSelector } from 'react-redux'
import mapboxgl from 'mapbox-gl';
import {Popup} from "devextreme-react/popup";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LoadPanel from 'devextreme-react/load-panel';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {kwapi} from "../../api/kwapi";
import './printmap.scss';
import Form, {
    ButtonItem,
    GroupItem,
    Item,
    Label,
    SimpleItem,
} from "devextreme-react/form";

export default ({printvisible, dismissPopup}) => {
    const map = useSelector((state) => state.mbmap);
    const mapObj = useSelector((state) => state.map);
    const lng = useSelector((state) => state.lng);
    const lat = useSelector((state) => state.lat);
    const zoom = useSelector((state) => state.zoom);
    //const selectedlayers = useSelector((state) => state.selectedlayers);

    const [printmap, setPrintMap] = useState({
        mapname: mapObj.MapName,
        format: 'pdf',
        unit: 'in',
        width: 11,
        height: 8.5,
        dpi: 300,
        zoom: zoom,
        lng: lng,
        lat: lat,
    });

    const [papertype, setPaperType] = useState('letter');
    const [printing, setPrinting] = useState(false);
    const [printmsg, setPrintMsg] = useState('Printing to PDF: Collecting map assets...');

    const updateMapData = () => {
        printmap.lng = lng;
        printmap.lat = lat;
        printmap.zoom = zoom;
        setPrintMap(printmap);
    };

    useEffect(() => {
        updateMapData();
    }, [lng, lat, zoom]); // eslint-disable-line react-hooks/exhaustive-deps

    const year = moment().format('YYYY');

    const paperSizes = {
        letter: [11, 8.5],
        legal: [14, 8.5],
        A0: [44, 34],
        A1: [34, 22],
        A2: [22, 17],
        A3: [17, 11],
        Arch_A: [12, 9],
        Arch_B: [18, 12],
        Arch_C: [24, 18],
        Arch_D: [36, 24]
    };

    mapboxgl.accessToken = mapObj.Token;

    const hideNonPrintable = (preview) => {
        if (typeof preview === 'undefined') { preview = false; }
        var markers = document.getElementsByClassName("kw-marker");
        for (var i = 0; i < markers.length; i++) {
            markers[i].style.display = 'none';
        }

        if (!preview) {
            const toprightctrls = document.querySelector('.mapboxgl-ctrl-top-right');
            toprightctrls.style.display = 'none';
        }

        const bottomcenterctrls = document.querySelector('.mapboxgl-ctrl-bottom-center');
        !bottomcenterctrls.classList.contains('print-view') && bottomcenterctrls.classList.add('print-view');
        bottomcenterctrls.style.left = 'unset';
        bottomcenterctrls.style.right = '10px';
        bottomcenterctrls.style.bottom = '70px';

        const layersicon = document.querySelector('.layers-icon');
        layersicon.style.display = 'none';

        const mblogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
        const vtattrib = document.getElementsByClassName('vt-attribution')[0];

        mblogo.style.display = 'none';
        vtattrib.style.marginTop = '-12px';

    };
    const showNonPrintable = () => {
        var markers = document.getElementsByClassName("kw-marker");
        for (var i = 0; i < markers.length; i++) {
            markers[i].style.display = 'block';
        }

        const toprightctrls = document.querySelector('.mapboxgl-ctrl-top-right');
        toprightctrls.style.display = 'block';

        const bottomcenterctrls = document.querySelector('.mapboxgl-ctrl-bottom-center');
        bottomcenterctrls.style.right = 'unset';
        bottomcenterctrls.style.left = '50%';
        bottomcenterctrls.style.bottom = '0';

        bottomcenterctrls.classList.contains('print-view') && bottomcenterctrls.classList.remove('print-view');

        const layersicon = document.querySelector('.layers-icon');
        layersicon.style.display = 'block'

        const mblogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
        const vtattrib = document.getElementsByClassName('vt-attribution')[0];

        mblogo.style.display = 'block';
        vtattrib.style.marginTop = '-12px';
    };

    const validationRules = {
        dpi: [
            { type: 'required', message: 'DPI is required.'}
        ],
        /*unit: [
            { type: 'required', message: 'Unit is required.'}
        ],*/
        dimensions: [
            { type: 'required', message: 'Paper Size is required.'}
        ],
        /*width: [
            { type: 'required', message: 'Width is required.'}
        ],
        height: [
            { type: 'required', message: 'Height is required.'}
        ]*/
    };

    const cancelHandler = (e) => {
        setPrintMap({
            mapname: mapObj.MapName,
            format: 'pdf',
            unit: 'in',
            width: 11,
            height: 8.5,
            dpi: 300,
            zoom: zoom,
            lng: lng,
            lat: lat,
        });
        const mapboxmapctnr = document.querySelector('.mapboxgl-map');
        mapboxmapctnr.style.width = 'unset';
        mapboxmapctnr.style.height = 'unset';
        mapboxmapctnr.style.border = 'none';
        const titleblock = document.querySelector('.print-title-block');
        titleblock !== null && titleblock.remove();
        /*const mapattrib = document.querySelector('.mapboxgl-ctrl-bottom-right');
        mapattrib.style.display = 'block';*/
        const existing_footerblock = document.querySelector('.footer-title-block');
        let footerblock;
        if (existing_footerblock === null) {
            footerblock = document.createElement('div');
            footerblock.className = 'footer-title-block';
            footerblock.innerHTML ='<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-title-attrib"><div class="mapboxgl-ctrl-attrib-inner title-attrib">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';
            const mapboxctrls = document.querySelector('.mapboxgl-control-container');
            mapboxctrls.appendChild(footerblock);
            const titleattrib = document.querySelector('.title-attrib');
            titleattrib.innerHTML = '<img src="'+kwapi.getCDNURL()+'assets/images/logo.svg" style="width: 180px;margin-top: 10px;margin-left: 5px;"><div class="vt-title" style="font-size: 20px;margin-left: 190px;margin-top: -34px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div><div class="mb-attribution">Map &amp; Satellite Data included in this map  \n' +
                '<a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox" role="listitem">© Mapbox</a>&nbsp;<a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a>&nbsp;<a href="https://www.maxar.com/" target="_blank" title="Maxar" aria-label="Maxar" role="listitem">© Maxar</a></div>';
        }
        showNonPrintable();
        setTimeout(() => dismissPopup(), 100);
    }

    const toPixels = (length) => {
        let conversionFactor = 96;
        if (printmap.unit === 'mm') {
            conversionFactor /= 25.4;
        }
        return conversionFactor * length + 'px';
    };

    const getLogoSVG = () => {
        return '<svg version="1.1" id="Logo_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="width: 300px;margin-top: 8px;margin-left: 4px;overflow: visible;"\n' +
            '\t width="300px" height="70px" viewBox="0 0 856.807 172.901" enable-background="new 0 0 856.807 172.901"\n' +
            '\t xml:space="preserve">\n' +
            '<g>\n' +
            '\t<path fill="#2C2A29" d="M208.061,120.379V52.37h4.893v41.247l39.711-41.342h5.66l-28.298,29.832l29.736,38.273H254.2L226.862,85.08\n' +
            '\t\tl-13.908,14.292v21.007H208.061z"/>\n' +
            '\t<path fill="#2C2A29" d="M269.833,120.379V52.37h4.893v68.009H269.833z"/>\n' +
            '\t<path fill="#2C2A29" d="M337.17,115.966v4.413h-45.371V52.274h44.508v4.412h-39.615v26.762h34.627v4.221h-34.627v28.297H337.17z"/>\n' +
            '\t<path fill="#2C2A29" d="M349.832,120.379V52.274h28.393c2.877,0,5.531,0.608,7.961,1.822c2.43,1.216,4.51,2.83,6.236,4.844\n' +
            '\t\tc1.727,2.014,3.084,4.285,4.076,6.811c0.99,2.527,1.486,5.069,1.486,7.625c0,2.494-0.383,4.909-1.15,7.242\n' +
            '\t\tc-0.768,2.335-1.855,4.429-3.262,6.283c-1.408,1.855-3.104,3.39-5.084,4.604c-1.982,1.215-4.158,2.014-6.523,2.398l16.979,26.475\n' +
            '\t\th-5.658l-16.5-25.707h-22.061v25.707H349.832z M354.725,90.164h23.883c2.174,0,4.156-0.479,5.947-1.439\n' +
            '\t\tc1.791-0.959,3.324-2.221,4.605-3.789c1.277-1.566,2.254-3.357,2.926-5.372c0.67-2.014,1.006-4.077,1.006-6.187\n' +
            '\t\tc0-2.173-0.4-4.269-1.199-6.283s-1.887-3.789-3.26-5.324c-1.377-1.535-2.975-2.765-4.797-3.693\n' +
            '\t\tc-1.822-0.926-3.789-1.391-5.898-1.391h-23.213V90.164z"/>\n' +
            '\t<path fill="#2C2A29" d="M503.303,52.754h4.797l10.168,24.748l10.168-24.748h4.795l-11.99,28.681l14.293,33.189l26.09-62.349h5.373\n' +
            '\t\tl-29.16,68.104h-4.51l-14.963-35.012l-15.061,35.012h-4.508L469.73,52.274h5.275l26.188,62.349l14.1-33.189L503.303,52.754z"/>\n' +
            '\t<path fill="#2C2A29" d="M575.531,120.379V52.274h28.393c2.879,0,5.531,0.608,7.963,1.822c2.43,1.216,4.508,2.83,6.234,4.844\n' +
            '\t\tc1.727,2.014,3.084,4.285,4.076,6.811c0.992,2.527,1.488,5.069,1.488,7.625c0,2.494-0.385,4.909-1.152,7.242\n' +
            '\t\tc-0.768,2.335-1.855,4.429-3.262,6.283c-1.406,1.855-3.102,3.39-5.084,4.604c-1.982,1.215-4.156,2.014-6.521,2.398l16.979,26.475\n' +
            '\t\th-5.66l-16.498-25.707h-22.062v25.707H575.531z M580.424,90.164h23.885c2.174,0,4.156-0.479,5.947-1.439\n' +
            '\t\tc1.789-0.959,3.324-2.221,4.604-3.789c1.279-1.566,2.254-3.357,2.926-5.372c0.672-2.014,1.008-4.077,1.008-6.187\n' +
            '\t\tc0-2.173-0.4-4.269-1.199-6.283c-0.801-2.014-1.887-3.789-3.262-5.324s-2.973-2.765-4.795-3.693\n' +
            '\t\tc-1.824-0.926-3.789-1.391-5.9-1.391h-23.213V90.164z"/>\n' +
            '\t<path fill="#2C2A29" d="M638.455,120.379V52.37h4.893v68.009H638.455z"/>\n' +
            '\t<path fill="#2C2A29" d="M711.74,109.828c-6.588,7.354-14.197,11.031-22.83,11.031c-4.604,0-8.873-0.991-12.807-2.974\n' +
            '\t\tc-3.932-1.981-7.305-4.604-10.119-7.865c-2.814-3.262-5.021-6.97-6.619-11.127c-1.598-4.156-2.396-8.377-2.396-12.662\n' +
            '\t\tc0-4.475,0.781-8.792,2.35-12.949c1.566-4.156,3.74-7.833,6.521-11.031c2.783-3.197,6.076-5.739,9.881-7.625\n' +
            '\t\tc3.805-1.886,7.977-2.83,12.518-2.83c3.453,0,6.537,0.369,9.256,1.104c2.719,0.736,5.133,1.759,7.242,3.069\n' +
            '\t\tc2.111,1.312,3.949,2.877,5.516,4.7c1.566,1.822,2.893,3.822,3.982,5.995l-3.934,2.398c-2.367-4.475-5.453-7.722-9.256-9.736\n' +
            '\t\tc-3.807-2.015-8.105-3.021-12.902-3.021c-4.029,0-7.658,0.863-10.887,2.59c-3.23,1.727-5.996,3.997-8.297,6.81\n' +
            '\t\tc-2.303,2.815-4.062,6.012-5.277,9.592c-1.215,3.582-1.822,7.259-1.822,11.031c0,4.029,0.703,7.851,2.111,11.462\n' +
            '\t\tc1.406,3.614,3.34,6.796,5.803,9.544c2.461,2.75,5.355,4.94,8.682,6.571c3.324,1.63,6.906,2.446,10.742,2.446\n' +
            '\t\tc4.156,0,8.072-0.959,11.752-2.877c3.676-1.918,7.273-4.924,10.791-9.017V91.219h-15.443v-3.933h19.76v33.093h-4.316V109.828z"/>\n' +
            '\t<path fill="#2C2A29" d="M783.68,52.274v68.104h-4.891V87.766h-42.973v32.613h-4.893V52.274h4.893v31.079h42.973V52.274H783.68z"/>\n' +
            '\t<path fill="#2C2A29" d="M847.564,56.687h-24.941v63.692h-4.891V56.687h-24.939v-4.412h54.771V56.687z"/>\n' +
            '</g>\n' +
            '<g>\n' +
            '\t<path fill="#2C2A29" d="M469.631,84.139v4.259h-29.812v31.942h-4.26V88.397h-29.812v-4.259h29.812V52.197h4.26v31.942H469.631z"/>\n' +
            '</g>\n' +
            '<g>\n' +
            '\t<g>\n' +
            '\t\t<path fill="#2C2A29" d="M70.622,76.209L60.126,61.061l-5.471,5.16v9.989h-6.914V30.324h6.914v29.105L69.804,43.4h7.479\n' +
            '\t\t\tL64.585,57.166L78.04,76.209H70.622z"/>\n' +
            '\t</g>\n' +
            '\t<g>\n' +
            '\t\t<path fill="#2C2A29" d="M138.037,96.396h6.271l-13.2,34.521h-5.375l-5.851-16.041l-5.792,16.041h-5.434L95.516,96.396h6.212\n' +
            '\t\t\tl10.09,27.525l4.54-12.801l-5.432-14.663h5.371l3.585,10.831l3.583-10.831h5.375l-5.375,14.663L128,123.921L138.037,96.396z"/>\n' +
            '\t</g>\n' +
            '\t<g>\n' +
            '\t\t<path fill="#2C2A29" d="M13.967,79.305c3.299-34.74,30.932-62.371,65.666-65.668V9.004c-37.288,3.322-66.972,33.009-70.3,70.3\n' +
            '\t\t\tH13.967z"/>\n' +
            '\t\t<path fill="#2C2A29" d="M159.267,93.273c-3.298,34.74-30.93,62.363-65.667,65.664v4.636c37.29-3.326,66.973-33.013,70.303-70.3\n' +
            '\t\t\tH159.267z"/>\n' +
            '\t</g>\n' +
            '\t<path fill="#D6001C" d="M86.616,163.902C43.75,163.902,9,129.15,9,86.286h77.616V163.902z"/>\n' +
            '\t<path fill="#D6001C" d="M164.229,86.286c0-42.869-34.749-77.618-77.613-77.618v77.618H164.229z"/>\n' +
            '</g>\n' +
            '</svg>';

    }

    const createPrintMap = (width, height, dpi, format, unit, zoom, center, bearing, style, pitch) => {
        dismissPopup();
        const footer = document.querySelector('.footer-title-block');
        footer !== null && footer.remove();
        const existing_titleblock = document.querySelector('.print-title-block');
        existing_titleblock.remove();
        let titleblock;
        titleblock = document.createElement('div');
        titleblock.className = 'print-title-block';
        titleblock.innerHTML ='<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-title-attrib"><div class="mapboxgl-ctrl-attrib-inner title-attrib">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';
        const mapboxctrls = document.querySelector('.mapboxgl-control-container');
        mapboxctrls.appendChild(titleblock);
        const titleattrib = document.querySelector('.title-attrib');
        //titleattrib.innerHTML = getLogoSVG() + '<div class="vt-title" style="font-size: 20px;margin-left: 81px;margin-top: -15px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div>';
        titleattrib.innerHTML = getLogoSVG() + '<div class="vt-title" style="font-size: 20px;margin-left: 80px;margin-top: -12px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div>';
        const mapboxmapctnr = document.querySelector('.mapboxgl-map');
        mapboxmapctnr.style.border = 'none';
        /*const mapboxmapctnr = document.querySelector('.mapboxgl-map');
        mapboxmapctnr.style.width = toPixels(width);
        mapboxmapctnr.style.height = toPixels(height);*/

        // Calculate pixel ratio
        /*const actualPixelRatio = window.devicePixelRatio;
        Object.defineProperty(window, 'devicePixelRatio', {
            get: function() {return dpi / 96}
        });*/
        setPrintMsg('Printing to PDF... This will take some time');
        //const screenshotTarget = document.querySelector('.dx-scrollview-content');
        html2canvas(document.querySelector(".mapboxgl-map"), {
            //allowTaint: true
        })
        .then(function(canvas) {
            var canvas_img = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: width > height ? 'l' : 'p',
                unit: unit,
                format: [width, height],
                compress: true
            });
            pdf.addImage(canvas_img,
                'png', 0, 0, width, height, null, 'FAST');

            const title = printmap.mapname,
                subject = "center: [" + printmap.lng  + ", " + printmap.lat + ", " + printmap.zoom + "]",
                attribution = 'Kier & Wright Civil Engineers and Surveyors, Inc';

            pdf.setProperties({
                title: title,
                subject: subject,
                creator: ' Kier + Wright Virtual Tours',
                author: attribution
            });

            const currentdate = new Date();
            const datestr = currentdate.getMonth() + "-" + currentdate.getDay() + "-" + currentdate.getFullYear();
            pdf.save(printmap.mapname + ' - ' + datestr + '.pdf');
            setPrinting(false);
            const mapboxmapctnr = document.querySelector('.mapboxgl-map');
            mapboxmapctnr.style.width = 'unset';
            mapboxmapctnr.style.height = 'unset';
            mapboxmapctnr.style.border = 'none';
            const titleblock = document.querySelector('.print-title-block');
            titleblock !== null && titleblock.remove();
            /*const mapattrib = document.querySelector('.mapboxgl-ctrl-bottom-right');
            mapattrib.style.display = 'block';
            mbattrib.innerHTML = '';
            */
            const existing_footerblock = document.querySelector('.footer-title-block');
            let footerblock;
            if (existing_footerblock === null) {
                footerblock = document.createElement('div');
                footerblock.className = 'footer-title-block';
                footerblock.innerHTML ='<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-title-attrib"><div class="mapboxgl-ctrl-attrib-inner title-attrib">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';
                const mapboxctrls = document.querySelector('.mapboxgl-control-container');
                mapboxctrls.appendChild(footerblock);
                const titleattrib = document.querySelector('.title-attrib');
                titleattrib.innerHTML = '<img src="'+kwapi.getCDNURL()+'assets/images/logo.svg" style="width: 180px;margin-top: 10px;margin-left: 5px;"><div class="vt-title" style="font-size: 20px;margin-left: 190px;margin-top: -34px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div>';
            }
            showNonPrintable();
            /*mapCenter = new mapboxgl.LngLat(center.lng, center.lat);
            map.setCenter(mapCenter);*/
        });

    };
    const onPrintSubmit = (e) => {
        e.preventDefault();
        setPrinting(true);
        hideNonPrintable();
        const center = { lng: printmap.lng, lat: printmap.lat }

        createPrintMap(printmap.width, printmap.height, printmap.dpi, printmap.format, printmap.unit, printmap.zoom, center, map.getBearing(), mapObj.styleURL, map.getPitch());
    };

    const updateBtnOptions = {
        text: 'Print to PDF',
        type: 'default',
        useSubmitBehavior: true,
    };

    const cancelBtnOptions = {
        text: 'Cancel',
        type: 'normal',
        className: 'print-cancel-btn',
        useSubmitBehavior: false,
        onClick: cancelHandler
    };

    const validateForm = (e) => {
        e.component.validate();
    };

    const paperSizeChange = (e) => {
        setPaperType(e.value);
        const paperDimensions = paperSizes[e.value];
        if (typeof paperDimensions !== 'undefined') {
            const mapboxmapctnr = document.querySelector('.mapboxgl-map');
            mapboxmapctnr.style.width = toPixels(paperDimensions[0]);
            mapboxmapctnr.style.height = toPixels(paperDimensions[1]);
            mapboxmapctnr.style.border = '3px solid #000';

            const mapattrib = document.querySelector('.mapboxgl-ctrl-bottom-right');
            mapattrib.style.display = 'none';

            hideNonPrintable(true);
            const existing_titleblock = document.querySelector('.print-title-block');
            let titleblock;
            const footer = document.querySelector('.footer-title-block');
            footer !== null && footer.remove();
            if (existing_titleblock === null) {
                titleblock = document.createElement('div');
                titleblock.className = 'print-title-block';
                titleblock.innerHTML ='<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-title-attrib"><div class="mapboxgl-ctrl-attrib-inner title-attrib">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div></div>';
                const mapboxctrls = document.querySelector('.mapboxgl-control-container');
                mapboxctrls.appendChild(titleblock);
                const titleattrib = document.querySelector('.title-attrib');
                titleattrib.innerHTML = '<img src="'+kwapi.getCDNURL()+'assets/images/logo.svg" style="width: 300px;margin-top: 18px;margin-left: 10px;"><div class="vt-title" style="font-size: 20px;margin-left: 81px;margin-top: -15px;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div>';
                //titleattrib.innerHTML = getLogoSVG() + '<div class="vt-title" style="font-size: 20px;margin-left: 81px;margin-top: 0;">Virtual Tours</div><div class="vt-attribution">Copyright © ' + year + ' <a href="https://kierwright.com" target="_blank">Kier &amp; Wright Civil Engineers and Surveyors, Inc.</a> - All Rights Reserved</div>';
            }

            printmap.width = paperDimensions[0];
            printmap.height = paperDimensions[1];
            setPrintMap(printmap);
        }
    };
    const initPrintPopup = () => {
        paperSizeChange({value: 'letter'});
    };
    return (
        <Fragment>
        {
            printvisible ?
                <Popup
                    visible={printvisible}
                    onHiding={cancelHandler}
                    onInitialized={initPrintPopup}
                    dragEnabled={true}
                    resizeEnabled={true}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Print Current Map View to PDF file"
                    defaultWidth={600}
                    defaultHeight={230}
                    minHeight={221}
                    minWidth={596}
                    shading={false}
                >
                    <div className="form-container print-map-container">
                        <form onSubmit={onPrintSubmit}>
                            <Form
                                onContentReady={validateForm}
                                colCount={2}
                                id="form"
                                formData={printmap}>

                                <SimpleItem dataField="mapname" editorType="dxTextBox">
                                    <Label text="Map/File Name"/>
                                </SimpleItem>
                                <Item dataField="dpi" validationRules={validationRules.dpi}>
                                    <Label text="DPI"/>
                                </Item>
                                <Item dataField="dimensions" editorType="dxSelectBox" editorOptions={{ items: Object.keys(paperSizes), value: papertype, onValueChanged: paperSizeChange }}  validationRules={validationRules.dimensions}>
                                    <Label text="Paper Size"/>
                                </Item>
                                {/*<SimpleItem dataField="lng" editorType="dxTextBox">
                                    <Label text="Longitude"/>
                                </SimpleItem>
                                <SimpleItem dataField="lat" editorType="dxTextBox">
                                    <Label text="Longitude"/>
                                </SimpleItem>
                                <SimpleItem dataField="zoom" editorType="dxTextBox">
                                    <Label text="Zoom"/>
                                </SimpleItem>*/}
                                <GroupItem cssClass="form-buttons">
                                    <ButtonItem buttonOptions={updateBtnOptions}/>
                                    <ButtonItem buttonOptions={cancelBtnOptions}/>
                                </GroupItem>

                                {/*<GroupItem caption="Printing Preferences">
                                    <Item dataField="dpi" validationRules={validationRules.dpi}>
                                        <Label text="DPI"/>
                                    </Item>
                                    <Item dataField="unit" editorType="dxSelectBox"
                                          editorOptions={{items: units, searchEnabled: false}}
                                          validationRules={validationRules.unit}/>
                                    <Item dataField="height" validationRules={validationRules.height}>
                                        <Label text="Height"/>
                                    </Item>
                                    <Item dataField="width" validationRules={validationRules.width}>
                                        <Label text="Width"/>
                                    </Item>
                                    <GroupItem cssClass="form-buttons">
                                        <ButtonItem buttonOptions={updateBtnOptions}/>
                                        <ButtonItem buttonOptions={cancelBtnOptions}/>
                                    </GroupItem>
                                </GroupItem>*/}
                            </Form>
                        </form>
                        <LoadPanel
                            visible={printing}
                            message={printmsg}
                            shadingColor="rgba(0,0,0,0.4)"
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                            closeOnOutsideClick={false}
                        />
                    </div>
                </Popup>
                :
                ''
        }
        </Fragment>

        );
}